import { Box, Container, Stack, SvgIcon, Tooltip, useTheme } from "@mui/material";
import {
  gridDivider,
  innerGridContainer,
  subText,
  tableHeader,
  totalBalanceValue,
  warmColdValues,
  wrapperContainer,
} from "./styles.tsx";
import { Unstable_Grid2 as Grid } from "@mui/material";
import { Divider, Icons, Paper, Typography, formatDollarAmountUsd } from "@bakkt/bakkt-ui-components";
import {
  calculateBalanceTotalsFromWallets,
  calculateOffExchangeTotalsFromWallets,
  checkWarmBalance,
} from "../../utils/dataUtils.ts";
import { NavLink, useRouteLoaderData } from "react-router-dom";
import { PriceInfo, Wallet } from "../../services/openAPI/client";
import { RootContextType, useRootContext } from "../../RootLayout";
import { isREL009PublicFeature } from "../../config/firebaseConfig.ts";
import { organizationGridSpacing } from "../organization/styles.tsx";

export default function Balances() {
  const theme = useTheme();
  const { selectedOrg } = useRootContext();
  const { orgDataCache, priceFeed, assets } = useRootContext() as RootContextType;
  const wallets = isREL009PublicFeature
    ? [...orgDataCache.wallets, ...orgDataCache.omnibusWallets]
    : [...orgDataCache.wallets];

  const hideBelowLarge = {
    display: "block",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  };

  const hideAboveLarge = {
    display: "block",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  };

  const balanceData = calculateBalanceTotalsFromWallets(wallets, priceFeed as PriceInfo[], assets);
  const isWarmBalance = checkWarmBalance(wallets, priceFeed as PriceInfo[], assets);
  const offExchangeBalances = calculateOffExchangeTotalsFromWallets(orgDataCache.offExchangeWallets, priceFeed, assets);

  const isOffExchangeBalanceViewEnabled =
    selectedOrg.isExchangeAccountEnabled && orgDataCache.offExchangeWallets.length > 0 && !isWarmBalance;

  return (
    <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
      <Grid container spacing={2}>
        <Grid xs={9}>
          <Typography variant="h3" data-testid="balances">
            Balances
          </Typography>
          <Typography variant="body1" sx={subText}>
            For details on pending transactions, please visit the{" "}
            <NavLink to={`/transactions/${selectedOrg.id}`}>
              <Box component="span" sx={{ textDecoration: "underline", textDecorationSkipInk: "none" }}>
                Transactions log
              </Box>
            </NavLink>
          </Typography>
        </Grid>
      </Grid>

      <Paper>
        <Grid container spacing={2} xs={12}>
          <Grid container xs={isOffExchangeBalanceViewEnabled ? 12 : 4}>
            <Container data-testid="balanceContainer" sx={innerGridContainer}>
              <Stack direction={"row"} alignContent={"center"} sx={{ p: 0, m: 0 }}>
                <Typography variant="overline" data-testid="label" sx={tableHeader}>
                  Current Balance
                </Typography>

                <Tooltip
                  title={
                    <Typography variant="body2">
                      Current balance is the sum total of confirmed assets across all wallets. Bakkt waits for a certain
                      number of blocks to pass before a transaction is confirmed. Block confirmations vary from chain to
                      chain so please review our user guide to understand the confirmations required per asset.
                    </Typography>
                  }
                  placement="top"
                >
                  <Box>
                    <SvgIcon component={Icons.InformationIcon} sx={{ width: 25, height: 25 }} />
                  </Box>
                </Tooltip>
              </Stack>

              <Typography variant="h3" data-testid="amount" sx={totalBalanceValue}>
                {/* Update this value to be omnibus + custodial wallets */}
                {formatDollarAmountUsd(balanceData.total + offExchangeBalances.totalBalance)}
              </Typography>

              {/*TODO: Coming back in Phase 2*/}
              {/*{balanceLabel === "Total Balance" && (*/}
              {/*  <div data-testid="percentChange">*/}
              {/*    <UpDownPill direction={"up"} text={"6.8%"} />*/}
              {/*    <span>Last 24 hours</span>*/}
              {/*  </div>*/}
              {/*)}*/}
            </Container>
          </Grid>
          {isWarmBalance && (
            <>
              <Divider orientation="vertical" flexItem />
              <Grid container xs={2.5}>
                <Container data-testid="warmContainer" sx={innerGridContainer}>
                  <Typography variant="overline" data-testid="label" sx={tableHeader}>
                    {isREL009PublicFeature ? "Segregated Warm" : "Warm"}
                  </Typography>
                  <Typography variant="h5" data-testid="amount" sx={warmColdValues}>
                    {/* Update to display total custodial warm value */}
                    {formatDollarAmountUsd(balanceData.warm)}
                  </Typography>

                  {/*TODO: Coming back in Phase 2*/}
                  {/*{balanceLabel === "Total Balance" && (*/}
                  {/*  <div data-testid={"percentChange"}>*/}
                  {/*    <UpDownPill direction={"down"} text={"3.2%"} />*/}
                  {/*    <span>Last 24 hours</span>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </Container>
              </Grid>
              <Divider orientation="vertical" flexItem sx={gridDivider} />
              <Grid container xs={2.5}>
                <Container data-testid="coldContainer" sx={innerGridContainer}>
                  <Typography variant="overline" data-testid="label" sx={tableHeader}>
                    {isREL009PublicFeature ? "Segregated Cold" : "Cold"}
                  </Typography>
                  <Typography variant="h5" data-testid="amount" sx={warmColdValues}>
                    {/* Update to display total custodial cold value */}
                    {formatDollarAmountUsd(balanceData.cold)}
                  </Typography>

                  {/*TODO: Coming back in Phase 2*/}
                  {/*{balanceLabel === "Total Balance" && (*/}
                  {/*  <div data-testid="percentChange">*/}
                  {/*    <UpDownPill direction={"up"} text={"4.5%"} />*/}
                  {/*    <span>Last 24 hours</span>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </Container>
              </Grid>
            </>
          )}

          {isREL009PublicFeature && !isWarmBalance && (
            <>
              {isOffExchangeBalanceViewEnabled ? (
                <Grid xs={12} sx={{ p: 0, m: 0, ml: 1, mr: -1 }}>
                  <Divider />
                </Grid>
              ) : (
                <Divider orientation="vertical" flexItem />
              )}
              <Grid
                container
                xs={isOffExchangeBalanceViewEnabled ? 4 : 3}
                sx={{ pb: isOffExchangeBalanceViewEnabled ? 4 : 0 }}
              >
                <Container data-testid="warmContainer" sx={innerGridContainer}>
                  <Typography variant="overline" data-testid="label" sx={tableHeader}>
                    Segregated
                  </Typography>
                  <Typography
                    variant={isOffExchangeBalanceViewEnabled ? "h4" : "h5"}
                    data-testid="amount"
                    sx={warmColdValues}
                  >
                    {/* Update to display total custodial wallet value */}
                    {formatDollarAmountUsd(balanceData.segregatedCustody)}
                  </Typography>

                  {/*TODO: Coming back in Phase 2*/}
                  {/*{balanceLabel === "Total Balance" && (*/}
                  {/*  <div data-testid={"percentChange"}>*/}
                  {/*    <UpDownPill direction={"down"} text={"3.2%"} />*/}
                  {/*    <span>Last 24 hours</span>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </Container>
              </Grid>
            </>
          )}
          {selectedOrg.isExchangeAccountEnabled && orgDataCache.offExchangeWallets.length > 0 && (
            <>
              <Divider orientation="vertical" flexItem />
              <Grid container xs={4}>
                <Container data-testid="offExchangeContainer" sx={innerGridContainer}>
                  <Typography variant="overline" data-testid="label" sx={tableHeader}>
                    OFF EXCHANGE
                  </Typography>
                  <Typography
                    variant={isOffExchangeBalanceViewEnabled ? "h4" : "h5"}
                    data-testid="amount"
                    sx={warmColdValues}
                  >
                    {/* Update to display omnibus value */}
                    {formatDollarAmountUsd(offExchangeBalances.totalBalance)}
                  </Typography>
                </Container>
              </Grid>
            </>
          )}
          {isREL009PublicFeature && orgDataCache.omnibusWallets.length > 0 && (
            <>
              <Divider orientation="vertical" flexItem />
              <Grid container>
                <Container data-testid="warmContainer" sx={innerGridContainer}>
                  <Typography variant="overline" data-testid="label" sx={tableHeader}>
                    OMNIBUS
                  </Typography>
                  <Typography
                    variant={isOffExchangeBalanceViewEnabled ? "h4" : "h5"}
                    data-testid="amount"
                    sx={warmColdValues}
                  >
                    {/* Update to display omnibus value */}
                    {formatDollarAmountUsd(balanceData.omnibusTrading)}
                  </Typography>

                  {/*TODO: Coming back in Phase 2*/}
                  {/*{balanceLabel === "Total Balance" && (*/}
                  {/*  <div data-testid={"percentChange"}>*/}
                  {/*    <UpDownPill direction={"down"} text={"3.2%"} />*/}
                  {/*    <span>Last 24 hours</span>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </Container>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Container>
  );
}
