import { Button, Typography, Icons } from "@bakkt/bakkt-ui-components";
import { Unstable_Grid2 as Grid, SvgIcon } from "@mui/material";
import { RootContextType, useRootContext } from "../../RootLayout.tsx";
import { useFetcher } from "react-router-dom";
import { useEffect } from "react";
import { CryptoTickerEnum, WalletTemp } from "../../utils/CryptoIconsMap.ts";
import { CreateWalletRequest, Organization, WalletType } from "../../services/openAPI/client/api.ts";
import { formatActionErrorResponse, formatActionSuccessResponse } from "../../utils/responseHandlingUtils.ts";
import { WalletFormData } from "./AddWallet.tsx";
import { WalletService } from "../../services/serviceLoader.ts";
import { isViewOnly } from "../../utils/permissionsUtil.ts";
import { getAssetSymbolFromTicker } from "../../utils/dataUtils.ts";

export const createOmnibusWallet = (assetSymbol: string, selectedOrg: Organization): CreateWalletRequest => {
  const omnibusWalletSymbol = assetSymbol;
  return {
    accountId: selectedOrg.accountId,
    organizationId: selectedOrg.id,
    assetSymbol: omnibusWalletSymbol,
    type: WalletType.Trading,
    name: `Omnibus ${omnibusWalletSymbol} Wallet`,
    description: `Omnibus ${omnibusWalletSymbol} Wallet`,
  };
};
const mapFormData = (wallet: WalletFormData): CreateWalletRequest => {
  return {
    accountId: Number(wallet.accountId),
    organizationId: Number(wallet.organizationId),
    assetSymbol: wallet.assetSymbol,
    name: wallet.name,
    description: wallet.description,
    type: wallet.type || "",
  };
};
export function ActivateOmnibusWallet() {
  const { selectedOrg, addAlert, setShouldRefreshOrgOmnibusWallets, userInfo, assets } =
    useRootContext() as RootContextType;
  const fetcher = useFetcher();

  const omnibusWallets = [
    createOmnibusWallet(getAssetSymbolFromTicker(CryptoTickerEnum.ETH.toString(), assets), selectedOrg),
    createOmnibusWallet(getAssetSymbolFromTicker(CryptoTickerEnum.BTC.toString(), assets), selectedOrg),
  ];

  const handleActivateOmnibusWallet = () => {
    omnibusWallets.forEach((wallet) => {
      fetcher.submit(JSON.stringify(wallet), {
        method: "post",
        encType: "application/json",
      });
    });
  };

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response?.success) {
        addAlert({
          severity: "success",
          messageHeader: "Your omnibus wallets have been activated.",
        });
      }
      setShouldRefreshOrgOmnibusWallets(true);
    }
  }, [fetcher.data]);

  return (
    <Grid container spacing={2} direction={"column"} alignItems={"center"} justifyContent={"center"} sx={{ py: 6 }}>
      <Grid>
        <Typography variant="h4" sx={{ fontWeight: 400 }}>
          Activate Omnibus wallets to perform fee-free transfer between participating organizations.
        </Typography>
      </Grid>
      <Grid justifyContent={"center"}>
        <Button
          variant="outlined"
          size="small"
          sx={{ pointerEvents: "auto" }}
          onClick={handleActivateOmnibusWallet}
          data-testid="newWalletButton"
          disabled={isViewOnly(userInfo, Number(selectedOrg.id))}
        >
          <SvgIcon component={Icons.PlusIcon} sx={{ width: "20px", height: "20px", marginRight: "6px" }} />
          ACTIVATE OMNIBUS WALLETS
        </Button>
      </Grid>
    </Grid>
  );
}

export async function action({ request }: { request: Request }) {
  try {
    const walletFormData = (await request.json()) as WalletFormData;
    const walletFormRequest = mapFormData(walletFormData);
    const createWalletResponse = await WalletService.createWallet(walletFormRequest);
    return formatActionSuccessResponse(createWalletResponse);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
