import { Container, Skeleton, SvgIcon } from "@mui/material";
import { innerGridContainer, subText, wrapperContainer } from "./styles.tsx";
import { Alert, Paper, Typography, Button, Icons, color } from "@bakkt/bakkt-ui-components";
import { Unstable_Grid2 as Grid } from "@mui/material";
import React from "react";
import LoadingIndicator from "../../components/loading/LoadingIndicator.tsx";
import { Await, Link as RouterLink, useRouteLoaderData } from "react-router-dom";
import { WalletsGrid } from "./WalletsGrid.tsx";
import { RootContextType, useRootContext } from "../../RootLayout";
import { isREL009PublicFeature } from "../../config/firebaseConfig.ts";
import { isViewOnly } from "../../utils/permissionsUtil.ts";

export default function Wallets() {
  const { orgDataCache, selectedOrg } = useRootContext() as RootContextType;
  const { userInfo }: any = useRouteLoaderData("root");
  const wallets = orgDataCache.wallets;

  return (
    <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
      <Grid container xs={12} justifyContent={"space-between"} spacing={2} sx={{ mb: 4 }}>
        <Typography variant="h3" data-testid="wallets">
          {isREL009PublicFeature ? "Segregated Wallets" : "Wallets"}
        </Typography>
        <Grid container>
          {!isViewOnly(userInfo, selectedOrg.id) && (
            <RouterLink to={`addWallet/`}>
              <Button
                variant="outlined"
                size="small"
                sx={{ pointerEvents: "auto", backgroundColor: color.crispWhite.main }}
                data-testid="newWalletButton"
              >
                <SvgIcon component={Icons.PlusIcon} sx={{ width: "20px", height: "20px", marginRight: "6px" }} />
                NEW WALLET
              </Button>
            </RouterLink>
          )}
        </Grid>
      </Grid>
      <Paper>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Container sx={innerGridContainer} data-testid="walletsTable">
              <React.Suspense fallback={<Skeleton variant="rectangular" height={110} />}>
                <Await resolve={wallets} errorElement={<Alert severity="error">Error loading wallet data!</Alert>}>
                  <WalletsGrid />
                </Await>
              </React.Suspense>
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
