import {
  Configuration,
  FusionAuthApiFactory,
  AccountApiFactory,
  OrganizationApiFactory,
  WalletApiFactory,
  WalletTransactionApiFactory,
  PriceApiFactory,
  UserApiFactory,
  PolicyApiFactory,
  AllowlistApiFactory,
  BlockchainApiFactory,
  HistorySummaryApiFactory,
  MessagingApiFactory,
  ReportApiFactory,
  AssetsApiFactory,
} from "./openAPI/client";
import { API_URL } from "../utils/dataUtils.ts";

const configuration: Configuration = new Configuration();
const baseUrl = API_URL + "/custody";

export const FusionAuthService = FusionAuthApiFactory(configuration, baseUrl);
export const AccountService = AccountApiFactory(configuration, baseUrl);
export const OrganizationService = OrganizationApiFactory(configuration, baseUrl);
export const WalletService = WalletApiFactory(configuration, baseUrl);
export const WalletTransactionService = WalletTransactionApiFactory(configuration, baseUrl);
export const PriceService = PriceApiFactory(configuration, baseUrl);
export const UserService = UserApiFactory(configuration, baseUrl);
export const PolicyService = PolicyApiFactory(configuration, baseUrl);
export const HistoryService = HistorySummaryApiFactory(configuration, baseUrl);
export const AllowListService = AllowlistApiFactory(configuration, baseUrl);
export const NotabeneService = BlockchainApiFactory(configuration, baseUrl);
export const MessagingService = MessagingApiFactory(configuration, baseUrl);
export const ReportService = ReportApiFactory(configuration, baseUrl);
export const AssetService = AssetsApiFactory(configuration, baseUrl);
