import { Box } from "@mui/material";
import { PolicyActionDetails, PolicyStatusEnum } from "../../services/openAPI/client";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { getRouteByRequestCategory } from "../../utils/dataUtils";

interface RequestLinkProps {
  policyItem: PolicyActionDetails;
  linkState?: object;
}

const RequestLink = ({ policyItem, linkState }: RequestLinkProps) => {
  const location = useLocation();

  return (
    <RouterLink
      to={`${location.pathname}/review-${getRouteByRequestCategory(policyItem)}/${
        JSON.parse(String(policyItem.policyInstancePayload)).entityId
      }`}
      state={linkState}
    >
      <Box component="span" sx={{ textDecoration: "underline" }}>
        {policyItem.policyInstanceRequestType?.replaceAll("_", " ")} {policyItem.policyInstanceRequestCategory}
      </Box>
    </RouterLink>
  );
};

export default RequestLink;
