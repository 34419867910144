export const internalGrid = {
  ".MuiDataGrid-main": {
    "&>.MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
    },
  },
};

export const userPremissionsChip = {
  "& .MuiChip-label": {
    textTransform: "capitalize",
  },
};
