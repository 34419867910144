import { Divider, Icons } from "@bakkt/bakkt-ui-components";
import * as React from "react";
import {
  AppBar,
  Container,
  Toolbar,
  Stack,
  Button,
  StyledEngineProvider,
  SvgIcon,
  MenuItem,
  Menu,
  Typography,
  Box,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { Unstable_Grid2 as Grid } from "@mui/material";
import { BakktToolbar } from "./BakktToolbar";
import BakktLogo from "../../assets/images/bakkt_logo.svg";
import styles from "./Header.module.css";
import { NavLink, useLoaderData, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { isTeamManager } from "../../utils/permissionsUtil";
import { handleLogout } from "../../utils/sessionUtils.ts";
import { generateAndDownloadCsv, shouldUseMockData } from "../../utils/dataUtils";
import { fetchMockDataPromiseWithDelay, lastMonthFinancialReport } from "../../services/mockData";
import { ReportService } from "../../services/serviceLoader";

import { Account } from "../../services/openAPI/client";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

interface HeaderProps {
  selectedOrgId: string;
  setSelectedOrgId: (selectedOrgId: string) => void;
  account: Account;
}

export const Header = ({ selectedOrgId, setSelectedOrgId, account }: HeaderProps) => {
  const theme = useTheme();
  const location = useLocation();
  const { userInfo }: any = useLoaderData();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeTextDecorationUnderline = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.color = theme.palette.success.light;
  };

  const changeTextDecorationNone = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.color = theme.palette.primary.contrastText;
  };

  async function getMonthlyFinanceReport() {
    setLoading(true);
    const financeReportData: any = shouldUseMockData
      ? await fetchMockDataPromiseWithDelay(lastMonthFinancialReport, 300)
      : await ReportService.getLastMonthFinanceReport(account.id);
    generateAndDownloadCsv(financeReportData, account.name);
    setLoading(false);
  }

  return (
    <StyledEngineProvider injectFirst>
      <Box sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
        <Container maxWidth="xl" disableGutters>
          <AppBar position="static" elevation={0} sx={{ py: 3, px: 4 }}>
            <Toolbar>
              <Grid container xs={12} justifyContent="space-between" alignItems="center">
                <Grid>
                  <img src={BakktLogo} alt="Bakkt logo" className={styles["header_logo"]} />
                </Grid>
                <Grid xs={8}>
                  <Stack direction="row" spacing={4}>
                    <NavLink
                      to={`/${selectedOrgId}`}
                      state={{ previousLocation: location }}
                      className="default-link-hover"
                    >
                      <Box component="span" className={styles["main_nav_item"]}>
                        DASHBOARD
                      </Box>
                    </NavLink>
                    <NavLink
                      to={`/transactions/${selectedOrgId}`}
                      onMouseOver={changeTextDecorationUnderline}
                      onMouseLeave={changeTextDecorationNone}
                      state={{ previousLocation: location }}
                      className="default-link-hover"
                    >
                      <Box component="span" className={styles["main_nav_item"]}>
                        TRANSACTIONS
                      </Box>
                    </NavLink>
                    <NavLink
                      to={`/audit/${selectedOrgId}/${userInfo.accountId}`}
                      onMouseOver={changeTextDecorationUnderline}
                      onMouseLeave={changeTextDecorationNone}
                      state={{ previousLocation: location }}
                      className="default-link-hover"
                    >
                      <Box component="span" className={styles["main_nav_item"]}>
                        AUDIT LOG
                      </Box>
                    </NavLink>
                    <NavLink
                      to={`/organization/${selectedOrgId}`}
                      onMouseOver={changeTextDecorationUnderline}
                      onMouseLeave={changeTextDecorationNone}
                      state={{ previousLocation: location }}
                      className="default-link-hover"
                    >
                      <Box component="span" className={styles["main_nav_item"]}>
                        ORGANIZATION
                      </Box>
                    </NavLink>
                    <NavLink
                      to={`/allowlist/${selectedOrgId}`}
                      onMouseOver={changeTextDecorationUnderline}
                      onMouseLeave={changeTextDecorationNone}
                      state={{ previousLocation: location }}
                      className="default-link-hover"
                    >
                      <Box component="span" className={styles["main_nav_item"]}>
                        ALLOWLIST
                      </Box>
                    </NavLink>
                  </Stack>
                </Grid>
                <Grid>
                  <Button
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={{ textTransform: "none", fontWeight: 700 }}
                    color="inherit"
                    className="default-link-hover"
                  >
                    {userInfo.preferred_username} <SvgIcon component={Icons.ChevronDownIcon} sx={{ mb: 0.5 }} />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={styles["MuiLink-Nav-Drop"]}
                  >
                    <MenuItem
                      component={NavLink}
                      to={`/manage-users/${selectedOrgId}/${userInfo.accountId}`}
                      onClick={handleClose}
                      disabled={!isTeamManager(userInfo)}
                    >
                      <Typography variant="body2">User Management</Typography>
                    </MenuItem>
                    {loading ? (
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress />
                      </Box>
                    ) : (
                      <MenuItem onClick={getMonthlyFinanceReport}>
                        <Typography variant="body2">Export Billing Report</Typography>
                      </MenuItem>
                    )}
                    <MenuItem onClick={handleLogout}>
                      <Typography variant="body2">Logout</Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Toolbar>
            <Container maxWidth="xl">
              <Divider />
            </Container>
            <BakktToolbar selectedOrgId={selectedOrgId} setSelectedOrgId={setSelectedOrgId} />
          </AppBar>
        </Container>
      </Box>
    </StyledEngineProvider>
  );
};
