import React from "react";
import { PriceService, WalletTransactionService } from "../../services/serviceLoader";
import { LoaderFunctionArgs, Outlet, useRevalidator } from "react-router-dom";
import { useInterval } from "@bakkt/bakkt-ui-components";
import { fetchMockDataPromiseWithDelay, getRandomizedPricingInfo, walletTransactions } from "../../services/mockData";
import Transactions from "./Transactions.tsx";
import { shouldUseMockData } from "../../utils/dataUtils.ts";
import { RootContextType, useRootContext } from "../../RootLayout.tsx";
import Alerts from "../../components/alerts/Alerts.tsx";
import Balances from "./Balances.tsx";
import Wallets from "./Wallets.tsx";
import { isREL009PublicFeature } from "../../config/firebaseConfig.ts";
import OmnibusWallets from "./OmnibusWallets.tsx";
import OffExchangeWallets from "./OffExchangeWallets.tsx";

const Dashboard = () => {
  const { setPriceFeed, selectedOrg } = useRootContext() as RootContextType;
  const revalidator = useRevalidator();

  const refreshIntervalMilliseconds = 120000; // two minutes
  useInterval(async () => {
    const feed: any = shouldUseMockData
      ? await fetchMockDataPromiseWithDelay(getRandomizedPricingInfo(), 20)
      : await PriceService.getPrices();
    revalidator.revalidate();

    setPriceFeed(feed);
  }, refreshIntervalMilliseconds);

  return (
    <>
      <Outlet context={useRootContext()} />
      <Alerts />
      <Balances />
      <Wallets />
      {selectedOrg.isExchangeAccountEnabled && <OffExchangeWallets />}
      {isREL009PublicFeature && <OmnibusWallets />}
      <Transactions />
    </>
  );
};

export default Dashboard;

export async function loader({ params }: LoaderFunctionArgs) {
  const orgId = Number(params.organizationId);

  const transactionsPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(walletTransactions, 300)
    : WalletTransactionService.getWalletTransactions(undefined, orgId);

  return {
    transactionsPromise: transactionsPromise,
  };
}
