import React, { useEffect, useState } from "react";
import { useFetcher, useNavigate, useParams, useRouteError } from "react-router-dom";
import { useRootContext } from "../../RootLayout";
import { Button, DialogTitle } from "@bakkt/bakkt-ui-components";
import { Alert, Dialog, DialogActions, DialogContent, Grid, TextField } from "@mui/material";
import { formatActionErrorResponse, formatActionSuccessResponse } from "../../utils/responseHandlingUtils";
import { shouldUseMockData } from "../../utils/dataUtils";
import { fetchMockDataPromiseWithDelay, users } from "../../services/mockData";
import { MessagingService } from "../../services/serviceLoader";
import { SendMessageData } from "../../services/openAPI/client";
import { AxiosError } from "axios/index";

interface requestTemplate {
  title: string;
  template: string;
  bodyText: string;
}
type requestTemplateGroup = {
  [key: string]: requestTemplate;
};
export default function ChangeRequest() {
  const { change } = useParams();
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const error = useRouteError() as AxiosError | any;
  const { addAlert, userInfo, selectedOrg } = useRootContext();
  const [open, setOpen] = useState(true);
  const [additionalContent, setAdditionalContent] = useState<string>();
  const changeMap: requestTemplateGroup = {
    "video-auth": {
      title: "Video Auth Change Request",
      template: "custody-request-edit-video-auth",
      bodyText:
        "Send Bakkt Support Team a change request for your minimum withdrawal dollar value requiring video authorization.",
    },
    consensus: {
      title: "Consensus Change Request",
      template: "custody-request-edit-consensus",
      bodyText: "Send Bakkt Support Team a change request for this Organization's minimum number of approvers.",
    },
    user: {
      title: "User Change Request",
      template: "custody-request-edit-user",
      bodyText: "Send Bakkt Support Team a request for user changes.",
    },
    "user-add": {
      title: "Add User Request",
      template: "custody-request-new-user",
      bodyText: "Send Bakkt Support Team a request to add a new user.",
    },
  };
  const requestTemplate = (): requestTemplate => {
    return change ? (changeMap[change] as requestTemplate) : ({} as any);
  };

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response?.success) {
        addAlert({
          severity: "success",
          messageHeader: "Change Request submitted successfully - Bakkt Support will be reaching out to you soon.",
        });

        handleClose();
      }
    }
  }, [fetcher.data]);

  const handleCreateChangeReq = () => {
    const data: SendMessageData = {
      messageType: requestTemplate().template,
      content: {
        accountId: userInfo.accountId,
        organizationId: selectedOrg.id,
        requesterUserId: userInfo.userId,
        additionalContent: additionalContent,
      },
    };

    fetcher.submit(JSON.stringify(data), {
      method: "post",
      encType: "application/json",
    });
  };

  const handleCancel = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={false}>
      <DialogTitle title={requestTemplate().title}>
        Submit your change request to our Bakkt Support team and we will follow up with you as soon as possible.
      </DialogTitle>

      <DialogContent sx={{ pt: "0 !important" }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2, mt: 4 }}>
            {error.message ? error.message : "Failed to create request. Please try again later or contact support."}
          </Alert>
        )}

        <Alert severity="info" sx={{ mb: 2, mt: 4 }}>
          {requestTemplate().bodyText}
        </Alert>

        <Grid xs={2} sx={{ mt: 4 }}>
          <TextField
            value={additionalContent}
            name={"additionalContent"}
            label="Additional Details"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAdditionalContent(event.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            multiline
            rows={7}
            required
            fullWidth={true}
            placeholder={"...please provide as many details as possible"}
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant={"outlined"} onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant={"contained"} onClick={handleCreateChangeReq} autoFocus>
          Create Request
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export async function action({ request }: { request: Request }) {
  try {
    const walletFormData = (await request.json()) as SendMessageData;
    const messageResponse = shouldUseMockData
      ? await fetchMockDataPromiseWithDelay(users, 3000)
      : await MessagingService.sendMessage(walletFormData);

    return formatActionSuccessResponse(messageResponse);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
