import { Stack, Typography, StyledEngineProvider, Container, Box } from "@mui/material";
import { Unstable_Grid2 as Grid } from "@mui/material";
import BakktLogo from "../../assets/images/bakkt_logo.svg";
import styles from "./Footer.module.css";
import { Link } from "@bakkt/bakkt-ui-components";
import { useTheme } from "@mui/material/styles";

export const Footer = () => {
  const theme = useTheme();

  const linkSx = {
    color: theme.palette.primary.contrastText,
  };

  return (
    <StyledEngineProvider injectFirst>
      <Box sx={{ backgroundColor: theme.palette.primary.main }}>
        <Container maxWidth="xl">
          <Grid sx={{ color: "primary.contrastText", py: 6, px: 4 }}>
            <Grid container gap={4} sx={{ pb: 6 }}>
              <Stack spacing={1}>
                <Typography sx={{ fontWeight: "bold" }}>Important Links</Typography>
                <Link
                  sx={linkSx}
                  target="_blank"
                  href="https://22005419.fs1.hubspotusercontent-na1.net/hubfs/22005419/Client%20Onboarding/Bakkt%20Custody%20-%20User%20Guide.pdf"
                  className="default-link-hover"
                >
                  <Box component="span" className="body-links">
                    User Guide
                  </Box>
                </Link>
                <Link href="tel:7709844900" sx={linkSx} className="default-link-hover">
                  (770) 984-4900
                </Link>
                <Link href="mailto:custody@bakkt.com" sx={linkSx} className="default-link-hover">
                  <Box component="span" className="body-links">
                    custody@bakkt.com
                  </Box>
                </Link>
              </Stack>
            </Grid>
            <Grid container gap={4} alignItems="center">
              <Link href="https://bakkt.com/" target="_blank" rel="noopener">
                <img src={BakktLogo} alt="Bakkt Logo" className={styles["footer_logo"]} />
              </Link>
              <Typography variant="body2">
                Copyright {new Date().getFullYear()} &copy; Bakkt Trust Company LLC. All rights reserved
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </StyledEngineProvider>
  );
};
