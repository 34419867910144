import { setUserProperties } from "firebase/analytics";
import { analytics, initRemoteConfig } from "../config/firebaseConfig";
import { UserInfo } from "@bakkt/bakkt-ui-components";

// pass user info, selected org, and account id.
export async function featureFlagUser(user: UserInfo) {
  // set init values when app is loaded.
  setUserProperties(analytics, { custody_user_id: String(user.userId) });
  setUserProperties(analytics, { account_id: String(user.accountId) });
  return await initRemoteConfig(String(user.accountId));
}
