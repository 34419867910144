import { color } from "@bakkt/bakkt-ui-components";

export const wrapperContainer = {
  mb: "68px",
  mt: "50px",
};

export const subText = {
  mt: 0.625,
  mb: 2.5,
};

export const innerGridContainer = {
  p: "20px",
};

export const tableHeader = {
  color: color.techBlack["700"],
  fontSize: "16px",
  lineHeight: "20px",
  letterSpacing: "normal",
  fontWeight: 500,
};

export const totalBalanceValue = {
  fontWeight: 700,
  lineHeight: "42px",
  marginBottom: "8px",
};

export const warmColdValues = {
  lineHeight: "36px",
  marginBottom: "8px",
};

export const gridDivider = {
  mr: "-1px",
};

export const offExgAggregateHeader = {
  color: color.techBlack["700"],
  backgroundColor: "#f2f2f2",
};
export const offExgTypography = {
  variant: "caption",
  alignContent: "center",
  textAlign: "center",
  padding: 1,
  noWrap: "true",
};
