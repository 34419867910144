import { Icons } from "@bakkt/bakkt-ui-components";

export const iconsMap: { [key: string]: any } = {
  BTC: Icons.BitcoinIcon,
  ETH: Icons.EthereumIcon,
  LTC: Icons.LitecoinIcon,
  ETC: Icons.EthereumClassicIcon,
  BCH: Icons.BitcoinCashIcon,
  ADA: Icons.CardanoIcon,
  DOGE: Icons.DogecoinIcon,
  MATIC: Icons.PolygonIcon,
  SHIB: Icons.ShibaInuIcon,
  SOL: Icons.SolanaIcon,
  USDC: Icons.UsdCoinIcon,
  CHL: Icons.ChyleIcon,
  DBIT: Icons.DeribitIcon,
};

export const enum WalletTemp {
  Warm = "Warm",
  Cold = "Cold",
}

export const enum CryptoTickerEnum {
  BTC = "BTC",
  ETH = "ETH",
  DOGE = "DOGE",
  LTC = "LTC",
  USDC = "USDC",
  BCH = "BCH",
  ETC = "ETC",
  SHIB = "SHIB",
  CHL = "CHL",
}

export const enum DestinationTypeEnum {
  Centralized = "Centralized Provider (Exchange)",
  SelfHosted = "Self-Hosted wallet",
}

export const enum OwnerTypeEnum {
  Individual = "Individual",
  Company = "Company",
}
