import { Button, Dialog, DialogTitle } from "@bakkt/bakkt-ui-components";
import { DialogActions } from "@mui/material";
import { useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate, useParams } from "react-router-dom";

import { useRootContext } from "../../RootLayout";
import { PolicyAction, PolicyActionActionEnum } from "../../services/openAPI/client";
import { PolicyService } from "../../services/serviceLoader";
import { formatActionErrorResponse, formatActionSuccessResponse } from "../../utils/responseHandlingUtils.ts";

const ApproveDeclineWithdraw = () => {
  const navigate = useNavigate();
  const fetcher = useFetcher();

  const { addAlert, selectedOrg, setShouldRefreshPolicyItems } = useRootContext();
  const { state } = useLocation();
  const params = useParams();
  const transactionAction = params.transactionAction?.toUpperCase();

  const [open, setOpen] = useState<boolean>(true);
  const [isSubmitError, setIsSubmitError] = useState<boolean>(false);
  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        setShouldRefreshPolicyItems(true);
        if (transactionAction === PolicyActionActionEnum.Approve) {
          addAlert({
            severity: "success",
            messageHeader: "Transaction has been approved.",
          });
        } else if (transactionAction === PolicyActionActionEnum.Deny) {
          addAlert({
            severity: "warning",
            messageHeader: "Transaction has been declined.",
          });
        }
        setIsSubmitError(false);
        navigate(`/${selectedOrg.id}`);
      } else {
        setIsSubmitError(true);
      }
    }
  }, [fetcher.data]);

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleCancel = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleApprove = () => {
    const approvalData = {
      action: PolicyActionActionEnum.Approve,
      policyActionId: state.policyActionId,
      organizationId: state.organizationId,
      accountId: state.clientId,
    };
    fetcher.submit(JSON.stringify(approvalData), {
      method: "post",
      encType: "application/json",
    });
  };

  const handleDecline = () => {
    const declineData = {
      action: PolicyActionActionEnum.Deny,
      policyActionId: state.policyActionId,
      organizationId: state.organizationId,
      accountId: state.clientId,
    };
    fetcher.submit(JSON.stringify(declineData), {
      method: "post",
      encType: "application/json",
    });
  };

  return (
    <>
      {isSubmitError ? (
        <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
          <DialogTitle title={"Failed to submit transaction"} severity="error">
            Please try again or reach out to support for assistance.
          </DialogTitle>

          <DialogActions>
            <Button variant={"outlined"} onClick={handleCancel}>
              Review Request
            </Button>
          </DialogActions>
        </Dialog>
      ) : transactionAction === PolicyActionActionEnum.Approve ? (
        <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={false}>
          <DialogTitle title={"Are you sure you want to approve this transaction?"} severity="warning">
            The transaction will be completed within 48 hours.
          </DialogTitle>

          <DialogActions>
            <Button variant={"outlined"} onClick={handleCancel}>
              Review Request
            </Button>
            <Button variant={"contained"} onClick={handleApprove} autoFocus>
              Yes, Approve
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={false}>
          <DialogTitle title={"Are you sure you want to decline this transaction?"} severity="error">
            Declining this transaction will result in the request being cancelled.
          </DialogTitle>

          <DialogActions>
            <Button variant={"outlined"} onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant={"contained"} onClick={handleDecline} autoFocus>
              Yes, Decline
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ApproveDeclineWithdraw;

export async function action({ request }: { request: Request }) {
  try {
    const reviewPolicyRequest = (await request.json()) as PolicyAction;
    const policyServiceAction = await PolicyService.applyAction(reviewPolicyRequest);
    return formatActionSuccessResponse(policyServiceAction);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
