import { Await, defer, LoaderFunctionArgs, Outlet, useLoaderData } from "react-router-dom";
import {
  accountHistories,
  fetchMockDataPromiseWithDelay,
  orgHistories,
  userInfo as userInfoMock,
} from "../../services/mockData";
import { innerGridContainer, subText, wrapperContainer } from "../dashboard/styles";
import { Stack, Unstable_Grid2 as Grid } from "@mui/material";
import { Alert, Paper, Typography, UserInfo } from "@bakkt/bakkt-ui-components";
import { Container } from "@mui/material";
import React, { useMemo } from "react";
import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { useRootContext } from "../../RootLayout";
import { HistoryService } from "../../services/serviceLoader";
import { API_URL, shouldUseMockData } from "../../utils/dataUtils";
import { HistorySummary } from "../../services/openAPI/client";
import { AuditLogsGrid } from "./AuditLogsGrid.tsx";
import axios from "axios";
import { isTeamManager } from "../../utils/permissionsUtil";
import { getUserSession } from "../../utils/sessionUtils";

const Audit = () => {
  const { accountHistoriesPromise, orgHistoriesPromise } = useLoaderData() as {
    accountHistoriesPromise: Promise<HistorySummary[]>;
    orgHistoriesPromise: Promise<HistorySummary[]>;
  };

  const fetchHistoriesPromise = useMemo(
    () => Promise.all([accountHistoriesPromise, orgHistoriesPromise]),
    [accountHistoriesPromise, orgHistoriesPromise],
  );

  return (
    <>
      <Outlet context={useRootContext()} />
      <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
        <Grid container spacing={2}>
          <Grid xs={9}>
            <Typography variant="h3">Audit Log</Typography>
            <Typography variant="body1" sx={subText}>
              Showing all Audit Logs.
            </Typography>
          </Grid>
        </Grid>

        <Paper>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Container sx={innerGridContainer} data-testid="auditLogsTable">
                <React.Suspense
                  fallback={
                    <Stack sx={{ mt: 12, mb: 7 }}>
                      <LoadingIndicator />
                    </Stack>
                  }
                >
                  <Await
                    resolve={fetchHistoriesPromise}
                    errorElement={<Alert severity="error">Error loading audit log data!</Alert>}
                  >
                    {([accountHistories, orgHistories]) => (
                      <AuditLogsGrid accountHistories={accountHistories} orgHistories={orgHistories} />
                    )}
                  </Await>
                </React.Suspense>
              </Container>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default Audit;

export async function loader({ params }: LoaderFunctionArgs) {
  try {
    const userInfo: UserInfo = await getUserSession();
    const orgId = Number(params.organizationId);
    const accountId = Number(params.accountId);

    let accountHistoriesPromise;

    if (isTeamManager(userInfo)) {
      accountHistoriesPromise = shouldUseMockData
        ? fetchMockDataPromiseWithDelay(accountHistories, 6000)
        : HistoryService.getAccountHistorySummaries(accountId, 0, 10000);
    }

    const orgHistoriesPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(orgHistories, 700)
      : HistoryService.getOrganizationHistorySummaries(accountId, orgId, 0, 10000);

    return defer({
      accountHistoriesPromise: accountHistoriesPromise,
      orgHistoriesPromise: orgHistoriesPromise,
    });
  } catch (e) {
    console.log("Error fetching dashboard data: ", e);
    return {};
  }
}
