import { ThemeProvider, TimeoutModal, UserInfo, themes, color, useInterval } from "@bakkt/bakkt-ui-components";
import { Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { Header } from "./components/header/Header";
import { Footer } from "./components/footer/Footer";
import { LicenseInfo } from "@mui/x-license-pro";
import {
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useRevalidator,
} from "react-router-dom";
import {
  AccountService,
  AssetService,
  OrganizationService,
  PriceService,
  UserService,
  WalletService,
} from "./services/serviceLoader.ts";
import React, { useEffect, useState } from "react";
import { Account, Asset, Network, Organization, PriceInfo, User, Wallet, WalletType } from "./services/openAPI/client";
import {
  account,
  fetchMockDataPromiseWithDelay,
  organizations,
  pricingInfoInit,
  getRandomizedPricingInfo,
  wallets as walletMockData,
  users as userMockData,
  assets as assetMockData,
  networks as networkMockData,
} from "./services/mockData.ts";
import { shouldUseMockData, USER_SESSION_KEY } from "./utils/dataUtils.ts";
import { getUserSession, handleLogout } from "./utils/sessionUtils.ts";
import { PendingApproval } from "./components/pendingApproval/PendingApproval.tsx";
import { AlertDisplay } from "./utils/customTypes.ts";
import { featureFlagUser } from "./utils/featureFlagUtil.ts";
LicenseInfo.setLicenseKey(
  "3ae214fa0120d6bf6b5d84afa5a9950dTz02NzAyNyxFPTE3MTYzOTc0OTYzMDQsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",
);

function RootLayout() {
  const { account, organizations, userInfo, wallets, users, assets, networks } = useLoaderData() as {
    account: Account;
    organizations: Organization[];
    userInfo: UserInfo;
    wallets: Wallet[];
    users: User[];
    assets: Asset[];
    networks: Network[];
  };
  const { organizationId: orgIdFromParams } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const revalidator = useRevalidator();
  const defaultOrgId = (organizations[0] as Organization)?.id?.toString();
  const [selectedOrgId, setSelectedOrgId] = useState<string>(orgIdFromParams || defaultOrgId || "");
  const selectedOrg = organizations.find((org: Organization) => org.id?.toString() === orgIdFromParams);
  const [alerts, setAlerts] = useState<AlertDisplay[]>([]);
  const [orgDataCache, setOrgDataCache] = useState<OrgDataCache>({
    transactions: [],
    wallets: filterSegregatedWallets(wallets),
    omnibusWallets: filterOmniWallets(wallets),
    accountOmnibusWallets: wallets.filter((wallet) => wallet.type === WalletType.Trading),
    users: users,
    account: account,
    accountOrgs: organizations,
    offExchangeWallets: wallets.filter((wallet) => wallet.type === "Exchange"),
  } as OrgDataCache);
  const [priceFeed, setPriceFeed] = useState<PriceInfo[]>(pricingInfoInit);
  const [shouldRefreshPolicyItems, setShouldRefreshPolicyItems] = useState<boolean>(false);
  const [shouldRefreshOrgOmnibusWallets, setShouldRefreshOrgOmnibusWallets] = useState<boolean>(false);

  const getInitialPriceFeed = async () => {
    const feed: any = shouldUseMockData
      ? await fetchMockDataPromiseWithDelay(getRandomizedPricingInfo(), 20)
      : await PriceService.getPrices();

    setPriceFeed(feed);
  };

  const refreshIntervalMilliseconds = shouldRefreshOrgOmnibusWallets ? 5000 : 120000; // two minutes
  useInterval(async () => {
    const feed: any = shouldUseMockData
      ? await fetchMockDataPromiseWithDelay(getRandomizedPricingInfo(), 20)
      : await PriceService.getPrices();

    setPriceFeed(feed);
    await revalidator.revalidate();
    getSelectedOrgWallets();
    if (shouldRefreshOrgOmnibusWallets) {
      setShouldRefreshOrgOmnibusWallets(false);
    }
  }, refreshIntervalMilliseconds);

  const getSelectedOrgWallets = () => {
    const orgWallets = filterSegregatedWallets(wallets);
    const omnibusOrgWallets = filterOmniWallets(wallets);
    const accountOmnibusWallets = wallets.filter((wallet) => wallet.type === WalletType.Trading);
    const offExchangeOrgWallets = wallets.filter(
      (wallet) => wallet.type === WalletType.OffExchange && wallet.organizationId === Number(selectedOrgId),
    );
    setOrgDataCache((prevOrgCache) => ({
      ...prevOrgCache,
      wallets: orgWallets,
      omnibusWallets: omnibusOrgWallets,
      accountOmnibusWallets: accountOmnibusWallets,
      accountOrgs: organizations,
      offExchangeWallets: offExchangeOrgWallets,
    }));
  };

  function addAlert(alert: AlertDisplay) {
    setAlerts((prevAlerts) => {
      return [alert, ...prevAlerts];
    });
  }

  function filterSegregatedWallets(wallets: Wallet[]) {
    const segregatedWalletTypes: string[] = [WalletType.Trust, WalletType.Custody, WalletType.Deposit];
    return wallets.filter(
      (wallet) => wallet.organizationId === Number(selectedOrgId) && segregatedWalletTypes.includes(wallet.type),
    );
  }

  function filterOmniWallets(wallets: Wallet[]) {
    const omniWalletTypes: string[] = [WalletType.Trading, WalletType.Fee];
    return wallets.filter(
      (wallet) => wallet.organizationId === Number(selectedOrgId) && omniWalletTypes.includes(wallet.type),
    );
  }

  useEffect(() => {
    getInitialPriceFeed();
    setAlerts([]);
    getSelectedOrgWallets();
    if (selectedOrgId && location.pathname.indexOf(selectedOrgId) < 1) {
      if (location.pathname.includes("transactions")) {
        navigate(`transactions/${selectedOrgId}`);
      } else if (location.pathname.includes("audit")) {
        navigate(`audit/${selectedOrgId}/${account.id}`);
      } else if (location.pathname.includes("manage-users")) {
        navigate(`manage-users/${selectedOrgId}/${account.id}`);
      } else if (location.pathname.includes("organization")) {
        navigate(`organization/${selectedOrgId}`);
      } else if (location.pathname.includes("allowlist")) {
        navigate(`allowlist/${selectedOrgId}`);
      } else {
        navigate(`${selectedOrgId}`);
      }
    }
  }, [selectedOrgId]);

  return (
    <ThemeProvider theme={themes.light}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: color.oysterGray.main,
          },
          ".active": {
            fontWeight: "700",
          },
          ".body-links": {
            textDecoration: "underline",
            cursor: "pointer",
          },
          ".default-link-hover": {
            textDecoration: "none",
            cursor: "pointer",
            "&:hover": {
              color: `${color.energizingLime.main} !important`,
            },
          },
          ".content_wrapper": {
            padding: "32px 56px 32px 56px",
          },
          ".MuiContainer-maxWidthLg": {
            maxWidth: "3000px !important",
          },
          "header .MuiSelect-iconStandard": {
            fill: "#ffffff",
          },
        }}
      />
      <>
        <Header selectedOrgId={selectedOrgId} setSelectedOrgId={setSelectedOrgId} account={account} />
        <PendingApproval
          selectedOrgId={selectedOrgId}
          shouldRefreshPolicyItems={shouldRefreshPolicyItems}
          setShouldRefreshPolicyItems={setShouldRefreshPolicyItems}
        />
        <Container maxWidth="xl" disableGutters className="content_wrapper">
          <Outlet
            context={{
              userInfo,
              selectedOrg,
              alerts,
              setAlerts,
              addAlert,
              orgDataCache,
              setOrgDataCache,
              priceFeed,
              setPriceFeed,
              shouldRefreshPolicyItems,
              setShouldRefreshPolicyItems,
              setShouldRefreshOrgOmnibusWallets,
              shouldRefreshOrgOmnibusWallets,
              assets,
              networks,
            }}
          />
        </Container>
        <Footer />
      </>

      <TimeoutModal handleLogout={handleLogout} />
    </ThemeProvider>
  );
}

export default RootLayout;

export interface OrgDataCache {
  transactions: any[];
  wallets: Wallet[];
  users: User[];
  account: Account;
  omnibusWallets: Wallet[];
  accountOmnibusWallets: Wallet[];
  accountOrgs: Organization[];
  offExchangeWallets: Wallet[];
}

export type RootContextType = {
  userInfo: UserInfo;
  selectedOrg: Organization;
  alerts: AlertDisplay[];
  setAlerts: React.Dispatch<React.SetStateAction<AlertDisplay[]>>;
  addAlert: (alert: AlertDisplay) => void;
  orgDataCache: OrgDataCache;
  setOrgDataCache: React.Dispatch<React.SetStateAction<OrgDataCache>>;
  priceFeed: PriceInfo[];
  setPriceFeed: React.Dispatch<React.SetStateAction<PriceInfo[]>>;
  shouldRefreshPolicyItems: boolean;
  setShouldRefreshPolicyItems: React.Dispatch<React.SetStateAction<boolean>>;
  shouldRefreshOrgOmnibusWallets: boolean;
  setShouldRefreshOrgOmnibusWallets: React.Dispatch<React.SetStateAction<boolean>>;
  assets: Asset[];
  networks: Network[];
};

export function useRootContext() {
  return useOutletContext<RootContextType>();
}

export async function loader() {
  const userInfo: UserInfo = await getUserSession();
  await sessionStorage.setItem(USER_SESSION_KEY, JSON.stringify(userInfo));
  // load feature flag
  await featureFlagUser(userInfo);

  const accounts = shouldUseMockData
    ? await fetchMockDataPromiseWithDelay(account, 200)
    : await AccountService.getAccount(userInfo.accountId);

  const orgs = shouldUseMockData
    ? await fetchMockDataPromiseWithDelay(organizations, 200)
    : await OrganizationService.getOrganizations(userInfo.accountId);

  const wallets: any = shouldUseMockData
    ? await fetchMockDataPromiseWithDelay(walletMockData, 300)
    : await WalletService.getWallets(userInfo.accountId);

  const users = shouldUseMockData
    ? await fetchMockDataPromiseWithDelay(userMockData, 300)
    : await UserService.getUsers(userInfo.accountId);
  const assets = shouldUseMockData
    ? await fetchMockDataPromiseWithDelay(assetMockData, 300)
    : await AssetService.getAssets();
  const networks = shouldUseMockData
    ? await fetchMockDataPromiseWithDelay(networkMockData, 300)
    : await AssetService.getNetworks();
  return {
    account: accounts,
    organizations: orgs,
    userInfo: userInfo,
    wallets: wallets,
    users: users,
    assets: assets,
    networks: networks,
  };
}
