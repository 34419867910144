import { LoaderFunctionArgs, useFetcher, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { DialogActions } from "@mui/material";
import { Dialog, DialogTitle, Button } from "@bakkt/bakkt-ui-components";
import { useRootContext } from "../../RootLayout";
import { PolicyService } from "../../services/serviceLoader";
import { PolicyAction, PolicyActionActionEnum } from "../../services/openAPI/client";
import { formatActionErrorResponse, formatActionSuccessResponse } from "../../utils/responseHandlingUtils.ts";

const ConfirmAllowlist = () => {
  const { addAlert, selectedOrg, setShouldRefreshPolicyItems } = useRootContext();
  const fetcher = useFetcher();
  const [isSubmitError, setIsSubmitError] = useState<boolean>(false);
  const navigate = useNavigate();

  const { state } = useLocation();

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response?.success) {
        setShouldRefreshPolicyItems(true);
        setIsSubmitError(false);
        addAlert({
          severity: "success",
          messageHeader: "Allowlist addition has been approved.",
        });
        navigate(`/${selectedOrg.id}`);
      } else {
        setIsSubmitError(true);
      }
    }
  }, [fetcher.data]);

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleCancel = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleApprove = () => {
    const approvalData = {
      action: PolicyActionActionEnum.Approve,
      policyActionId: state.policyActionId,
      organizationId: state.organizationId,
      accountId: state.clientId,
    };
    fetcher.submit(JSON.stringify(approvalData), {
      method: "post",
      encType: "application/json",
    });
  };

  return (
    <>
      {isSubmitError ? (
        <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
          <DialogTitle title={"Failed to approve allowlist address"} severity="error">
            Please try again or reach out to support for assistance.
          </DialogTitle>

          <DialogActions>
            <Button variant={"outlined"} onClick={handleCancel}>
              Review Request
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
          <DialogTitle title={"Are you sure you want to approve this new address?"} severity="warning">
            After approving, your request will be reviewed by Bakkt within 48 hours. Once Bakkt has reviewed and
            approved your request, the organization will be able to transact with this address.
          </DialogTitle>

          <DialogActions>
            <Button variant={"outlined"} onClick={handleCancel}>
              Review Request
            </Button>
            <Button variant={"contained"} onClick={handleApprove} autoFocus>
              Approve
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ConfirmAllowlist;

export async function action({ request }: { request: Request; params: LoaderFunctionArgs | any }) {
  try {
    const reviewPolicyRequest = (await request.json()) as PolicyAction;
    const policyResponse = await PolicyService.applyAction(reviewPolicyRequest);
    return formatActionSuccessResponse(policyResponse);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
