import React, { useState } from "react";
import { Typography, color } from "@bakkt/bakkt-ui-components";
import { Unstable_Grid2 as Grid } from "@mui/material";
import WalletBalanceInfo from "./WalletBalanceInfo";

interface WalletBalanceInfoCardProps {
  title: string;
  cryptoBalance: number;
  cryptoTicker: string;
  usdBalance: string;
}

export const WalletBalanceInfoCard = ({
  title,
  cryptoBalance,
  cryptoTicker,
  usdBalance,
}: WalletBalanceInfoCardProps) => {
  const [shouldDisplayLongQty, setShouldDisplayLongQty] = useState(false);

  const infoBoxSx = {
    py: 1.2,
    px: 2.4,
    pb: 2,
    backgroundColor: color.designGray.light,
    borderTop: `2px solid ${color.designGray.light}`,
    "&:hover": {
      borderColor: "secondary.main",
      backgroundColor: color.provocativePlum[50],
    },
    height: `100%`,
  };

  return (
    <Grid
      container
      direction="column"
      sx={infoBoxSx}
      onMouseEnter={() => setShouldDisplayLongQty(true)}
      onMouseLeave={() => setShouldDisplayLongQty(false)}
    >
      <Typography variant="overline">{title}</Typography>
      <WalletBalanceInfo
        cryptoBalance={cryptoBalance}
        cryptoTicker={cryptoTicker}
        shouldDisplayLongQty={shouldDisplayLongQty}
      />
      <Typography variant="subtitle1">{usdBalance} USD</Typography>
    </Grid>
  );
};
