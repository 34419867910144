import { Typography, formatAmount, formatUnixTime } from "@bakkt/bakkt-ui-components";
import React, { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { WalletTransaction, WalletTransactionStatus } from "../../services/openAPI/client";
import { useAsyncValue } from "react-router-dom";
import { mapFauxIds } from "../../utils/dataUtils.ts";

export default function WalletDetailsTransGrid() {
  const walletTransactions = useAsyncValue() as WalletTransaction[];
  const pendingStatuses: string[] = [
    WalletTransactionStatus.Initiated,
    WalletTransactionStatus.Pending,
    WalletTransactionStatus.Signing,
    WalletTransactionStatus.Confirming,
  ];
  const pendingTransactions: WalletTransaction[] = walletTransactions.filter((tx) =>
    pendingStatuses.includes(tx.status || ""),
  );

  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "TIMESTAMP",
      width: 120,
      editable: false,
      renderCell: (params) => <>{formatUnixTime(params.value)}</>,
    },
    {
      field: "createdBy",
      headerName: "ORIGINATOR",
      width: 120,
      editable: false,
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 90,
      editable: false,
    },
    {
      field: "correlationId",
      headerName: "CORRELATION ID",
      width: 155,
      editable: false,
    },
    {
      field: "quantity",
      headerName: "QTY",
      width: 75,
      editable: false,
      valueFormatter: (params: any) => {
        return formatAmount(params.value);
      },
    },
    {
      field: "destinationAddress",
      headerName: "ADDRESS",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 100,
    },
    {
      field: "type",
      headerName: "TYPE",
      width: 90,
      editable: false,
    },
    {
      field: "sourceName",
      headerName: "WALLET ORIGINATION",
      width: 180,
      editable: false,
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
      },
    },
  };

  const data = { columns, initialState, rows: mapFauxIds(pendingTransactions) };

  return (
    <>
      {pendingTransactions.length === 0 && (
        <Typography variant="body1">No pending transactions. For transaction history, View Transactions.</Typography>
      )}

      {pendingTransactions.length > 0 && <DataGridPremium {...data} disableRowSelectionOnClick />}
    </>
  );
}
