import { formatDollarAmountUsd } from "@bakkt/bakkt-ui-components";
import { PriceInfo } from "../../../services/openAPI/client";
import { getAssetQtyByPrice, getPriceByAssetQty } from "../../../utils/dataUtils";
import { Typography } from "@bakkt/bakkt-ui-components";
import { RootContextType, useRootContext } from "../../../RootLayout";
import { CryptoTickerEnum } from "../../../utils/CryptoIconsMap";
import { useTheme } from "@mui/material/styles";

interface walletAvailableProps {
  amount: string;
  ticker: string;
  view: string;
  disabled?: boolean;
}

export const WalletBalanceAvailableMinimal = (props: walletAvailableProps) => {
  const { priceFeed, assets } = useRootContext() as RootContextType;
  const theme = useTheme();
  const { amount, ticker, view, disabled } = {
    amount: props.amount,
    ticker: props.ticker,
    view: props.view,
    disabled: props.disabled,
  };

  const valueToFixed = ticker === CryptoTickerEnum.BTC ? 8 : 18;
  return (
    <>
      {view === "CRYPTO" ? (
        <Typography variant="subtitle2" sx={{ color: disabled ? theme.palette.text.disabled : "" }}>
          USD Value: {formatDollarAmountUsd(getPriceByAssetQty(ticker, Number(amount), priceFeed, assets) || 0)}
        </Typography>
      ) : (
        <Typography variant="subtitle2" sx={{ color: disabled ? theme.palette.text.disabled : "" }}>
          {ticker} Value: {getAssetQtyByPrice(ticker, Number(amount), priceFeed, assets).toFixed(valueToFixed)}
        </Typography>
      )}
    </>
  );
};
