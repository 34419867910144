import { Await, LoaderFunctionArgs, Outlet, useLoaderData, useRevalidator } from "react-router-dom";
import { innerGridContainer, subText, wrapperContainer } from "../dashboard/styles";
import { Unstable_Grid2 as Grid } from "@mui/material";
import { Paper, Typography, formatUnixTime, useInterval } from "@bakkt/bakkt-ui-components";
import { Alert, Container, Stack } from "@mui/material";
import React, { useDeferredValue, useState } from "react";
import { AllTransactionsGrid } from "./AllTransactionsGrid";
import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { useRootContext } from "../../RootLayout";
import { WalletTransaction } from "../../services/openAPI/client";
import { fetchMockDataPromiseWithDelay, walletTransactions } from "../../services/mockData";
import { WalletTransactionService } from "../../services/serviceLoader";
import { shouldUseMockData } from "../../utils/dataUtils";

const AllTransactions = () => {
  const { transactionsPromise } = useLoaderData() as {
    transactionsPromise: Promise<WalletTransaction[]>;
  };
  const deferredTransactions = useDeferredValue(transactionsPromise);

  const revalidator = useRevalidator();
  const time = formatUnixTime(Date.now(), "long");
  const [updatedTransactionTime, setUpdatedTransactionTime] = useState(time);

  const refreshIntervalMilliseconds = 120000; // two minutes

  useInterval(() => {
    const time = formatUnixTime(Date.now(), "long");
    revalidator.revalidate();
    setUpdatedTransactionTime(time);
  }, refreshIntervalMilliseconds);

  return (
    <>
      <Outlet context={useRootContext()} />
      <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
        <Grid container spacing={2}>
          <Grid xs={9}>
            <Typography variant="h3">Transactions</Typography>
            <Typography variant="body1" sx={subText}>
              Last updated {updatedTransactionTime}
            </Typography>
          </Grid>
        </Grid>

        <Paper>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Container sx={innerGridContainer} data-testid="allTransactionsTable">
                <React.Suspense
                  fallback={
                    <Stack sx={{ mt: 12, mb: 7 }}>
                      <LoadingIndicator />
                    </Stack>
                  }
                >
                  <Await
                    resolve={deferredTransactions}
                    errorElement={<Alert severity="error">Error loading transaction data!</Alert>}
                  >
                    <AllTransactionsGrid />
                  </Await>
                </React.Suspense>
              </Container>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default AllTransactions;

export async function loader({ params }: LoaderFunctionArgs) {
  const orgId = Number(params.organizationId);
  const transactionsPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(walletTransactions, 3000)
    : WalletTransactionService.getWalletTransactions(undefined, orgId);

  return {
    transactionsPromise: transactionsPromise,
  };
}
