import { Button, Dialog, DialogTitle } from "@bakkt/bakkt-ui-components";
import { DialogActions } from "@mui/material";
import { useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate } from "react-router-dom";

import { useRootContext } from "../../RootLayout";
import { PolicyAction, PolicyActionActionEnum } from "../../services/openAPI/client";
import { PolicyService } from "../../services/serviceLoader";
import { formatActionErrorResponse, formatActionSuccessResponse } from "../../utils/responseHandlingUtils.ts";

const DeclineAllowlist = () => {
  const { addAlert, selectedOrg, setShouldRefreshPolicyItems } = useRootContext();
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const [isSubmitError, setIsSubmitError] = useState<boolean>(false);

  const { state } = useLocation();
  const [open, setOpen] = useState<boolean>(true);

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response?.success) {
        setIsSubmitError(false);
        setShouldRefreshPolicyItems(true);
        addAlert({
          severity: "warning",
          messageHeader: "Allowlist addition has been declined.",
        });
        navigate(`/${selectedOrg.id}`);
      } else {
        setIsSubmitError(true);
      }
    }
  }, [fetcher.data]);

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleCancel = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleDecline = () => {
    const declineData = {
      action: PolicyActionActionEnum.Deny,
      policyActionId: state.policyActionId,
      organizationId: state.organizationId,
      accountId: state.clientId,
    };
    fetcher.submit(JSON.stringify(declineData), {
      method: "post",
      encType: "application/json",
    });
  };

  return (
    <>
      {isSubmitError ? (
        <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
          <DialogTitle title={"Failed to decline allowlist address"} severity="error">
            Please try again or reach out to support for assistance.
          </DialogTitle>

          <DialogActions>
            <Button variant={"outlined"} onClick={handleCancel}>
              Review Request
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={false}>
          <DialogTitle title={"Are you sure you want to decline this new address?"} severity="error">
            Declining this address will result in a failed attempt to Allowlist the address.
          </DialogTitle>

          <DialogActions>
            <Button variant={"outlined"} onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant={"contained"} onClick={handleDecline} autoFocus>
              Decline
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DeclineAllowlist;

export async function action({ request }: { request: Request }) {
  try {
    const reviewPolicyRequest = (await request.json()) as PolicyAction;
    const policyResponse = await PolicyService.applyAction(reviewPolicyRequest);
    return formatActionSuccessResponse(policyResponse);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
