import { ClickAwayListener, IconButton, SvgIcon, Tooltip, Unstable_Grid2 as Grid } from "@mui/material";
import {
  AvailableAndPendingBalanceSummary,
  getAvailableAndPendingBalances,
  getDecimalLength,
  getPriceByAssetQty,
  getSVGStringForTicker,
  getScanLink,
} from "../../../utils/dataUtils";
import { Account, Wallet } from "../../../services/openAPI/client";
import { useState } from "react";
import { useRootContext } from "../../../RootLayout";
import { iconsMap } from "../../../utils/CryptoIconsMap";
import {
  Icons,
  Typography,
  formatDollarAmountUsd,
  formatCryptoQuantity,
  formatWalletAddress,
  Skeleton,
} from "@bakkt/bakkt-ui-components";
import { useTheme } from "@mui/material/styles";
import QuantityDisplay from "../../../components/quantityDisplay/QuantityDisplay";
import { AssetSVGIcon } from "../../../components/customSVG/AssetSVGIcon";
import { CollateralMinimal } from "./CollateralContext";
import { collateralActionType } from "../../../utils/customTypes";

interface ReviewCollateralProps {
  collateralRequest: Partial<CollateralMinimal>;
}

const ReviewCollateralDetails = ({ collateralRequest }: ReviewCollateralProps) => {
  const { orgDataCache, userInfo, selectedOrg, priceFeed, assets, networks } = useRootContext();
  const wallets: Wallet[] = [...orgDataCache.wallets, ...orgDataCache.offExchangeWallets];
  const account: Account = orgDataCache.account;
  const selectedWallet = wallets?.find((w) => w.walletId === collateralRequest!.fromWalletId) as Wallet;
  const offExchangeWallet = wallets?.find((w) => w.walletId === collateralRequest.offExchangeWalletId);
  const isPolicyCreated = collateralRequest.createdOn ? true : false;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showWalletAddressConfirm, setShowWalletAddressConfirm] = useState(false);

  const balances = getWalletBalances();

  function getWalletBalances(): AvailableAndPendingBalanceSummary {
    return getAvailableAndPendingBalances(offExchangeWallet, priceFeed, assets);
  }
  const availableBalance =
    balances.availableBalanceCrypto + (isPolicyCreated ? Number(collateralRequest?.quantity) : 0);

  const collateralBalance = offExchangeWallet?.lockedBalance;

  const getUpdatedBalance = (a: number, b: number) => {
    if (collateralRequest.action === collateralActionType.add) {
      return a + b; // this will be the collateral balance
    } else if (collateralRequest.action === collateralActionType.remove) {
      return a - b; // this will be the collateral balance
    }
  };

  const updatedCollateralBalance = getUpdatedBalance(collateralBalance || 0, Number(collateralRequest.quantity) || 0);
  const updatedBalance = getUpdatedBalance(availableBalance || 0, Number(collateralRequest.quantity));

  const copyWalletAddress = (address: string) => {
    setShowWalletAddressConfirm(true);
    navigator.clipboard.writeText(address || "");
  };
  const goToIconStyle = { width: 14, height: 14 };
  const copyIconStyle = { width: 15, height: 15, ml: 1, mr: 1.2 };
  const scanWalletAddress = getScanLink(
    selectedWallet?.assetTicker || "",
    selectedWallet?.address || "",
    assets,
    networks,
  );

  const theme = useTheme();

  return (
    <>
      {isLoading && <Skeleton variant="rectangular" height={500} width={520} />}
      {!isLoading && (
        <Grid container spacing={1}>
          <Grid xs={12}>
            <Typography sx={{ fontWeight: 600 }}>Source</Typography>
            <Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">CLIENT</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle1">{collateralRequest.clientName || account?.name}</Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">ORGANIZATION</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle1">{collateralRequest.orgName || selectedOrg.name}</Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">REQUESTED BY</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle1">
                    {collateralRequest.requesterName || userInfo.preferred_username}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">ACTION</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle1">{collateralRequest.action?.replaceAll("_", " ")}</Typography>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">ORIGINATION</Typography>
                </Grid>
                <Grid>
                  <Grid container justifyContent={"flex-end"}>
                    <Typography variant="subtitle1">
                      <SvgIcon
                        component={() =>
                          AssetSVGIcon({
                            svgString: getSVGStringForTicker(assets, collateralRequest.assetTicker as string),
                            title: collateralRequest.assetTicker as string,
                            sx: { width: 16, height: 16, mr: 1, mb: -0.3 },
                          })
                        }
                        inheritViewBox
                      />

                      {selectedWallet?.name}
                    </Typography>
                  </Grid>

                  <Grid direction="row" sx={{ textAlign: "right" }}>
                    {formatWalletAddress(selectedWallet.address as string)}
                    <ClickAwayListener onClickAway={() => setShowWalletAddressConfirm(false)}>
                      <Tooltip
                        onClose={() => setShowWalletAddressConfirm(false)}
                        open={showWalletAddressConfirm}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        placement="top"
                        title="Address Copied"
                      >
                        <IconButton
                          sx={{ m: 0, p: 0 }}
                          onClick={() => copyWalletAddress(selectedWallet.address as string)}
                          disableRipple
                          disableFocusRipple
                        >
                          <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={copyIconStyle} />
                        </IconButton>
                      </Tooltip>
                    </ClickAwayListener>

                    <IconButton sx={{ m: 0, p: 0 }} href={scanWalletAddress} target="_blank" disableRipple={true}>
                      <SvgIcon component={Icons.GoToIcon} inheritViewBox sx={goToIconStyle} />
                    </IconButton>
                  </Grid>
                  <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
                    #{selectedWallet.walletId}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Typography sx={{ fontWeight: 600 }}>Destination</Typography>
            <Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">EXCHANGE</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle1">
                    <SvgIcon
                      component={iconsMap["DBIT"]}
                      inheritViewBox
                      sx={{ width: 20, height: 20, mr: 1, mb: -0.6 }}
                    />
                    {collateralRequest.exchange}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Typography sx={{ fontWeight: 600 }}>Amounts</Typography>
            <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
              <Grid>
                <Typography variant="subtitle2">AVAILABLE BALANCE</Typography>
              </Grid>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                <Typography variant="subtitle1">
                  <QuantityDisplay quantity={availableBalance || 0} ticker={selectedWallet.assetTicker || ""} />{" "}
                  {(selectedWallet && selectedWallet.assetTicker) || ""}
                </Typography>
                <Typography variant="subtitle1">
                  {formatDollarAmountUsd(
                    getPriceByAssetQty(
                      collateralRequest.assetTicker as string,
                      Number(availableBalance),
                      priceFeed,
                      assets,
                    ) || 0,
                  )}{" "}
                  USD
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
              <Grid>
                <Typography variant="subtitle2">COLLATERAL BALANCE</Typography>
              </Grid>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                <Typography variant="subtitle1">
                  {formatCryptoQuantity(
                    Number(collateralBalance) || 0,
                    collateralRequest.assetTicker || "",
                    "long",
                    getDecimalLength(Number(collateralBalance)),
                  )}{" "}
                  {(selectedWallet && selectedWallet.assetTicker) || ""}
                </Typography>
                <Typography variant="subtitle1">
                  {formatDollarAmountUsd(
                    getPriceByAssetQty(
                      collateralRequest.assetTicker as string,
                      Number(collateralBalance),
                      priceFeed,
                      assets,
                    ) || 0,
                  )}{" "}
                  USD
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
              <Grid>
                <Typography variant="subtitle2">COLLATERAL AMOUNT</Typography>
              </Grid>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                <Typography variant="subtitle1">
                  {formatCryptoQuantity(
                    Number(collateralRequest.quantity) || 0,
                    collateralRequest.assetTicker || "",
                    "long",
                    getDecimalLength(Number(collateralRequest.quantity)),
                  )}{" "}
                  {(selectedWallet && selectedWallet.assetTicker) || ""}
                </Typography>
                <Typography variant="subtitle1">
                  {formatDollarAmountUsd(
                    getPriceByAssetQty(
                      collateralRequest.assetTicker as string,
                      Number(collateralRequest.quantity),
                      priceFeed,
                      assets,
                    ) || 0,
                  )}{" "}
                  USD
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Grid sx={{ borderBottom: 1, borderColor: theme.palette.divider, pb: 2 }}></Grid>
            <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
              <Grid>
                <Typography variant="subtitle2">UPDATED COLLATERAL BALANCE</Typography>
              </Grid>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                <Typography variant="subtitle1">
                  <QuantityDisplay quantity={updatedCollateralBalance || 0} ticker={selectedWallet.assetTicker || ""} />{" "}
                  {(selectedWallet && selectedWallet.assetTicker) || ""}
                </Typography>
                <Typography variant="subtitle1">
                  {formatDollarAmountUsd(
                    getPriceByAssetQty(
                      collateralRequest.assetTicker as string,
                      Number(updatedCollateralBalance),
                      priceFeed,
                      assets,
                    ) || 0,
                  )}{" "}
                  USD
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
              <Grid>
                <Typography variant="subtitle2">UPDATED AVAILABLE BALANCE</Typography>
              </Grid>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                <Typography variant="subtitle1">
                  <QuantityDisplay quantity={updatedBalance || 0} ticker={selectedWallet.assetTicker || ""} />{" "}
                  {(selectedWallet && selectedWallet.assetTicker) || ""}
                </Typography>
                <Typography variant="subtitle1">
                  {formatDollarAmountUsd(
                    getPriceByAssetQty(
                      collateralRequest.assetTicker as string,
                      Number(updatedBalance),
                      priceFeed,
                      assets,
                    ) || 0,
                  )}{" "}
                  USD
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ReviewCollateralDetails;
