import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SvgIcon,
  Unstable_Grid2 as Grid,
  Stack,
  Typography,
  Chip,
} from "@mui/material";

import { Dialog, DialogTitle, Button, TextField, Alert, isValidAddress } from "@bakkt/bakkt-ui-components";
import { API_URL } from "../../utils/dataUtils.ts";
import { CryptoTickerEnum, DestinationTypeEnum, OwnerTypeEnum } from "../../utils/CryptoIconsMap.ts";
import { AllowlistAddress, CreateAllowlistRequestTypeEnum } from "../../services/openAPI/client/api.ts";
import { useRootContext } from "../../RootLayout";
import { AdditionalInfo } from "./AdditionalInfo";
import { AssetSVGIcon } from "../../components/customSVG/AssetSVGIcon.tsx";

export interface AllowListFormData {
  address: string;
  type: CreateAllowlistRequestTypeEnum;
  organizationId: number;
  assetSymbol: string;
  name: string;
  financialInstitutionName: string;
  financialInstitutionExternalId: string;
  accountId: number;
  isSelfHosted: boolean;
  ownerType?: OwnerTypeEnum.Individual | OwnerTypeEnum.Company | "";
  individualFirstName?: string;
  individualLastName?: string;
  companyName?: string;
  agreeTerms?: boolean;
  destinationType: DestinationTypeEnum.Centralized | DestinationTypeEnum.SelfHosted | "";
}

export const blankAllowlistFormData: AllowListFormData = {
  address: "", //0x1f9090aaE28b8a3dCeaDf281B0F12828e676c326
  type: CreateAllowlistRequestTypeEnum.External,
  organizationId: -1,
  assetSymbol: "",
  name: "",
  financialInstitutionName: "",
  financialInstitutionExternalId: "",
  accountId: -1,
  isSelfHosted: false,
  ownerType: "",
  destinationType: "",
};

export default function AddAllowlist() {
  const navigate = useNavigate();
  const { selectedOrg, orgDataCache, assets } = useRootContext();
  const [open, setOpen] = useState(true);
  const [allowlist, setAllowlist] = useState<AllowListFormData>(blankAllowlistFormData);
  const [selectedAsset, setSelectedAsset] = useState<string>("");
  const [formStep, setFormStep] = useState<number>(1);
  const [addressError, setAddressError] = useState<string>("");
  const { state } = useLocation();
  const allowlistItems = state.allowlistItems as AllowlistAddress[];

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleCancel = () => {
    setOpen(false);
    navigate(`/allowlist/${selectedOrg.id}`);
  };

  const handleChangeAsset = (event: SelectChangeEvent) => {
    setSelectedAsset(event.target.value as string);
    allowlist.assetSymbol = event.target.value;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    updateField(name, value);
  };

  const updateField = (fieldName: string, value: string) => {
    setAllowlist((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleNext = () => {
    const isValid = handleAddressValidation(allowlist.address, allowlist.assetSymbol, allowlistItems);
    if (isValid) {
      setFormStep((prevValue) => prevValue + 1);
      setAllowlist((prevData) => ({
        ...prevData,
        accountId: orgDataCache.account.id,
        organizationId: selectedOrg.id,
        address: removeTrailingValid(allowlist.address),
      }));
    }
  };

  const isFormValid = () => {
    if (allowlist.name !== "" && allowlist.assetSymbol !== "" && allowlist.address !== "") {
      return true;
    }
    return false;
  };

  function isSkipValidation(address: string) {
    const skip = /.*-valid$/;
    return skip.test(address);
  }

  function removeTrailingValid(address: string): string {
    const skip = /.*-valid$/;
    if (skip.test(address)) {
      return address.slice(0, -6);
    }
    return address;
  }

  const handleAddressValidation = (address: string, assetSymbol: string, allowlistAddresses: AllowlistAddress[]) => {
    //TODO: This function can be updated to include checking if address exists on chain.
    // returns true if address is present and matches assetSymbol/false if not present and doesn't match assetSymbol
    // isValid takes ticker. finding ticker in assets.
    const assetTicker = assets.find((asset) => asset.symbol === assetSymbol);
    const isDuplicateAddress = allowlistAddresses.some(
      (allowlist: AllowlistAddress) =>
        allowlist.address === removeTrailingValid(address || "") && allowlist.assetSymbol === assetSymbol,
    );
    const environment: string = API_URL === "https://api.custody.bakkt.com" ? "prod" : "testnet";

    if (
      isSkipValidation(address) ||
      (isValidAddress(address, assetTicker?.ticker as string, environment) && !isDuplicateAddress)
    ) {
      setAddressError("");
      return true;
    } else if (!isValidAddress(address, assetTicker?.ticker as string, environment) && !isDuplicateAddress) {
      setAddressError(`Invalid ${assetTicker?.ticker as string} address`);
      return false;
    } else {
      setAddressError(`Duplicate address, address is already allow listed.`);
      return false;
    }
  };

  return (
    <>
      {formStep === 1 && (
        <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={false}>
          <DialogTitle title={"Allowlist"}>
            Requests require approval from your organization and Bakkt Support. Once approved, it will take up to 48
            hours to activate new addresses.
          </DialogTitle>
          <DialogContent sx={{ pt: "0 !important" }}>
            <Grid container sx={{ my: 3 }}>
              <Grid xs={12}>
                <Stack spacing={2}>
                  {addressError ? (
                    <Alert severity="error" title={"Address Validation Error"}>
                      {addressError}
                    </Alert>
                  ) : (
                    ""
                  )}
                </Stack>
              </Grid>
            </Grid>
            <Grid container rowSpacing={4} data-testid="addAddressContainerGrid">
              <Grid xs={12}>
                <FormControl variant="standard" sx={{ width: "100%" }} required>
                  <InputLabel id="select-asset">Asset</InputLabel>
                  <Select value={selectedAsset} label="asset" id="asset" onChange={handleChangeAsset}>
                    {assets.map((asset, index) => (
                      <MenuItem key={index} value={asset.symbol} disabled={asset.ticker === CryptoTickerEnum.ETC}>
                        {asset.ticker === CryptoTickerEnum.ETC ? (
                          <>
                            <Grid container alignItems={"center"} sx={{ p: 1, pb: 0 }}>
                              <SvgIcon
                                component={() =>
                                  AssetSVGIcon({
                                    svgString: asset.iconSVG,
                                    title: asset.ticker,
                                    sx: { width: 32, height: 32, pr: 1 },
                                  })
                                }
                                inheritViewBox
                              />
                              <Typography sx={{ fontWeight: 600, mr: 1 }}>{asset.name}</Typography>
                              <Typography variant={"body2"} sx={{ mr: 1 }}>
                                {asset.ticker}
                              </Typography>
                              <Chip key={asset.ticker} label={"Contact Support"} />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid container alignItems={"center"} sx={{ p: 1, pb: 0 }}>
                              <SvgIcon
                                component={() =>
                                  AssetSVGIcon({
                                    svgString: asset.iconSVG,
                                    title: asset.ticker,
                                    sx: { width: 32, height: 32, pr: 1 },
                                  })
                                }
                                inheritViewBox
                              />
                              <Typography sx={{ fontWeight: 600, mr: 1 }}>{asset.name}</Typography>
                              <Typography variant={"body2"}>{asset.ticker}</Typography>
                            </Grid>
                          </>
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <TextField
                  name="name"
                  value={allowlist.name}
                  onChange={handleInputChange}
                  label="Wallet Address Name"
                  id="name"
                  variant="standard"
                  fullWidth
                  required
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  name="address"
                  value={allowlist.address}
                  onChange={handleInputChange}
                  label="Wallet Address"
                  id="address"
                  variant="standard"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Grid container xs={12} spacing={2} direction={"column"} alignItems={"flex-end"} sx={{ pt: 8, pr: 1 }}>
              <Stack direction={"row"} spacing={2}>
                <Button variant={"outlined"} onClick={handleCancel}>
                  Cancel
                </Button>
                <Button variant={"contained"} onClick={handleNext} autoFocus disabled={!isFormValid()}>
                  Next
                </Button>
              </Stack>
              <Grid>
                <FormHelperText>
                  *Your organization requires {selectedOrg.numberOfConsensus} approval(s) to add a new address to your
                  Allowlist.
                </FormHelperText>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      {formStep === 2 && <AdditionalInfo allowlistFormData={allowlist} />}
    </>
  );
}
