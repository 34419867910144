import { UserInfo } from "@bakkt/bakkt-ui-components";
import { AccountPermission, OrganizationPermission, PolicyStatusEnum } from "../services/openAPI/client";

export function isViewOnly(userInfo: UserInfo, orgId: number): boolean {
  const perms = JSON.parse(userInfo.permissions)?.organizationPermissions.find((x: any) => x.organizationId == orgId);
  if (perms) {
    if (
      perms.activePermissions.includes(OrganizationPermission.View) &&
      !perms.activePermissions.includes(OrganizationPermission.Initiator) &&
      !perms.activePermissions.includes(OrganizationPermission.Approver) &&
      !perms.activePermissions.includes(OrganizationPermission.VideoAuthorization)
    ) {
      return true;
    }
  }
  return false;
}

export function isInitiator(userInfo: UserInfo, orgId: number): boolean {
  const perms = JSON.parse(userInfo.permissions)?.organizationPermissions.find((x: any) => x.organizationId == orgId);
  if (perms) {
    return perms.activePermissions.includes(OrganizationPermission.Initiator);
  }
  return false;
}

export function isApprover(userInfo: UserInfo, orgId: number): boolean {
  const perms = JSON.parse(userInfo.permissions)?.organizationPermissions.find((x: any) => x.organizationId == orgId);
  if (perms) {
    return perms.activePermissions.includes(OrganizationPermission.Approver);
  }
  return false;
}

export function isTeamManager(userInfo: UserInfo): boolean {
  const perms = JSON.parse(userInfo.permissions)?.accountPermissions;
  if (perms) {
    return perms.activePermissions.includes(AccountPermission.TeamManager);
  }
  return false;
}

export function isPolicyDisabled(
  approvalCount: number,
  consensusNumber: number,
  instanceStatus: PolicyStatusEnum,
  sequenceStatus: PolicyStatusEnum,
  isApproved: boolean,
): boolean {
  if (
    (approvalCount === consensusNumber &&
      sequenceStatus === PolicyStatusEnum.Completed &&
      instanceStatus === PolicyStatusEnum.Initiated) ||
    sequenceStatus === PolicyStatusEnum.Expired ||
    instanceStatus === PolicyStatusEnum.Expired ||
    isApproved
  ) {
    return true;
  } else {
    return false;
  }
}
