import { color } from "@bakkt/bakkt-ui-components";

export const cardWidth = {
  width: 325,
};

export const cardContentActiveUserTop = {
  backgroundColor: color.designGray.light,
  height: 130,
  display: "flex",
  justifyContent: "space-between",
};

export const cardContentUserBottom = {
  backgroundColor: color.designGray.main,
  height: 110,
};

export const cardContentDeactivatedUserTop = {
  backgroundColor: color.designGray.dark,
  height: 130,
  display: "flex",
  justifyContent: "space-between",
};

export const userPremissionsChip = {
  backgroundColor: "white",
  "& .MuiChip-label": {
    textTransform: "capitalize",
  },
};

export const pendingRequestAmount = {
  fontWeight: "bold",
  mb: 1,
};

export const currentAmountColor = {
  color: color.techBlack["700"],
};

export const currentAmountDollar = {
  fontWeight: "bold",
};

export const userFontWeightNormal = {
  fontWeight: "normal",
};

export const addUserIcon = {
  width: 25,
  height: 25,
  pr: 1,
};

export const pendingColor = {
  color: color.engagingOrange.dark,
};

export const organizationGridSpacing = {
  pt: 5,
  pr: 5,
  pb: 5,
  pl: 2.5,
};

export const lockedColor = {
  color: color.steadfastWatermelon.dark,
};

export const userEditDivider = {
  height: 0.15,
  mx: 1,
  my: 0.5,
};

export const noDeactivatedUsers = {
  display: "flex",
  justifyContent: "center",
  pb: 2,
};

export const cardGridContainer = {
  flexGrow: 1,
};
