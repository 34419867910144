import { useAsyncValue, Link as RouterLink } from "react-router-dom";
import { CryptoTickerEnum, iconsMap } from "../../utils/CryptoIconsMap.ts";
import { Icons, formatDollarAmountUsd, formatWalletAddress } from "@bakkt/bakkt-ui-components";
import {
  Box,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
  Unstable_Grid2 as Grid,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";
import { useState } from "react";
import { useRootContext } from "../../RootLayout.tsx";
import {
  convertPricingInfoArrayIntoMap,
  getMarketPriceForWallet,
  getSVGStringForTicker,
  sortWalletsByAssetId,
} from "../../utils/dataUtils.ts";
import QuantityDisplay from "../../components/quantityDisplay/QuantityDisplay.tsx";
import { Wallet, WalletType } from "../../services/openAPI/client/api.ts";
import { ActivateOmnibusWallet } from "../wallet/ActivateOmnibusWallet.tsx";
import { isInitiator } from "../../utils/permissionsUtil.ts";
import { AssetSVGIcon } from "../../components/customSVG/AssetSVGIcon.tsx";

export function OmnibusWalletsGrid() {
  const wallets: any = useAsyncValue() as Wallet[];
  const { priceFeed, selectedOrg, userInfo, assets } = useRootContext();
  const selectedOrgOmnibusWallets = wallets.filter(
    (wallet: Wallet) =>
      wallet.organizationId === selectedOrg.id &&
      (wallet.type === WalletType.Trading || wallet.type === WalletType.Fee),
  );

  const [open, setOpen] = useState(false);

  const pricingInfoMap = convertPricingInfoArrayIntoMap(priceFeed, assets);

  const getOmnibusWalletBalance = (wallet: Wallet) => {
    const priceInfo = pricingInfoMap[String(wallet.assetTicker)];
    const balance = Number(wallet.quantity) * (priceInfo.bidPrice || 0);
    return formatDollarAmountUsd(balance);
  };

  const tableHeader = {
    fontWeight: 500,
    fontSize: 16,
  };

  const linkTextSx = {
    textDecoration: "underline",
  };

  const handleCopyAddress = (address: string) => {
    setOpen(true);
    navigator.clipboard.writeText(address);
  };

  return (
    <>
      {selectedOrgOmnibusWallets.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" sx={[tableHeader, { pl: 0 }]} width="200">
                  ASSET
                </TableCell>
                <TableCell component="th" align="left" sx={tableHeader} width="200">
                  BAKKT NETWORK ID
                </TableCell>
                <TableCell component="th" align="left" sx={tableHeader} width="200">
                  DESCRIPTION
                </TableCell>
                <TableCell component="th" align="left" sx={tableHeader} width="200">
                  NETWORK
                </TableCell>
                <TableCell component="th" align="right" sx={tableHeader}>
                  MARKET PRICE
                </TableCell>
                <TableCell component="th" align="right" sx={tableHeader}>
                  QUANTITY
                </TableCell>
                <TableCell component="th" align="right" sx={tableHeader}>
                  BALANCE
                </TableCell>
                <TableCell component="th" align="right" sx={[tableHeader, { pr: 0.2 }]} width="250">
                  ACTIONS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortWalletsByAssetId(selectedOrgOmnibusWallets, assets).map((wallet: Wallet, index: number) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" sx={{ pl: 0.2 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <SvgIcon
                        component={() =>
                          AssetSVGIcon({
                            svgString: getSVGStringForTicker(assets, wallet.assetTicker),
                            title: wallet.assetTicker,
                            sx: { width: 25, height: 25, mx: 1 },
                          })
                        }
                        inheritViewBox
                      />
                      <Box>
                        <Typography variant="h5" sx={{ fontWeight: 500 }}>
                          <span>{assets.find((asset) => asset.symbol === wallet.assetSymbol)?.name || ""}</span>
                        </Typography>
                        <Typography variant="body2">{wallet.assetTicker as string}</Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      aria-label="copy"
                      onClick={() => handleCopyAddress(String(wallet.address))}
                      disableRipple
                      disableFocusRipple
                    >
                      <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={{ width: 15, height: 15 }} />
                    </IconButton>
                    <Tooltip title={wallet.address}>
                      <span>{formatWalletAddress(String(wallet.address))}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">{wallet.description}</TableCell>
                  <TableCell align="left">{wallet.network}</TableCell>
                  <TableCell align="right">{getMarketPriceForWallet(wallet, pricingInfoMap)}</TableCell>
                  <TableCell align="right">
                    <QuantityDisplay quantity={wallet.quantity as number} ticker={wallet.assetTicker as string} />
                  </TableCell>
                  <TableCell align="right">{getOmnibusWalletBalance(wallet)}</TableCell>
                  <TableCell align="right" sx={{ pr: 0.2 }}>
                    <Grid justifyContent="space-between">
                      <RouterLink to={`deposit/${wallet.walletId}`}>
                        <Box component="span" sx={linkTextSx}>
                          Deposit
                        </Box>
                      </RouterLink>{" "}
                      {isInitiator(userInfo, Number(selectedOrg.id)) && (
                        <>
                          |{" "}
                          <RouterLink to={`transfer/${wallet.walletId}`}>
                            <Box component="span" sx={linkTextSx}>
                              Transfer
                            </Box>
                          </RouterLink>{" "}
                        </>
                      )}
                      {isInitiator(userInfo, Number(selectedOrg.id)) && (
                        <>
                          |{" "}
                          <RouterLink to={`withdraw/${wallet.walletId}`}>
                            <Box component="span" sx={linkTextSx}>
                              Withdraw
                            </Box>
                          </RouterLink>
                        </>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <ActivateOmnibusWallet />
      )}
    </>
  );
}
