import { Alert, Button, DialogTitle, Divider } from "@bakkt/bakkt-ui-components";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import SelectCollateralAction from "./SelectCollateralAction";
import SelectCollateralSource from "./SelectCollateralSource";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CollateralMinimal, ManageCollateralContext } from "./CollateralContext";
import ReviewCollateralDetails from "./ReviewCollateralDetails";
import { collateralActionType } from "../../../utils/customTypes";

const ManageCollateralModal = () => {
  const context = useContext(ManageCollateralContext);
  const {
    selectedWallet,
    collateral,
    collateralStep,
    collateralAction,
    handleReviewCollateralRequest,
    handleCreateCollateralRequest,
    offExchangeWallet,
  } = context;
  const [open, setOpen] = useState(true);

  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const disableCollateralRequest = () => {
    const disableCollateralCritera = [
      Number(collateral?.quantity) > Number(selectedWallet?.quantity),
      Boolean(collateral?.quantity) === false,
      Number(offExchangeWallet?.lockedBalance) - Number(collateral?.quantity) < 0 &&
        collateral?.action === collateralActionType.remove,
    ];
    if (disableCollateralCritera.includes(true)) {
      return true;
    } else {
      return false;
    }
  };

  const isCollateralRequestDisabled = disableCollateralRequest();

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
      {collateralStep == 1 && (
        <>
          <DialogTitle title="Manage Collateral" severity="secondary">
            Add collateral to or remove collateral from your Bakkt Off Exchange collateral balance.
          </DialogTitle>
          <DialogContent>
            <Alert severity="info" sx={{ mb: 2 }}>
              Collateral changes are processed as on-chain transactions at the time the request is fully completed after
              receiving full client consensus and Bakkt Support approval. <br /> <br /> In the event of an insufficient
              collateral balance when a collateral transaction is completed, the maximum available collateral amount
              will be applied.
            </Alert>
            <SelectCollateralAction />
            <Divider sx={{ my: 3 }} />
            {collateralAction && <SelectCollateralSource />}
          </DialogContent>
          <DialogActions>
            <Button variant={"contained"} onClick={handleClose} autoFocus>
              Close
            </Button>
            <Button
              variant={"contained"}
              onClick={handleReviewCollateralRequest}
              autoFocus
              disabled={isCollateralRequestDisabled}
            >
              Review Collateral Change
            </Button>
          </DialogActions>
        </>
      )}
      {collateralStep == 2 && (
        <>
          <DialogTitle title="Review Collateral Change" severity="warning">
            Review your collateral change details below.{" "}
          </DialogTitle>
          <DialogContent>
            <ReviewCollateralDetails collateralRequest={collateral as Partial<CollateralMinimal>} />
          </DialogContent>
          <DialogActions>
            <Button variant={"contained"} onClick={handleClose} autoFocus>
              Close
            </Button>
            <Button variant={"contained"} onClick={handleCreateCollateralRequest} autoFocus>
              Create Collateral Change Request
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ManageCollateralModal;
