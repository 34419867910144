import { Box, Container, Skeleton } from "@mui/material";
import { innerGridContainer, subText, wrapperContainer } from "./styles.tsx";
import { Unstable_Grid2 as Grid } from "@mui/material";
import { Alert, Paper, Typography } from "@bakkt/bakkt-ui-components";
import React, { useDeferredValue } from "react";
import { Await, useLoaderData, Link as RouterLink } from "react-router-dom";
import { TransactionsGrid } from "./TransactionsGrid.tsx";
import { useRootContext } from "../../RootLayout.tsx";

export default function Transactions() {
  const { transactionsPromise }: any = useLoaderData();
  const { selectedOrg } = useRootContext();
  const deferredTransactions = useDeferredValue(transactionsPromise);

  return (
    <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid>
          <Typography variant="h3" data-testid="latestTransactions">
            Latest Transactions
          </Typography>
          <Typography variant="body1" sx={subText}>
            Showing the 10 most recent transactions for {selectedOrg.name}.
          </Typography>
        </Grid>
        <Grid sx={{ mt: 6, mr: 1 }}>
          <RouterLink to={`/transactions/${selectedOrg.id}`}>
            <Box component="span" sx={{ textDecoration: "underline" }}>
              View All Transactions
            </Box>
          </RouterLink>
        </Grid>
      </Grid>

      <Paper>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Container sx={innerGridContainer} data-testid="dashboardTransactionsTable">
              <React.Suspense fallback={<Skeleton variant="rectangular" height={300} />}>
                <Await
                  resolve={deferredTransactions}
                  errorElement={<Alert severity="error">Error loading transaction data!</Alert>}
                >
                  <TransactionsGrid />
                </Await>
              </React.Suspense>
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
