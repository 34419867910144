import { Box, IconButton, SvgIcon, Tooltip } from "@mui/material";
import { Icons, Typography, formatCryptoQuantity } from "@bakkt/bakkt-ui-components";
import { useState } from "react";

interface WalletBalanceInfoProps {
  cryptoBalance: number;
  cryptoTicker: string;
  shouldDisplayLongQty: boolean;
}

const WalletBalanceInfo = ({ cryptoBalance, cryptoTicker, shouldDisplayLongQty }: WalletBalanceInfoProps) => {
  const [open, setOpen] = useState(false);

  const balanceFont = shouldDisplayLongQty ? "h6" : "h5";
  const iconSx = { width: 10, color: "primary.contrastText" };

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(String(formatCryptoQuantity(cryptoBalance, cryptoTicker, "long")));
  };

  return (
    <Typography variant={balanceFont}>
      <Tooltip
        title={
          open ? (
            `${formatCryptoQuantity(cryptoBalance, cryptoTicker, "long")} ${cryptoTicker} copied`
          ) : (
            <Box>
              <IconButton onClick={handleClick} sx={{ p: 0, m: 0.4 }} disableRipple disableFocusRipple>
                <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={iconSx} />
              </IconButton>
              {formatCryptoQuantity(cryptoBalance, cryptoTicker, "long")} {cryptoTicker}
            </Box>
          )
        }
        onClick={handleClick}
        onMouseLeave={() => setOpen(false)}
        placement="top"
      >
        <span>
          {shouldDisplayLongQty
            ? `${formatCryptoQuantity(cryptoBalance, cryptoTicker, "long")} `
            : `${formatCryptoQuantity(cryptoBalance)} `}
          {cryptoTicker}
        </span>
      </Tooltip>
    </Typography>
  );
};

export default WalletBalanceInfo;
