import { useAsyncValue, useRouteLoaderData } from "react-router-dom";
import { useState } from "react";
import {
  GridColDef,
  GridPagination,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";
import {
  Box,
  IconButton,
  Snackbar,
  SvgIcon,
  Tooltip,
  Unstable_Grid2 as Grid,
  TablePaginationProps,
} from "@mui/material";
import { DataGridPremium, Icons, Button, Pagination, formatUnixTime } from "@bakkt/bakkt-ui-components";
import Paper from "@mui/material/Paper";
import { AllowlistType, PolicyActionDetails } from "../../services/openAPI/client";
import { Link as RouterLink } from "react-router-dom";
import { RootContextType, useRootContext } from "../../RootLayout";
import { isInitiator } from "../../utils/permissionsUtil";
import { getSVGStringForTicker } from "../../utils/dataUtils";
import { AssetSVGIcon } from "../../components/customSVG/AssetSVGIcon.tsx";

export const AllowlistGrid = () => {
  const allowlistItems: any = useAsyncValue() as PolicyActionDetails[];
  const { userInfo }: any = useRouteLoaderData("root");
  const { selectedOrg, assets } = useRootContext() as RootContextType;
  const [open, setOpen] = useState(false);

  const handleClick = (hash: string) => {
    setOpen(true);
    navigator.clipboard.writeText(hash);
  };

  const csvOptions = {
    fileName: `${selectedOrg.name} Allowlist ${formatUnixTime(Date.now())}`,
  };
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Grid container xs={12} justifyContent="space-between">
          <GridToolbarQuickFilter />
          <RouterLink
            to={isInitiator(userInfo, Number(selectedOrg.id)) ? `/allowlist/${Number(selectedOrg.id)}/new` : "#"}
            state={{ allowlistItems: allowlistItems }}
          >
            <Button variant={"contained"} disabled={!isInitiator(userInfo, Number(selectedOrg.id))}>
              <SvgIcon component={Icons.PlusIcon} inheritViewBox sx={{ width: 20, height: 20, pr: 1 }} />
              Add New Address
            </Button>
          </RouterLink>
        </Grid>
        <Grid container xs={12}>
          <GridToolbar csvOptions={csvOptions} />
        </Grid>
      </GridToolbarContainer>
    );
  };

  const paginationSx = {
    "& .MuiPagination-ul": {
      flexWrap: "nowrap",
    },
  };

  function MuiPagination({ page, onPageChange }: Pick<TablePaginationProps, "page" | "onPageChange">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
        sx={paginationSx}
        showFirstButton
        showLastButton
      />
    );
  }

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={MuiPagination} {...props} />;
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "WALLET ADDRESS NAME",
      maxWidth: 300,
      flex: 1,
    },
    {
      field: "address",
      headerName: "WALLET ADDRESS",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          return (
            <>
              <IconButton aria-label="copy" onClick={() => handleClick(params.value)}>
                <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={{ width: 15, height: 15 }} />
              </IconButton>
              <Tooltip title={params.value}>
                <span>{params.value}</span>
              </Tooltip>
            </>
          );
        }
      },
    },
    {
      field: "assetTicker",
      headerName: "ASSET",
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <>
          <SvgIcon
            component={() =>
              AssetSVGIcon({
                svgString: getSVGStringForTicker(assets, params.value),
                title: params.value,
                sx: { width: 32, height: 32, pr: 1 },
              })
            }
            inheritViewBox
          />
          {params.value}
        </>
      ),
    },
    {
      field: "network",
      headerName: "NETWORK",
      maxWidth: 200,
      flex: 1,
    },
    {
      field: "type",
      headerName: "KEY MANAGER",
      maxWidth: 200,
      flex: 1,
      renderCell: (params) => {
        if (params.value === AllowlistType.Internal) {
          return "BAKKT";
        }
      },
    },
    {
      field: "status",
      headerName: "STATUS",
      maxWidth: 200,
      flex: 1,
    },
    {
      field: "id",
      headerName: "ID",
      maxWidth: 100,
      flex: 1,
    },
  ];

  const initialState = {};

  const data = { columns, initialState, rows: allowlistItems };

  return (
    <>
      <Paper>
        <Box sx={{ height: "100%", flex: 1, width: "100%" }}>
          <DataGridPremium
            slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: {
                  debounceMs: 500,
                },
              },
            }}
            {...data}
            initialState={{
              ...data.initialState,
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            getRowId={(row) => row.id}
            pageSizeOptions={[10, 20, 50]}
            pagination
            disableRowSelectionOnClick
          />
        </Box>
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={2000}
          message="Address Copied"
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        />
      </Paper>
    </>
  );
};
