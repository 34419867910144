/* tslint:disable */
/* eslint-disable */
/**
 * Bakkt Trust Custody - Client
 * Provide Overview spec for accessing Custody APIs
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: custody@bakkt.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from "./base";

/**
 * Object model representing an account
 * @export
 * @interface Account
 */
export interface Account {
  /**
   * Account Id
   * @type {number}
   * @memberof Account
   */
  id: number;
  /**
   * Account Name
   * @type {string}
   * @memberof Account
   */
  name: string;
  /**
   * Account Description
   * @type {string}
   * @memberof Account
   */
  description?: string | null;
  /**
   *
   * @type {Address}
   * @memberof Account
   */
  address: Address;
  /**
   *
   * @type {Phone}
   * @memberof Account
   */
  phone: Phone;
  /**
   * Activated On
   * @type {number}
   * @memberof Account
   */
  activatedOn?: number;
  /**
   *
   * @type {Status}
   * @memberof Account
   */
  status: Status;
  /**
   * Account Attributes
   * @type {Array<Attribute>}
   * @memberof Account
   */
  attributes?: Array<Attribute> | null;
}

/**
 *
 * @export
 * @interface AccountHistory
 */
export interface AccountHistory {
  /**
   *
   * @type {number}
   * @memberof AccountHistory
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof AccountHistory
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof AccountHistory
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof AccountHistory
   */
  organizationId?: number;
  /**
   *
   * @type {number}
   * @memberof AccountHistory
   */
  userId?: number;
  /**
   *
   * @type {number}
   * @memberof AccountHistory
   */
  initiatorUserId: number;
  /**
   *
   * @type {string}
   * @memberof AccountHistory
   */
  initiatorUserName: string;
  /**
   *
   * @type {number}
   * @memberof AccountHistory
   */
  requesterUserId?: number;
  /**
   *
   * @type {string}
   * @memberof AccountHistory
   */
  requesterUserName?: string;
  /**
   *
   * @type {string}
   * @memberof AccountHistory
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof AccountHistory
   */
  status?: string;
  /**
   *
   * @type {boolean}
   * @memberof AccountHistory
   */
  isClientViewable: boolean;
  /**
   *
   * @type {string}
   * @memberof AccountHistory
   */
  correlationId?: string;
  /**
   *
   * @type {string}
   * @memberof AccountHistory
   */
  executionId?: string;
}
/**
 *
 * @export
 * @interface AccountHistoryRequest
 */
export interface AccountHistoryRequest {
  /**
   *
   * @type {number}
   * @memberof AccountHistoryRequest
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof AccountHistoryRequest
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof AccountHistoryRequest
   */
  organizationId?: number;
  /**
   *
   * @type {number}
   * @memberof AccountHistoryRequest
   */
  userId?: number;
  /**
   *
   * @type {number}
   * @memberof AccountHistoryRequest
   */
  initiatorUserId: number;
  /**
   *
   * @type {string}
   * @memberof AccountHistoryRequest
   */
  initiatorUserName: string;
  /**
   *
   * @type {number}
   * @memberof AccountHistoryRequest
   */
  requesterUserId?: number;
  /**
   *
   * @type {string}
   * @memberof AccountHistoryRequest
   */
  requesterUserName?: string;
  /**
   *
   * @type {string}
   * @memberof AccountHistoryRequest
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof AccountHistoryRequest
   */
  status?: string;
  /**
   *
   * @type {boolean}
   * @memberof AccountHistoryRequest
   */
  isClientViewable?: boolean;
  /**
   *
   * @type {string}
   * @memberof AccountHistoryRequest
   */
  correlationId?: string;
  /**
   *
   * @type {string}
   * @memberof AccountHistoryRequest
   */
  executionId?: string;
}
/**
 * Object model representing an account permission
 * @export
 * @interface AccountLevelPermission
 */
export interface AccountLevelPermission {
  /**
   *
   * @type {Status}
   * @memberof AccountLevelPermission
   */
  status?: Status;
  /**
   * Permissions
   * @type {Array<AccountPermission>}
   * @memberof AccountLevelPermission
   */
  activePermissions?: Array<AccountPermission> | null;
}

/**
 * Account Permission
 * @export
 * @enum {string}
 */

export const AccountPermission = {
  TeamManager: "TEAM_MANAGER",
} as const;

export type AccountPermission = (typeof AccountPermission)[keyof typeof AccountPermission];

/**
 * Object model for an account request
 * @export
 * @interface AccountRequest
 */
export interface AccountRequest {
  /**
   * Account Name
   * @type {string}
   * @memberof AccountRequest
   */
  name: string;
  /**
   *
   * @type {Phone}
   * @memberof AccountRequest
   */
  phone: Phone;
  /**
   *
   * @type {Address}
   * @memberof AccountRequest
   */
  address: Address;
  /**
   * Account Description
   * @type {string}
   * @memberof AccountRequest
   */
  description?: string | null;
  /**
   * Account Attributes
   * @type {Array<Attribute>}
   * @memberof AccountRequest
   */
  attributes?: Array<Attribute> | null;
}
/**
 * Action
 * @export
 * @enum {string}
 */

export const Action = {
  Lock: "LOCK",
  Activate: "ACTIVATE",
} as const;

export type Action = (typeof Action)[keyof typeof Action];

/**
 * Object model representing address
 * @export
 * @interface Address
 */
export interface Address {
  /**
   * Street Name
   * @type {string}
   * @memberof Address
   */
  street1: string;
  /**
   * Street Name
   * @type {string}
   * @memberof Address
   */
  street2?: string | null;
  /**
   * City
   * @type {string}
   * @memberof Address
   */
  city: string;
  /**
   * State
   * @type {string}
   * @memberof Address
   */
  state: string;
  /**
   * Zip code
   * @type {string}
   * @memberof Address
   */
  zipCode: string;
  /**
   * Country
   * @type {string}
   * @memberof Address
   */
  country: string;
}
/**
 *
 * @export
 * @interface AllowlistAddress
 */
export interface AllowlistAddress {
  /**
   *
   * @type {number}
   * @memberof AllowlistAddress
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AllowlistAddress
   */
  correlationId: string;
  /**
   *
   * @type {string}
   * @memberof AllowlistAddress
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof AllowlistAddress
   */
  tradingAddress?: string;
  /**
   *
   * @type {string}
   * @memberof AllowlistAddress
   */
  name: string;
  /**
   *
   * @type {AllowlistType}
   * @memberof AllowlistAddress
   */
  type: AllowlistType;
  /**
   *
   * @type {number}
   * @memberof AllowlistAddress
   */
  organizationId: number;
  /**
   *
   * @type {string}
   * @memberof AllowlistAddress
   */
  assetSymbol: string;
  /**
   *
   * @type {string}
   * @memberof AllowlistAddress
   */
  assetTicker: string;
  /**
   *
   * @type {string}
   * @memberof AllowlistAddress
   */
  network: string;
  /**
   *
   * @type {boolean}
   * @memberof AllowlistAddress
   */
  isDeleted?: boolean;
  /**
   *
   * @type {AllowlistAddressStatusEnum}
   * @memberof AllowlistAddress
   */
  status: AllowlistAddressStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AllowlistAddress
   */
  financialInstitutionName?: string | null;
  /**
   *
   * @type {string}
   * @memberof AllowlistAddress
   */
  financialInstitutionId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AllowlistAddress
   */
  isSelfHosted: boolean;
}

/**
 *
 * @export
 * @interface AllowlistAddressRequest
 */
export interface AllowlistAddressRequest {
  /**
   *
   * @type {string}
   * @memberof AllowlistAddressRequest
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof AllowlistAddressRequest
   */
  tradingAddress?: string;
  /**
   *
   * @type {AllowlistType}
   * @memberof AllowlistAddressRequest
   */
  type: AllowlistType;
  /**
   *
   * @type {number}
   * @memberof AllowlistAddressRequest
   */
  organizationId: number;
  /**
   *
   * @type {string}
   * @memberof AllowlistAddressRequest
   */
  assetSymbol: string;
  /**
   *
   * @type {string}
   * @memberof AllowlistAddressRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AllowlistAddressRequest
   */
  financialInstitutionName?: string | null;
  /**
   *
   * @type {string}
   * @memberof AllowlistAddressRequest
   */
  financialInstitutionId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AllowlistAddressRequest
   */
  isSelfHosted: boolean;
}

/**
 * Status
 * @export
 * @enum {string}
 */

export const AllowlistAddressStatusEnum = {
  NotFound: "NOT_FOUND",
  Pending: "PENDING",
  Denied: "DENIED",
  Approved: "APPROVED",
  Expired: "EXPIRED",
} as const;

export type AllowlistAddressStatusEnum = (typeof AllowlistAddressStatusEnum)[keyof typeof AllowlistAddressStatusEnum];

/**
 *
 * @export
 * @interface AllowlistHistory
 */
export interface AllowlistHistory {
  /**
   *
   * @type {number}
   * @memberof AllowlistHistory
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof AllowlistHistory
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof AllowlistHistory
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof AllowlistHistory
   */
  organizationId: number;
  /**
   *
   * @type {string}
   * @memberof AllowlistHistory
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof AllowlistHistory
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof AllowlistHistory
   */
  initiatorUserId: number;
  /**
   *
   * @type {string}
   * @memberof AllowlistHistory
   */
  initiatorUserName: string;
  /**
   *
   * @type {number}
   * @memberof AllowlistHistory
   */
  requesterUserId?: number;
  /**
   *
   * @type {string}
   * @memberof AllowlistHistory
   */
  requesterUserName?: string;
  /**
   *
   * @type {boolean}
   * @memberof AllowlistHistory
   */
  isClientViewable: boolean;
  /**
   *
   * @type {string}
   * @memberof AllowlistHistory
   */
  correlationId?: string;
  /**
   *
   * @type {string}
   * @memberof AllowlistHistory
   */
  executionId?: string;
}
/**
 *
 * @export
 * @interface AllowlistHistoryRequest
 */
export interface AllowlistHistoryRequest {
  /**
   *
   * @type {number}
   * @memberof AllowlistHistoryRequest
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof AllowlistHistoryRequest
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof AllowlistHistoryRequest
   */
  organizationId: number;
  /**
   *
   * @type {string}
   * @memberof AllowlistHistoryRequest
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof AllowlistHistoryRequest
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof AllowlistHistoryRequest
   */
  initiatorUserId: number;
  /**
   *
   * @type {string}
   * @memberof AllowlistHistoryRequest
   */
  initiatorUserName: string;
  /**
   *
   * @type {number}
   * @memberof AllowlistHistoryRequest
   */
  requesterUserId?: number;
  /**
   *
   * @type {string}
   * @memberof AllowlistHistoryRequest
   */
  requesterUserName?: string;
  /**
   *
   * @type {boolean}
   * @memberof AllowlistHistoryRequest
   */
  isClientViewable?: boolean;
  /**
   *
   * @type {string}
   * @memberof AllowlistHistoryRequest
   */
  correlationId?: string;
  /**
   *
   * @type {string}
   * @memberof AllowlistHistoryRequest
   */
  executionId?: string;
}
/**
 * Status
 * @export
 * @enum {string}
 */

export const AllowlistType = {
  Internal: "INTERNAL",
  External: "EXTERNAL",
} as const;

export type AllowlistType = (typeof AllowlistType)[keyof typeof AllowlistType];

/**
 *
 * @export
 * @interface Asset
 */
export interface Asset {
  /**
   * Asset Id
   * @type {number}
   * @memberof Asset
   */
  id: number;
  /**
   * Asset Name
   * @type {string}
   * @memberof Asset
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  ticker: string;
  /**
   * Symbol
   * @type {string}
   * @memberof Asset
   */
  symbol: string;
  /**
   * Description
   * @type {string}
   * @memberof Asset
   */
  description: string;
  /**
   * Network
   * @type {string}
   * @memberof Asset
   */
  network: string;
  /**
   * Asset used for gas fee
   * @type {string}
   * @memberof Asset
   */
  feeAsset?: string;
  /**
   * Contract Address
   * @type {string}
   * @memberof Asset
   */
  contractAddress?: string | null;
  /**
   *
   * @type {AssetType}
   * @memberof Asset
   */
  type: AssetType;
  /**
   * Decimal Precision
   * @type {number}
   * @memberof Asset
   */
  decimalPrecision: number;
  /**
   * Icon SVG
   * @type {string}
   * @memberof Asset
   */
  iconSVG: string;
  /**
   * Primary Color Hex
   * @type {string}
   * @memberof Asset
   */
  primaryColorHex: string;
  /**
   * Secondary Color Hex
   * @type {string}
   * @memberof Asset
   */
  secondaryColorHex: string;
  /**
   *
   * @type {Status}
   * @memberof Asset
   */
  status: Status;
  /**
   * External OffExchange Account Enabled
   * @type {boolean}
   * @memberof Asset
   */
  offExchangeEnabled: boolean;
}

/**
 * AssetType
 * @export
 * @enum {string}
 */

export const AssetType = {
  Base: "BASE",
  Token: "TOKEN",
} as const;

export type AssetType = (typeof AssetType)[keyof typeof AssetType];

/**
 * Object model representing an attribute
 * @export
 * @interface Attribute
 */
export interface Attribute {
  /**
   * Name
   * @type {string}
   * @memberof Attribute
   */
  name: string;
  /**
   * Value
   * @type {string}
   * @memberof Attribute
   */
  value: string;
}
/**
 *
 * @export
 * @interface BalanceInfo
 */
export interface BalanceInfo {
  /**
   *
   * @type {string}
   * @memberof BalanceInfo
   */
  confirmed_balance?: string;
  /**
   *
   * @type {string}
   * @memberof BalanceInfo
   */
  pending_balance?: string;
  /**
   *
   * @type {string}
   * @memberof BalanceInfo
   */
  confirmed_block?: string;
  /**
   *
   * @type {BalanceInfoCurrency}
   * @memberof BalanceInfo
   */
  currency?: BalanceInfoCurrency;
}
/**
 *
 * @export
 * @interface BalanceInfoCurrency
 */
export interface BalanceInfoCurrency {
  /**
   *
   * @type {string}
   * @memberof BalanceInfoCurrency
   */
  asset_path?: string;
  /**
   *
   * @type {string}
   * @memberof BalanceInfoCurrency
   */
  symbol?: string;
  /**
   *
   * @type {string}
   * @memberof BalanceInfoCurrency
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BalanceInfoCurrency
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof BalanceInfoCurrency
   */
  decimals?: number;
}
/**
 *
 * @export
 * @interface BalanceInfoWithAddress
 */
export interface BalanceInfoWithAddress {
  /**
   * address
   * @type {string}
   * @memberof BalanceInfoWithAddress
   */
  address?: string;
  /**
   * balances
   * @type {Array<BalanceInfo>}
   * @memberof BalanceInfoWithAddress
   */
  balances?: Array<BalanceInfo> | null;
}
/**
 * BalanceRequest
 * @export
 * @interface BalanceRequest
 */
export interface BalanceRequest {
  /**
   *
   * @type {string}
   * @memberof BalanceRequest
   */
  assetSymbol?: string;
  /**
   *
   * @type {string}
   * @memberof BalanceRequest
   */
  network?: string;
  /**
   * Addresses
   * @type {Array<string>}
   * @memberof BalanceRequest
   */
  addresses: Array<string> | null;
}
/**
 *
 * @export
 * @interface BuildTransactionUpdatesRequest
 */
export interface BuildTransactionUpdatesRequest {
  /**
   *
   * @type {string}
   * @memberof BuildTransactionUpdatesRequest
   */
  assetSymbol?: string;
  /**
   *
   * @type {string}
   * @memberof BuildTransactionUpdatesRequest
   */
  network?: string;
  /**
   *
   * @type {string}
   * @memberof BuildTransactionUpdatesRequest
   */
  txHash: string;
}
/**
 *
 * @export
 * @interface CreateAllowlistRequest
 */
export interface CreateAllowlistRequest {
  /**
   * Account Id
   * @type {number}
   * @memberof CreateAllowlistRequest
   */
  accountId: number;
  /**
   * Organization Id
   * @type {number}
   * @memberof CreateAllowlistRequest
   */
  organizationId: number;
  /**
   * Status
   * @type {string}
   * @memberof CreateAllowlistRequest
   */
  type: CreateAllowlistRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreateAllowlistRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateAllowlistRequest
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof CreateAllowlistRequest
   */
  assetSymbol: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateAllowlistRequest
   */
  isSelfHosted: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateAllowlistRequest
   */
  financialInstitutionName?: string | null;
}

export const CreateAllowlistRequestTypeEnum = {
  Internal: "INTERNAL",
  External: "EXTERNAL",
} as const;

export type CreateAllowlistRequestTypeEnum =
  (typeof CreateAllowlistRequestTypeEnum)[keyof typeof CreateAllowlistRequestTypeEnum];

/**
 *
 * @export
 * @interface CreateTransactionRequest
 */
export interface CreateTransactionRequest {
  /**
   *
   * @type {number}
   * @memberof CreateTransactionRequest
   */
  sourceWalletId: number;
  /**
   *
   * @type {number}
   * @memberof CreateTransactionRequest
   */
  allowlistId?: number;
  /**
   *
   * @type {number}
   * @memberof CreateTransactionRequest
   */
  quantity: number;
  /**
   * Transaction Type
   * @type {string}
   * @memberof CreateTransactionRequest
   */
  type: CreateTransactionRequestTypeEnum;
  /**
   *
   * @type {number}
   * @memberof CreateTransactionRequest
   */
  destinationWalletId?: number;
}

export const CreateTransactionRequestTypeEnum = {
  Withdraw: "WITHDRAW",
  Deposit: "DEPOSIT",
  Fee: "FEE",
  Feerefund: "FEEREFUND",
  Transfer: "TRANSFER",
  AddCollateralWithdraw: "ADD_COLLATERAL_WITHDRAW",
} as const;

export type CreateTransactionRequestTypeEnum =
  (typeof CreateTransactionRequestTypeEnum)[keyof typeof CreateTransactionRequestTypeEnum];

/**
 *
 * @export
 * @interface CreateWalletRequest
 */
export interface CreateWalletRequest {
  /**
   *
   * @type {number}
   * @memberof CreateWalletRequest
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof CreateWalletRequest
   */
  organizationId: number;
  /**
   *
   * @type {string}
   * @memberof CreateWalletRequest
   */
  assetSymbol: string;
  /**
   *
   * @type {string}
   * @memberof CreateWalletRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateWalletRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateWalletRequest
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof CreateWalletRequest
   */
  feeWalletId?: number;
}
/**
 *
 * @export
 * @interface CreateWalletTransactionRequest
 */
export interface CreateWalletTransactionRequest {
  /**
   *
   * @type {string}
   * @memberof CreateWalletTransactionRequest
   */
  sourceType: string;
  /**
   *
   * @type {number}
   * @memberof CreateWalletTransactionRequest
   */
  sourceId?: number;
  /**
   *
   * @type {string}
   * @memberof CreateWalletTransactionRequest
   */
  sourceName: string;
  /**
   *
   * @type {string}
   * @memberof CreateWalletTransactionRequest
   */
  sourceAddress: string;
  /**
   *
   * @type {string}
   * @memberof CreateWalletTransactionRequest
   */
  destinationType: string;
  /**
   *
   * @type {number}
   * @memberof CreateWalletTransactionRequest
   */
  destinationId: number;
  /**
   *
   * @type {string}
   * @memberof CreateWalletTransactionRequest
   */
  destinationName: string;
  /**
   *
   * @type {string}
   * @memberof CreateWalletTransactionRequest
   */
  destinationAddress: string;
  /**
   *
   * @type {number}
   * @memberof CreateWalletTransactionRequest
   */
  quantity: number;
  /**
   *
   * @type {number}
   * @memberof CreateWalletTransactionRequest
   */
  amountUSD?: number;
  /**
   *
   * @type {string}
   * @memberof CreateWalletTransactionRequest
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof CreateWalletTransactionRequest
   */
  initiatorUserId: number;
  /**
   *
   * @type {number}
   * @memberof CreateWalletTransactionRequest
   */
  quarantineTransactionId?: number;
  /**
   *
   * @type {number}
   * @memberof CreateWalletTransactionRequest
   */
  parentTransactionId?: number;
}
/**
 *
 * @export
 * @interface EstimateFee
 */
export interface EstimateFee {
  /**
   *
   * @type {string}
   * @memberof EstimateFee
   */
  networkFee?: string;
  /**
   *
   * @type {string}
   * @memberof EstimateFee
   */
  feePerByte?: string;
  /**
   *
   * @type {string}
   * @memberof EstimateFee
   */
  baseFee?: string;
}
/**
 * Object model representing an external exchange account
 * @export
 * @interface ExternalExchangeAccount
 */
export interface ExternalExchangeAccount {
  /**
   * Organization Id
   * @type {number}
   * @memberof ExternalExchangeAccount
   */
  organizationId: number;
  /**
   * External Exchange AccountId
   * @type {string}
   * @memberof ExternalExchangeAccount
   */
  externalExchangeAccountId: string;
  /**
   * External Exchange Account Name
   * @type {string}
   * @memberof ExternalExchangeAccount
   */
  externalExchangeAccountName: string;
  /**
   * Provider Id
   * @type {number}
   * @memberof ExternalExchangeAccount
   */
  providerId: number;
  /**
   * Activated On
   * @type {number}
   * @memberof ExternalExchangeAccount
   */
  activatedOn: number;
  /**
   *
   * @type {Status}
   * @memberof ExternalExchangeAccount
   */
  status: Status;
}

/**
 * Object model representing an external exchange account
 * @export
 * @interface ExternalExchangeAccountRequest
 */
export interface ExternalExchangeAccountRequest {
  /**
   * Account Id
   * @type {number}
   * @memberof ExternalExchangeAccountRequest
   */
  accountId: number;
  /**
   * Organization Id
   * @type {number}
   * @memberof ExternalExchangeAccountRequest
   */
  organizationId: number;
  /**
   * External Exchange AccountId
   * @type {string}
   * @memberof ExternalExchangeAccountRequest
   */
  externalExchangeAccountId: string;
  /**
   * External Exchange Account Name
   * @type {string}
   * @memberof ExternalExchangeAccountRequest
   */
  externalExchangeAccountName: string;
  /**
   * Provider Id
   * @type {number}
   * @memberof ExternalExchangeAccountRequest
   */
  providerId: number;
  /**
   * Activated On
   * @type {number}
   * @memberof ExternalExchangeAccountRequest
   */
  activatedOn: number;
  /**
   *
   * @type {Status}
   * @memberof ExternalExchangeAccountRequest
   */
  status: Status;
}

/**
 *
 * @export
 * @interface FinanceReport
 */
export interface FinanceReport {
  /**
   *
   * @type {string}
   * @memberof FinanceReport
   */
  day?: string;
  /**
   *
   * @type {number}
   * @memberof FinanceReport
   */
  accountId?: number;
  /**
   *
   * @type {string}
   * @memberof FinanceReport
   */
  accountName?: string;
  /**
   *
   * @type {number}
   * @memberof FinanceReport
   */
  organizationId?: number;
  /**
   *
   * @type {string}
   * @memberof FinanceReport
   */
  organizationName?: string;
  /**
   *
   * @type {number}
   * @memberof FinanceReport
   */
  btcCount?: number;
  /**
   *
   * @type {number}
   * @memberof FinanceReport
   */
  btcValue?: number;
  /**
   *
   * @type {number}
   * @memberof FinanceReport
   */
  ethCount?: number;
  /**
   *
   * @type {number}
   * @memberof FinanceReport
   */
  ethValue?: number;
}
/**
 *
 * @export
 * @interface FinanceReportData
 */
export interface FinanceReportData {
  /**
   *
   * @type {number}
   * @memberof FinanceReportData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FinanceReportData
   */
  day?: string;
  /**
   *
   * @type {number}
   * @memberof FinanceReportData
   */
  accountId?: number;
  /**
   *
   * @type {string}
   * @memberof FinanceReportData
   */
  accountName?: string;
  /**
   *
   * @type {number}
   * @memberof FinanceReportData
   */
  organizationId?: number;
  /**
   *
   * @type {string}
   * @memberof FinanceReportData
   */
  organizationName?: string;
  /**
   *
   * @type {string}
   * @memberof FinanceReportData
   */
  assetSymbol?: string;
  /**
   *
   * @type {number}
   * @memberof FinanceReportData
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof FinanceReportData
   */
  valueUSD?: number;
}
/**
 *
 * @export
 * @interface FusionAuthEvent
 */
export interface FusionAuthEvent {
  /**
   *
   * @type {number}
   * @memberof FusionAuthEvent
   */
  createInstant?: number;
  /**
   *
   * @type {string}
   * @memberof FusionAuthEvent
   */
  id: string;
  /**
   *
   * @type {FusionAuthEventInfo}
   * @memberof FusionAuthEvent
   */
  info: FusionAuthEventInfo;
  /**
   *
   * @type {string}
   * @memberof FusionAuthEvent
   */
  tenantId: string;
  /**
   *
   * @type {string}
   * @memberof FusionAuthEvent
   */
  type: string;
  /**
   *
   * @type {FusionAuthUser}
   * @memberof FusionAuthEvent
   */
  original?: FusionAuthUser;
  /**
   *
   * @type {FusionAuthUser}
   * @memberof FusionAuthEvent
   */
  user: FusionAuthUser;
}
/**
 *
 * @export
 * @interface FusionAuthEventInfo
 */
export interface FusionAuthEventInfo {
  /**
   *
   * @type {string}
   * @memberof FusionAuthEventInfo
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof FusionAuthEventInfo
   */
  userAgent?: string;
}
/**
 *
 * @export
 * @interface FusionAuthUser
 */
export interface FusionAuthUser {
  /**
   *
   * @type {boolean}
   * @memberof FusionAuthUser
   */
  active?: boolean;
  /**
   *
   * @type {number}
   * @memberof FusionAuthUser
   */
  breachedPasswordLastCheckedInstant?: number;
  /**
   *
   * @type {string}
   * @memberof FusionAuthUser
   */
  breachedPasswordStatus?: string;
  /**
   *
   * @type {string}
   * @memberof FusionAuthUser
   */
  connectorId?: string;
  /**
   *
   * @type {string}
   * @memberof FusionAuthUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof FusionAuthUser
   */
  mobilePhone?: string;
  /**
   *
   * @type {string}
   * @memberof FusionAuthUser
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof FusionAuthUser
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof FusionAuthUser
   */
  insertInstant?: number;
  /**
   *
   * @type {number}
   * @memberof FusionAuthUser
   */
  lastLoginInstant?: number;
  /**
   *
   * @type {number}
   * @memberof FusionAuthUser
   */
  lastUpdateInstant?: number;
  /**
   *
   * @type {string}
   * @memberof FusionAuthUser
   */
  middleName?: string;
  /**
   *
   * @type {string}
   * @memberof FusionAuthUser
   */
  lastName?: string;
  /**
   *
   * @type {boolean}
   * @memberof FusionAuthUser
   */
  passwordChangeRequired?: boolean;
  /**
   *
   * @type {number}
   * @memberof FusionAuthUser
   */
  passwordLastUpdateInstant?: number;
  /**
   *
   * @type {Array<FusionAuthUserRegistration>}
   * @memberof FusionAuthUser
   */
  registrations: Array<FusionAuthUserRegistration>;
  /**
   *
   * @type {string}
   * @memberof FusionAuthUser
   */
  tenantId?: string;
  /**
   *
   * @type {string}
   * @memberof FusionAuthUser
   */
  uniqueUsername?: string;
  /**
   *
   * @type {string}
   * @memberof FusionAuthUser
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof FusionAuthUser
   */
  usernameStatus?: string;
  /**
   *
   * @type {boolean}
   * @memberof FusionAuthUser
   */
  verified?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof FusionAuthUser
   */
  data?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface FusionAuthUserRegistration
 */
export interface FusionAuthUserRegistration {
  /**
   *
   * @type {string}
   * @memberof FusionAuthUserRegistration
   */
  applicationId?: string;
  /**
   *
   * @type {string}
   * @memberof FusionAuthUserRegistration
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof FusionAuthUserRegistration
   */
  insertInstant?: number;
  /**
   *
   * @type {number}
   * @memberof FusionAuthUserRegistration
   */
  lastLoginInstant?: number;
  /**
   *
   * @type {number}
   * @memberof FusionAuthUserRegistration
   */
  lastUpdateInstant?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof FusionAuthUserRegistration
   */
  roles: Array<string>;
  /**
   *
   * @type {string}
   * @memberof FusionAuthUserRegistration
   */
  usernameStatus?: string;
  /**
   *
   * @type {boolean}
   * @memberof FusionAuthUserRegistration
   */
  verified?: boolean;
}
/**
 *
 * @export
 * @interface FusionAuthWebhook
 */
export interface FusionAuthWebhook {
  /**
   *
   * @type {FusionAuthEvent}
   * @memberof FusionAuthWebhook
   */
  event: FusionAuthEvent;
}
/**
 *
 * @export
 * @interface HistorySummary
 */
export interface HistorySummary {
  /**
   *
   * @type {string}
   * @memberof HistorySummary
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof HistorySummary
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof HistorySummary
   */
  organizationId?: number;
  /**
   *
   * @type {string}
   * @memberof HistorySummary
   */
  itemId?: string;
  /**
   *
   * @type {string}
   * @memberof HistorySummary
   */
  actionId?: string;
  /**
   *
   * @type {string}
   * @memberof HistorySummary
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof HistorySummary
   */
  action: string;
  /**
   *
   * @type {number}
   * @memberof HistorySummary
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof HistorySummary
   */
  userName: string;
  /**
   *
   * @type {number}
   * @memberof HistorySummary
   */
  createdAt: number;
}
/**
 *
 * @export
 * @interface IVMSPerson
 */
export interface IVMSPerson {
  /**
   *
   * @type {IVMSPersonLegalPerson}
   * @memberof IVMSPerson
   */
  legalPerson?: IVMSPersonLegalPerson;
}
/**
 *
 * @export
 * @interface IVMSPersonLegalPerson
 */
export interface IVMSPersonLegalPerson {
  /**
   *
   * @type {IVMSPersonLegalPersonName}
   * @memberof IVMSPersonLegalPerson
   */
  name: IVMSPersonLegalPersonName;
  /**
   *
   * @type {Array<IVMSPersonLegalPersonGeographicAddressInner>}
   * @memberof IVMSPersonLegalPerson
   */
  geographicAddress?: Array<IVMSPersonLegalPersonGeographicAddressInner>;
  /**
   * A character string
   * @type {string}
   * @memberof IVMSPersonLegalPerson
   */
  customerNumber?: string;
}
/**
 *
 * @export
 * @interface IVMSPersonLegalPersonGeographicAddressInner
 */
export interface IVMSPersonLegalPersonGeographicAddressInner {
  /**
   *
   * @type {string}
   * @memberof IVMSPersonLegalPersonGeographicAddressInner
   */
  addressType: IVMSPersonLegalPersonGeographicAddressInnerAddressTypeEnum;
  /**
   * Information that locates and identifies a specific address, as defined by postal services, presented in free format text.
   * @type {Array<string>}
   * @memberof IVMSPersonLegalPersonGeographicAddressInner
   */
  addressLine: Array<string>;
  /**
   * A character string
   * @type {string}
   * @memberof IVMSPersonLegalPersonGeographicAddressInner
   */
  postCode: string;
  /**
   *
   * @type {string}
   * @memberof IVMSPersonLegalPersonGeographicAddressInner
   */
  country: string;
}

export const IVMSPersonLegalPersonGeographicAddressInnerAddressTypeEnum = {
  Home: "HOME",
  Bizz: "BIZZ",
  Geog: "GEOG",
} as const;

export type IVMSPersonLegalPersonGeographicAddressInnerAddressTypeEnum =
  (typeof IVMSPersonLegalPersonGeographicAddressInnerAddressTypeEnum)[keyof typeof IVMSPersonLegalPersonGeographicAddressInnerAddressTypeEnum];

/**
 *
 * @export
 * @interface IVMSPersonLegalPersonName
 */
export interface IVMSPersonLegalPersonName {
  /**
   * The name and type of name by which the legal person is known.
   * @type {Array<IVMSPersonLegalPersonNameNameIdentifierInner>}
   * @memberof IVMSPersonLegalPersonName
   */
  nameIdentifier: Array<IVMSPersonLegalPersonNameNameIdentifierInner>;
  /**
   * The name and type of name by which the legal person is known using local characters.
   * @type {Array<IVMSPersonLegalPersonNameNameIdentifierInner>}
   * @memberof IVMSPersonLegalPersonName
   */
  localNameIdentifier?: Array<IVMSPersonLegalPersonNameNameIdentifierInner>;
}
/**
 *
 * @export
 * @interface IVMSPersonLegalPersonNameNameIdentifierInner
 */
export interface IVMSPersonLegalPersonNameNameIdentifierInner {
  /**
   * A character string
   * @type {string}
   * @memberof IVMSPersonLegalPersonNameNameIdentifierInner
   */
  legalPersonName?: string;
  /**
   *
   * @type {string}
   * @memberof IVMSPersonLegalPersonNameNameIdentifierInner
   */
  legalPersonNameIdentifierType?: IVMSPersonLegalPersonNameNameIdentifierInnerLegalPersonNameIdentifierTypeEnum;
}

export const IVMSPersonLegalPersonNameNameIdentifierInnerLegalPersonNameIdentifierTypeEnum = {
  Legl: "LEGL",
  Shrt: "SHRT",
  Trad: "TRAD",
} as const;

export type IVMSPersonLegalPersonNameNameIdentifierInnerLegalPersonNameIdentifierTypeEnum =
  (typeof IVMSPersonLegalPersonNameNameIdentifierInnerLegalPersonNameIdentifierTypeEnum)[keyof typeof IVMSPersonLegalPersonNameNameIdentifierInnerLegalPersonNameIdentifierTypeEnum];

/**
 *
 * @export
 * @interface IvmsPerson
 */
export interface IvmsPerson {
  /**
   *
   * @type {IVMSPersonLegalPerson}
   * @memberof IvmsPerson
   */
  legalPerson?: IVMSPersonLegalPerson;
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {number}
   * @memberof ModelError
   */
  code: number;
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  domain: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ModelError
   */
  metadata?: { [key: string]: string };
}
/**
 * Object model representing name
 * @export
 * @interface Name
 */
export interface Name {
  /**
   * First Name
   * @type {string}
   * @memberof Name
   */
  firstName: string;
  /**
   * Middle Name
   * @type {string}
   * @memberof Name
   */
  middleName?: string | null;
  /**
   * Last Name
   * @type {string}
   * @memberof Name
   */
  lastName: string;
}
/**
 *
 * @export
 * @interface Network
 */
export interface Network {
  /**
   * Id
   * @type {number}
   * @memberof Network
   */
  id: number;
  /**
   * Name
   * @type {string}
   * @memberof Network
   */
  name: string;
  /**
   * Address Scanner URL
   * @type {string}
   * @memberof Network
   */
  addressScannerUrl: string;
  /**
   * Transaction Scanner URL
   * @type {string}
   * @memberof Network
   */
  transactionScannerUrl: string;
  /**
   * Blockchain confirmation
   * @type {number}
   * @memberof Network
   */
  blockConfirmationCount: number;
  /**
   * Does support tokenizationCARBON5
   * @type {boolean}
   * @memberof Network
   */
  hasTokenSupport: boolean;
  /**
   *
   * @type {Status}
   * @memberof Network
   */
  status: Status;
}

/**
 *
 * @export
 * @interface NotificationHistory
 */
export interface NotificationHistory {
  /**
   *
   * @type {number}
   * @memberof NotificationHistory
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof NotificationHistory
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof NotificationHistory
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof NotificationHistory
   */
  organizationId?: number;
  /**
   *
   * @type {string}
   * @memberof NotificationHistory
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof NotificationHistory
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof NotificationHistory
   */
  initiatorUserId: number;
  /**
   *
   * @type {string}
   * @memberof NotificationHistory
   */
  initiatorUserName: string;
  /**
   *
   * @type {number}
   * @memberof NotificationHistory
   */
  requesterUserId?: number;
  /**
   *
   * @type {string}
   * @memberof NotificationHistory
   */
  requesterUserName?: string;
  /**
   *
   * @type {boolean}
   * @memberof NotificationHistory
   */
  isClientViewable: boolean;
  /**
   *
   * @type {string}
   * @memberof NotificationHistory
   */
  correlationId?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationHistory
   */
  executionId?: string;
}
/**
 *
 * @export
 * @interface NotificationHistoryRequest
 */
export interface NotificationHistoryRequest {
  /**
   *
   * @type {number}
   * @memberof NotificationHistoryRequest
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof NotificationHistoryRequest
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof NotificationHistoryRequest
   */
  organizationId?: number;
  /**
   *
   * @type {string}
   * @memberof NotificationHistoryRequest
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof NotificationHistoryRequest
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof NotificationHistoryRequest
   */
  initiatorUserId: number;
  /**
   *
   * @type {string}
   * @memberof NotificationHistoryRequest
   */
  initiatorUserName: string;
  /**
   *
   * @type {number}
   * @memberof NotificationHistoryRequest
   */
  requesterUserId?: number;
  /**
   *
   * @type {string}
   * @memberof NotificationHistoryRequest
   */
  requesterUserName?: string;
  /**
   *
   * @type {boolean}
   * @memberof NotificationHistoryRequest
   */
  isClientViewable?: boolean;
  /**
   *
   * @type {string}
   * @memberof NotificationHistoryRequest
   */
  correlationId?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationHistoryRequest
   */
  executionId?: string;
}
/**
 *
 * @export
 * @interface OmniWalletOrgConfig
 */
export interface OmniWalletOrgConfig {
  /**
   *
   * @type {number}
   * @memberof OmniWalletOrgConfig
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof OmniWalletOrgConfig
   */
  accountId?: number;
  /**
   *
   * @type {number}
   * @memberof OmniWalletOrgConfig
   */
  organizationId?: number;
  /**
   *
   * @type {number}
   * @memberof OmniWalletOrgConfig
   */
  temperatureId?: number;
  /**
   *
   * @type {number}
   * @memberof OmniWalletOrgConfig
   */
  walletTypeId?: number;
}
/**
 * Object model representing an organization
 * @export
 * @interface Organization
 */
export interface Organization {
  /**
   * Organization Id
   * @type {number}
   * @memberof Organization
   */
  id: number;
  /**
   * Organization Name
   * @type {string}
   * @memberof Organization
   */
  name: string;
  /**
   * Account Id
   * @type {number}
   * @memberof Organization
   */
  accountId: number;
  /**
   * Organization Description
   * @type {string}
   * @memberof Organization
   */
  description?: string | null;
  /**
   *
   * @type {WithdrawalAuthorization}
   * @memberof Organization
   */
  withdrawalAuthorization: WithdrawalAuthorization;
  /**
   * Number Of Consensus Required
   * @type {number}
   * @memberof Organization
   */
  numberOfConsensus: number;
  /**
   * Number Of Maximum Wallets Per Asset
   * @type {number}
   * @memberof Organization
   */
  maxWalletsPerAsset: number;
  /**
   *
   * @type {Status}
   * @memberof Organization
   */
  status: Status;
  /**
   * Account Attributes
   * @type {Array<Attribute>}
   * @memberof Organization
   */
  attributes?: Array<Attribute> | null;
  /**
   * Activated On
   * @type {number}
   * @memberof Organization
   */
  activatedOn?: number;
  /**
   * Is this organization connected to an exchange account
   * @type {boolean}
   * @memberof Organization
   */
  isExchangeAccountEnabled?: boolean;
}

/**
 * Object model representing an organization permission
 * @export
 * @interface OrganizationLevelPermission
 */
export interface OrganizationLevelPermission {
  /**
   * Organization Id
   * @type {number}
   * @memberof OrganizationLevelPermission
   */
  organizationId: number;
  /**
   *
   * @type {Status}
   * @memberof OrganizationLevelPermission
   */
  status?: Status;
  /**
   * Permissions
   * @type {Array<OrganizationPermission>}
   * @memberof OrganizationLevelPermission
   */
  activePermissions?: Array<OrganizationPermission>;
}

/**
 * Organization Permission
 * @export
 * @enum {string}
 */

export const OrganizationPermission = {
  View: "VIEW",
  Initiator: "INITIATOR",
  Approver: "APPROVER",
  VideoAuthorization: "VIDEO_AUTHORIZATION",
} as const;

export type OrganizationPermission = (typeof OrganizationPermission)[keyof typeof OrganizationPermission];

/**
 * Object model for creating an organization request
 * @export
 * @interface OrganizationRequest
 */
export interface OrganizationRequest {
  /**
   * Organization Name
   * @type {string}
   * @memberof OrganizationRequest
   */
  name: string;
  /**
   * Organization Description
   * @type {string}
   * @memberof OrganizationRequest
   */
  description?: string | null;
  /**
   * Account Id
   * @type {number}
   * @memberof OrganizationRequest
   */
  accountId: number;
  /**
   *
   * @type {WithdrawalAuthorization}
   * @memberof OrganizationRequest
   */
  withdrawalAuthorization: WithdrawalAuthorization;
  /**
   * Number Of Consensus Required
   * @type {number}
   * @memberof OrganizationRequest
   */
  numberOfConsensus: number;
  /**
   * Number Of Maximum Wallets Per Asset
   * @type {number}
   * @memberof OrganizationRequest
   */
  maxWalletsPerAsset?: number;
  /**
   * Account Attributes
   * @type {Array<Attribute>}
   * @memberof OrganizationRequest
   */
  attributes?: Array<Attribute> | null;
}
/**
 * Object model representing phone
 * @export
 * @interface Phone
 */
export interface Phone {
  /**
   * CountryCode
   * @type {string}
   * @memberof Phone
   */
  countryCode: string;
  /**
   * Number
   * @type {string}
   * @memberof Phone
   */
  number: string;
}
/**
 * Object model representing an user
 * @export
 * @interface PolicyAction
 */
export interface PolicyAction {
  /**
   * Account Id
   * @type {number}
   * @memberof PolicyAction
   */
  accountId: number;
  /**
   * Organization Id
   * @type {number}
   * @memberof PolicyAction
   */
  organizationId: number;
  /**
   *
   * @type {string}
   * @memberof PolicyAction
   */
  action: PolicyActionActionEnum;
  /**
   *
   * @type {number}
   * @memberof PolicyAction
   */
  policyActionId: number;
}

export const PolicyActionActionEnum = {
  Approve: "APPROVE",
  Deny: "DENY",
} as const;

export type PolicyActionActionEnum = (typeof PolicyActionActionEnum)[keyof typeof PolicyActionActionEnum];

/**
 *
 * @export
 * @interface PolicyActionDetails
 */
export interface PolicyActionDetails {
  /**
   *
   * @type {number}
   * @memberof PolicyActionDetails
   */
  policyActionId?: number;
  /**
   *
   * @type {number}
   * @memberof PolicyActionDetails
   */
  policySequenceId?: number;
  /**
   *
   * @type {number}
   * @memberof PolicyActionDetails
   */
  timestamp?: number;
  /**
   *
   * @type {string}
   * @memberof PolicyActionDetails
   */
  policyActionPayload?: string;
  /**
   *
   * @type {PolicyStatusEnum}
   * @memberof PolicyActionDetails
   */
  policyActionStatus?: PolicyStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PolicyActionDetails
   */
  policyInstanceRequestCategory?: string;
  /**
   *
   * @type {string}
   * @memberof PolicyActionDetails
   */
  policyInstanceRequestType?: string;
  /**
   *
   * @type {string}
   * @memberof PolicyActionDetails
   */
  policyInstancePayload?: string;
  /**
   *
   * @type {number}
   * @memberof PolicyActionDetails
   */
  requesterId?: number;
  /**
   *
   * @type {string}
   * @memberof PolicyActionDetails
   */
  requester?: string;
  /**
   *
   * @type {number}
   * @memberof PolicyActionDetails
   */
  clientId?: number;
  /**
   *
   * @type {string}
   * @memberof PolicyActionDetails
   */
  client?: string;
  /**
   *
   * @type {number}
   * @memberof PolicyActionDetails
   */
  organizationId?: number;
  /**
   *
   * @type {string}
   * @memberof PolicyActionDetails
   */
  organization?: string;
  /**
   *
   * @type {PolicyStatusEnum}
   * @memberof PolicyActionDetails
   */
  policySequenceStatus?: PolicyStatusEnum;
  /**
   *
   * @type {PolicyStatusEnum}
   * @memberof PolicyActionDetails
   */
  policyInstanceStatus?: PolicyStatusEnum;
  /**
   *
   * @type {number}
   * @memberof PolicyActionDetails
   */
  approvalCount?: number;
  /**
   *
   * @type {number}
   * @memberof PolicyActionDetails
   */
  consensusNumber?: number;

  transactionIds?: number[];
}

/**
 *
 * @export
 * @interface PolicyHistory
 */
export interface PolicyHistory {
  /**
   *
   * @type {number}
   * @memberof PolicyHistory
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof PolicyHistory
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof PolicyHistory
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof PolicyHistory
   */
  organizationId?: number;
  /**
   *
   * @type {string}
   * @memberof PolicyHistory
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof PolicyHistory
   */
  payload?: string;
  /**
   *
   * @type {number}
   * @memberof PolicyHistory
   */
  providerId?: number;
  /**
   *
   * @type {string}
   * @memberof PolicyHistory
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof PolicyHistory
   */
  initiatorUserId: number;
  /**
   *
   * @type {string}
   * @memberof PolicyHistory
   */
  initiatorUserName: string;
  /**
   *
   * @type {number}
   * @memberof PolicyHistory
   */
  requesterUserId?: number;
  /**
   *
   * @type {string}
   * @memberof PolicyHistory
   */
  requesterUserName?: string;
  /**
   *
   * @type {boolean}
   * @memberof PolicyHistory
   */
  isClientViewable: boolean;
  /**
   *
   * @type {string}
   * @memberof PolicyHistory
   */
  correlationId?: string;
  /**
   *
   * @type {string}
   * @memberof PolicyHistory
   */
  executionId?: string;
}
/**
 *
 * @export
 * @interface PolicyHistoryRequest
 */
export interface PolicyHistoryRequest {
  /**
   *
   * @type {number}
   * @memberof PolicyHistoryRequest
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof PolicyHistoryRequest
   */
  organizationId?: number;
  /**
   *
   * @type {string}
   * @memberof PolicyHistoryRequest
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof PolicyHistoryRequest
   */
  timestamp: number;
  /**
   *
   * @type {string}
   * @memberof PolicyHistoryRequest
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof PolicyHistoryRequest
   */
  payload?: string;
  /**
   *
   * @type {number}
   * @memberof PolicyHistoryRequest
   */
  providerId?: number;
  /**
   *
   * @type {number}
   * @memberof PolicyHistoryRequest
   */
  initiatorUserId: number;
  /**
   *
   * @type {string}
   * @memberof PolicyHistoryRequest
   */
  initiatorUserName: string;
  /**
   *
   * @type {number}
   * @memberof PolicyHistoryRequest
   */
  requesterUserId?: number;
  /**
   *
   * @type {string}
   * @memberof PolicyHistoryRequest
   */
  requesterUserName?: string;
  /**
   *
   * @type {boolean}
   * @memberof PolicyHistoryRequest
   */
  isClientViewable?: boolean;
  /**
   *
   * @type {string}
   * @memberof PolicyHistoryRequest
   */
  correlationId?: string;
  /**
   *
   * @type {string}
   * @memberof PolicyHistoryRequest
   */
  executionId?: string;
}
/**
 *
 * @export
 * @interface PolicyInstance
 */
export interface PolicyInstance {
  /**
   *
   * @type {number}
   * @memberof PolicyInstance
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PolicyInstance
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof PolicyInstance
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PolicyInstance
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PolicyInstance
   */
  requestCategory?: PolicyInstanceRequestCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof PolicyInstance
   */
  requestType?: PolicyInstanceRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PolicyInstance
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof PolicyInstance
   */
  payload?: string;
  /**
   *
   * @type {number}
   * @memberof PolicyInstance
   */
  requesterId?: number;
  /**
   *
   * @type {string}
   * @memberof PolicyInstance
   */
  requester?: string;
  /**
   *
   * @type {number}
   * @memberof PolicyInstance
   */
  accountId?: number;
  /**
   *
   * @type {string}
   * @memberof PolicyInstance
   */
  account?: string;
  /**
   *
   * @type {boolean}
   * @memberof PolicyInstance
   */
  isDeleted?: boolean;
}

export const PolicyInstanceRequestCategoryEnum = {
  User: "USER",
  Allowlist: "ALLOWLIST",
  Transaction: "TRANSACTION",
} as const;

export type PolicyInstanceRequestCategoryEnum =
  (typeof PolicyInstanceRequestCategoryEnum)[keyof typeof PolicyInstanceRequestCategoryEnum];
export const PolicyInstanceRequestTypeEnum = {
  New: "NEW",
  Update: "UPDATE",
  Delete: "DELETE",
  Withdraw: "WITHDRAW",
  Deposit: "DEPOSIT",
  Transfer: "TRANSFER",
  Settlement: "SETTLEMENT",
} as const;

export type PolicyInstanceRequestTypeEnum =
  (typeof PolicyInstanceRequestTypeEnum)[keyof typeof PolicyInstanceRequestTypeEnum];

/**
 * policyStatus Enum
 * @export
 * @enum {string}
 */

export const PolicyStatusEnum = {
  Open: "OPEN",
  Initiated: "INITIATED",
  Cancelled: "CANCELLED",
  Completed: "COMPLETED",
  Expired: "EXPIRED",
} as const;

export type PolicyStatusEnum = (typeof PolicyStatusEnum)[keyof typeof PolicyStatusEnum];

/**
 *
 * @export
 * @interface PriceInfo
 */
export interface PriceInfo {
  /**
   *
   * @type {string}
   * @memberof PriceInfo
   */
  assetSymbol?: string;
  /**
   *
   * @type {string}
   * @memberof PriceInfo
   */
  assetTicker?: string;
  /**
   *
   * @type {string}
   * @memberof PriceInfo
   */
  network?: string;
  /**
   *
   * @type {number}
   * @memberof PriceInfo
   */
  bidPrice?: number;
  /**
   *
   * @type {number}
   * @memberof PriceInfo
   */
  bidSize?: number;
  /**
   *
   * @type {number}
   * @memberof PriceInfo
   */
  bidPrice_24hAgo?: number;
  /**
   *
   * @type {number}
   * @memberof PriceInfo
   */
  askPrice?: number;
  /**
   *
   * @type {number}
   * @memberof PriceInfo
   */
  askSize?: number;
  /**
   *
   * @type {number}
   * @memberof PriceInfo
   */
  askPrice_24hAgo?: number;
  /**
   *
   * @type {string}
   * @memberof PriceInfo
   */
  timestamp?: string;
}
/**
 *
 * @export
 * @interface ReconWallet
 */
export interface ReconWallet {
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  walletId?: number;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  accountId?: number;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  organizationId?: number;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  providerId?: number;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  assetId?: number;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  networkId?: number;
  /**
   *
   * @type {string}
   * @memberof ReconWallet
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ReconWallet
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ReconWallet
   */
  temperature?: string;
  /**
   *
   * @type {string}
   * @memberof ReconWallet
   */
  address?: string;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  statusId?: number;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  internalLedgerId?: number;
  /**
   *
   * @type {string}
   * @memberof ReconWallet
   */
  assetSymbol?: string;
  /**
   *
   * @type {string}
   * @memberof ReconWallet
   */
  network?: string;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  reconQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  pendingQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  reconProviderId?: number;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  reconTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  reconStatusId?: number;
  /**
   *
   * @type {string}
   * @memberof ReconWallet
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof ReconWallet
   */
  depositWalletId?: number;
}
/**
 * Role
 * @export
 * @enum {string}
 */

export const Role = {
  Client: "CLIENT",
  Da: "DA",
  View: "VIEW",
} as const;

export type Role = (typeof Role)[keyof typeof Role];

/**
 *
 * @export
 * @interface SendMessageData
 */
export interface SendMessageData {
  /**
   *
   * @type {string}
   * @memberof SendMessageData
   */
  messageType: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof SendMessageData
   */
  content: { [key: string]: any } | null;
}
/**
 *
 * @export
 * @interface SendMessageRequest
 */
export interface SendMessageRequest {
  /**
   *
   * @type {string}
   * @memberof SendMessageRequest
   */
  messageType: string;
  /**
   *
   * @type {Array<{ [key: string]: any; }>}
   * @memberof SendMessageRequest
   */
  payload: Array<{ [key: string]: any }>;
}
/**
 * Status
 * @export
 * @enum {string}
 */

export const Status = {
  PendingActivation: "PENDING_ACTIVATION",
  PendingChanges: "PENDING_CHANGES",
  Active: "ACTIVE",
  Deleted: "DELETED",
  Locked: "LOCKED",
} as const;

export type Status = (typeof Status)[keyof typeof Status];

/**
 *
 * @export
 * @interface TransactionInfo
 */
export interface TransactionInfo {
  /**
   *
   * @type {string}
   * @memberof TransactionInfo
   */
  txId?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionInfo
   */
  date?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionInfo
   */
  blockId?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionInfo
   */
  blockNumber?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionInfo
   */
  confirmations?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionInfo
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionInfo
   */
  assetSymbol?: string;
  /**
   *
   * @type {number}
   * @memberof TransactionInfo
   */
  nonce?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionInfo
   */
  num_events?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionInfo
   */
  transactionQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionInfo
   */
  transactionFee?: number;
  /**
   * list of Events
   * @type {Array<TransactionInfoEvent>}
   * @memberof TransactionInfo
   */
  events?: Array<TransactionInfoEvent>;
}
/**
 *
 * @export
 * @interface TransactionInfoEvent
 */
export interface TransactionInfoEvent {
  /**
   *
   * @type {number}
   * @memberof TransactionInfoEvent
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionInfoEvent
   */
  date?: number;
  /**
   *
   * @type {number}
   * @memberof TransactionInfoEvent
   */
  decimals?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionInfoEvent
   */
  denomination?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionInfoEvent
   */
  destination?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionInfoEvent
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionInfoEvent
   */
  source?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionInfoEvent
   */
  transactionId?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionInfoEvent
   */
  eventType?: string;
}
/**
 *
 * @export
 * @interface TriggerRecon
 */
export interface TriggerRecon {
  /**
   *
   * @type {Array<string>}
   * @memberof TriggerRecon
   */
  walletTypes: Array<string>;
}
/**
 *
 * @export
 * @interface UpdateWallet
 */
export interface UpdateWallet {
  /**
   *
   * @type {string}
   * @memberof UpdateWallet
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateWallet
   */
  description?: string;
}
/**
 * Object model representing an user
 * @export
 * @interface User
 */
export interface User {
  /**
   * User Id
   * @type {number}
   * @memberof User
   */
  id: number;
  /**
   *
   * @type {Name}
   * @memberof User
   */
  name: Name;
  /**
   * Position
   * @type {string}
   * @memberof User
   */
  position: string | null;
  /**
   * Account Id
   * @type {number}
   * @memberof User
   */
  accountId: number;
  /**
   *
   * @type {UserRole}
   * @memberof User
   */
  userRole: UserRole;
  /**
   * Email
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {Phone}
   * @memberof User
   */
  phone: Phone;
  /**
   *
   * @type {AccountLevelPermission}
   * @memberof User
   */
  accountPermissions?: AccountLevelPermission | null;
  /**
   * Organization Level Permissions
   * @type {Array<OrganizationLevelPermission>}
   * @memberof User
   */
  organizations?: Array<OrganizationLevelPermission> | null;
  /**
   *
   * @type {Status}
   * @memberof User
   */
  status: Status;
  /**
   * Account Attributes
   * @type {Array<Attribute>}
   * @memberof User
   */
  attributes?: Array<Attribute> | null;
}

/**
 * Object model representing an user request
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
  /**
   *
   * @type {Name}
   * @memberof UserRequest
   */
  name: Name;
  /**
   * Position
   * @type {string}
   * @memberof UserRequest
   */
  position: string | null;
  /**
   * Account Id
   * @type {number}
   * @memberof UserRequest
   */
  accountId: number;
  /**
   *
   * @type {UserRole}
   * @memberof UserRequest
   */
  userRole: UserRole;
  /**
   * Email
   * @type {string}
   * @memberof UserRequest
   */
  email: string;
  /**
   *
   * @type {Phone}
   * @memberof UserRequest
   */
  phone: Phone;
  /**
   *
   * @type {AccountLevelPermission}
   * @memberof UserRequest
   */
  accountPermissions?: AccountLevelPermission | null;
  /**
   * Organization Permissions
   * @type {Array<OrganizationLevelPermission>}
   * @memberof UserRequest
   */
  organizations?: Array<OrganizationLevelPermission> | null;
  /**
   * Account Attributes
   * @type {Array<Attribute>}
   * @memberof UserRequest
   */
  attributes?: Array<Attribute> | null;
}
/**
 * Object model representing an user role
 * @export
 * @interface UserRole
 */
export interface UserRole {
  /**
   *
   * @type {Status}
   * @memberof UserRole
   */
  status?: Status;
  /**
   * Role
   * @type {Array<Role>}
   * @memberof UserRole
   */
  roles: Array<Role>;
}

/**
 * Object model representing an user session
 * @export
 * @interface UserSession
 */
export interface UserSession {
  /**
   *
   * @type {AccountLevelPermission}
   * @memberof UserSession
   */
  accountPermissions: AccountLevelPermission | null;
  /**
   * Organization Permissions
   * @type {Array<OrganizationLevelPermission>}
   * @memberof UserSession
   */
  organizationPermissions: Array<OrganizationLevelPermission>;
}
/**
 *
 * @export
 * @interface UserSessionHistory
 */
export interface UserSessionHistory {
  /**
   *
   * @type {number}
   * @memberof UserSessionHistory
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof UserSessionHistory
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof UserSessionHistory
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof UserSessionHistory
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof UserSessionHistory
   */
  userName: string;
  /**
   *
   * @type {string}
   * @memberof UserSessionHistory
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof UserSessionHistory
   */
  status: string;
  /**
   *
   * @type {boolean}
   * @memberof UserSessionHistory
   */
  isClientViewable: boolean;
  /**
   *
   * @type {string}
   * @memberof UserSessionHistory
   */
  correlationId?: string;
  /**
   *
   * @type {string}
   * @memberof UserSessionHistory
   */
  executionId?: string;
}
/**
 *
 * @export
 * @interface UserSessionHistoryRequest
 */
export interface UserSessionHistoryRequest {
  /**
   *
   * @type {number}
   * @memberof UserSessionHistoryRequest
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof UserSessionHistoryRequest
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof UserSessionHistoryRequest
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof UserSessionHistoryRequest
   */
  userName: string;
  /**
   *
   * @type {string}
   * @memberof UserSessionHistoryRequest
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof UserSessionHistoryRequest
   */
  status: string;
  /**
   *
   * @type {boolean}
   * @memberof UserSessionHistoryRequest
   */
  isClientViewable?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserSessionHistoryRequest
   */
  correlationId?: string;
  /**
   *
   * @type {string}
   * @memberof UserSessionHistoryRequest
   */
  executionId?: string;
}
/**
 * VASP
 * @export
 * @interface Vasp
 */
export interface Vasp {
  /**
   * Decentralized Identifier
   * @type {string}
   * @memberof Vasp
   */
  did?: string;
  /**
   *
   * @type {string}
   * @memberof Vasp
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Vasp
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof Vasp
   */
  logo?: string;
  /**
   *
   * @type {string}
   * @memberof Vasp
   */
  incorporationCountry?: string;
  /**
   *
   * @type {string}
   * @memberof Vasp
   */
  documents?: string;
  /**
   *
   * @type {boolean}
   * @memberof Vasp
   */
  hasAdmin?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Vasp
   */
  isNotifiable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Vasp
   */
  isActiveSender?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Vasp
   */
  isActiveReceiver?: boolean;
  /**
   *
   * @type {string}
   * @memberof Vasp
   */
  issuers?: string;
}
/**
 *
 * @export
 * @interface VaspCustomerDid
 */
export interface VaspCustomerDid {
  /**
   * Account Id
   * @type {number}
   * @memberof VaspCustomerDid
   */
  accountId: number;
  /**
   * Organization Id
   * @type {number}
   * @memberof VaspCustomerDid
   */
  organizationId: number;
  /**
   *
   * @type {string}
   * @memberof VaspCustomerDid
   */
  did: string;
  /**
   *
   * @type {string}
   * @memberof VaspCustomerDid
   */
  customerRef: string;
}
/**
 *
 * @export
 * @interface VaspCustomerToken
 */
export interface VaspCustomerToken {
  /**
   *
   * @type {string}
   * @memberof VaspCustomerToken
   */
  access_token: string;
  /**
   *
   * @type {string}
   * @memberof VaspCustomerToken
   */
  hash: string;
  /**
   *
   * @type {string}
   * @memberof VaspCustomerToken
   */
  token_type: string;
  /**
   *
   * @type {string}
   * @memberof VaspCustomerToken
   */
  scope: string;
}
/**
 * VASP Request
 * @export
 * @interface VaspRequest
 */
export interface VaspRequest {
  /**
   *
   * @type {string}
   * @memberof VaspRequest
   */
  name: string;
}
/**
 *
 * @export
 * @interface VaspTransaction
 */
export interface VaspTransaction {
  /**
   * Identifier of the Transfer
   * @type {string}
   * @memberof VaspTransaction
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VaspTransaction
   */
  status?: VaspTransactionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof VaspTransaction
   */
  transactionType?: VaspTransactionTransactionTypeEnum;
  /**
   * Asset symbol (BTC,ETH)
   * @type {string}
   * @memberof VaspTransaction
   */
  transactionAsset: string;
  /**
   * Amount in base unit of the asset (satoshi, wei, etc)
   * @type {string}
   * @memberof VaspTransaction
   */
  transactionAmount: string;
  /**
   * Charged Quantity
   * @type {number}
   * @memberof VaspTransaction
   */
  chargedQuantity?: number;
  /**
   *
   * @type {VaspTransactionAmountInLocalCurrency}
   * @memberof VaspTransaction
   */
  amountInLocalCurrency?: VaspTransactionAmountInLocalCurrency;
  /**
   *
   * @type {VaspTransactionBeneficiaryAmountInLocalCurrency}
   * @memberof VaspTransaction
   */
  beneficiaryAmountInLocalCurrency?: VaspTransactionBeneficiaryAmountInLocalCurrency;
  /**
   * Decentralized Identifier
   * @type {string}
   * @memberof VaspTransaction
   */
  originatorDid: string;
  /**
   * Decentralized Identifier
   * @type {string}
   * @memberof VaspTransaction
   */
  beneficiaryDid: string;
  /**
   * Decentralized Identifier
   * @type {string}
   * @memberof VaspTransaction
   */
  originatorVASPDid: string;
  /**
   * Decentralized Identifier
   * @type {string}
   * @memberof VaspTransaction
   */
  beneficiaryVASPDid: string;
  /**
   *
   * @type {VaspTransactionRequestTransactionBlockchainInfo}
   * @memberof VaspTransaction
   */
  transactionBlockchainInfo: VaspTransactionRequestTransactionBlockchainInfo;
  /**
   *
   * @type {VaspTransactionIvms101}
   * @memberof VaspTransaction
   */
  ivms101?: VaspTransactionIvms101;
  /**
   * Encrypted IVMS101 data
   * @type {string}
   * @memberof VaspTransaction
   */
  ivms101Encrypted?: string;
  /**
   *
   * @type {string}
   * @memberof VaspTransaction
   */
  protocol?: string;
  /**
   *
   * @type {VaspTransactionProtocolData}
   * @memberof VaspTransaction
   */
  protocolData?: VaspTransactionProtocolData;
  /**
   *
   * @type {VaspTransactionRequestOriginatorProof}
   * @memberof VaspTransaction
   */
  originatorProof?: VaspTransactionRequestOriginatorProof;
  /**
   *
   * @type {VaspTransactionRequestOriginatorProof}
   * @memberof VaspTransaction
   */
  beneficiaryProof?: VaspTransactionRequestOriginatorProof;
  /**
   *
   * @type {Array<VaspTransactionActionsInner>}
   * @memberof VaspTransaction
   */
  actions?: Array<VaspTransactionActionsInner>;
}

export const VaspTransactionStatusEnum = {
  Saved: "SAVED",
  MissingBeneficiaryData: "MISSING_BENEFICIARY_DATA",
  New: "NEW",
  WaitingForInformation: "WAITING_FOR_INFORMATION",
  Sent: "SENT",
  Requested: "REQUESTED",
  Rejected: "REJECTED",
  Declined: "DECLINED",
  Ack: "ACK",
  Cancelled: "CANCELLED",
  Incomplete: "INCOMPLETE",
  Accepted: "ACCEPTED",
  NotReady: "NOT_READY",
} as const;

export type VaspTransactionStatusEnum = (typeof VaspTransactionStatusEnum)[keyof typeof VaspTransactionStatusEnum];
export const VaspTransactionTransactionTypeEnum = {
  BelowThreshold: "BELOW_THRESHOLD",
  NonCustodial: "NON_CUSTODIAL",
  Travelrule: "TRAVELRULE",
  Unknown: "UNKNOWN",
} as const;

export type VaspTransactionTransactionTypeEnum =
  (typeof VaspTransactionTransactionTypeEnum)[keyof typeof VaspTransactionTransactionTypeEnum];

/**
 *
 * @export
 * @interface VaspTransactionActionsInner
 */
export interface VaspTransactionActionsInner {
  /**
   * Credential Hash
   * @type {string}
   * @memberof VaspTransactionActionsInner
   */
  hash?: string;
  /**
   * Decentralized Identifier
   * @type {string}
   * @memberof VaspTransactionActionsInner
   */
  issuerDID?: string;
  /**
   * Action executed
   * @type {string}
   * @memberof VaspTransactionActionsInner
   */
  action?: string;
  /**
   * Action Data
   * @type {string}
   * @memberof VaspTransactionActionsInner
   */
  actionData?: string;
}
/**
 * Amount in the local currency of the originator VASP\'s jurisdiction
 * @export
 * @interface VaspTransactionAmountInLocalCurrency
 */
export interface VaspTransactionAmountInLocalCurrency {
  /**
   * Amount in the local currency of the originator VASP\'s jurisdiction
   * @type {number}
   * @memberof VaspTransactionAmountInLocalCurrency
   */
  amountInLocalCurrency?: number;
  /**
   * Currency code
   * @type {string}
   * @memberof VaspTransactionAmountInLocalCurrency
   */
  currency?: string;
}
/**
 * Amount in the local currency of the beneficiary VASP\'s jurisdiction
 * @export
 * @interface VaspTransactionBeneficiaryAmountInLocalCurrency
 */
export interface VaspTransactionBeneficiaryAmountInLocalCurrency {
  /**
   * Amount in the local currency of the beneficiary VASP\'s jurisdiction
   * @type {number}
   * @memberof VaspTransactionBeneficiaryAmountInLocalCurrency
   */
  amountInLocalCurrency?: number;
  /**
   * Currency code
   * @type {string}
   * @memberof VaspTransactionBeneficiaryAmountInLocalCurrency
   */
  currency?: string;
}
/**
 *
 * @export
 * @interface VaspTransactionIvms101
 */
export interface VaspTransactionIvms101 {
  /**
   *
   * @type {VaspTransactionRequestOriginator}
   * @memberof VaspTransactionIvms101
   */
  originator?: VaspTransactionRequestOriginator;
  /**
   *
   * @type {VaspTransactionRequestBeneficiary}
   * @memberof VaspTransactionIvms101
   */
  beneficiary?: VaspTransactionRequestBeneficiary;
}
/**
 *
 * @export
 * @interface VaspTransactionProtocolData
 */
export interface VaspTransactionProtocolData {
  /**
   *
   * @type {VaspTransactionProtocolDataTrLightData}
   * @memberof VaspTransactionProtocolData
   */
  trLightData?: VaspTransactionProtocolDataTrLightData;
}
/**
 * TRLight Procotol data
 * @export
 * @interface VaspTransactionProtocolDataTrLightData
 */
export interface VaspTransactionProtocolDataTrLightData {
  /**
   * Notification emails
   * @type {Array<string>}
   * @memberof VaspTransactionProtocolDataTrLightData
   */
  notificationEmails?: Array<string>;
}
/**
 *
 * @export
 * @interface VaspTransactionRequest
 */
export interface VaspTransactionRequest {
  /**
   * Asset symbol (BTC,ETH)
   * @type {string}
   * @memberof VaspTransactionRequest
   */
  transactionAsset: string;
  /**
   * Amount in base unit of the asset (satoshi, wei, etc)
   * @type {number}
   * @memberof VaspTransactionRequest
   */
  transactionAssetQuantity: number;
  /**
   * Asset decimals
   * @type {number}
   * @memberof VaspTransactionRequest
   */
  transactionAssetDecimals: number;
  /**
   * Decentralized Identifier
   * @type {string}
   * @memberof VaspTransactionRequest
   */
  originatorDid?: string;
  /**
   * Decentralized Identifier
   * @type {string}
   * @memberof VaspTransactionRequest
   */
  beneficiaryDid?: string;
  /**
   *
   * @type {string}
   * @memberof VaspTransactionRequest
   */
  beneficiaryRef?: string;
  /**
   *
   * @type {string}
   * @memberof VaspTransactionRequest
   */
  originatorRef?: string;
  /**
   * Decentralized Identifier
   * @type {string}
   * @memberof VaspTransactionRequest
   */
  originatorVASPDid: string;
  /**
   * Beneficiary VASP did - If you do not have this data, we will try to resolve it.
   * @type {string}
   * @memberof VaspTransactionRequest
   */
  beneficiaryVASPDid?: string;
  /**
   * Beneficiary VASP Name - If you do not have this data, we will try to resolve it.
   * @type {string}
   * @memberof VaspTransactionRequest
   */
  beneficiaryVASPname: string;
  /**
   *
   * @type {VaspTransactionRequestTransactionBlockchainInfo}
   * @memberof VaspTransactionRequest
   */
  transactionBlockchainInfo: VaspTransactionRequestTransactionBlockchainInfo;
  /**
   *
   * @type {VaspTransactionRequestOriginator}
   * @memberof VaspTransactionRequest
   */
  originator?: VaspTransactionRequestOriginator;
  /**
   *
   * @type {VaspTransactionRequestBeneficiary}
   * @memberof VaspTransactionRequest
   */
  beneficiary?: VaspTransactionRequestBeneficiary;
  /**
   * Encrypted Personal Identificable Information (obscure schema-less value)
   * @type {string}
   * @memberof VaspTransactionRequest
   */
  encrypted?: string;
  /**
   *
   * @type {string}
   * @memberof VaspTransactionRequest
   */
  protocol?: string;
  /**
   * Beneficiary VASP Notification Email
   * @type {string}
   * @memberof VaspTransactionRequest
   */
  notificationEmail?: string;
  /**
   * Disable beneficiary data validation (opportunistic compliance)
   * @type {boolean}
   * @memberof VaspTransactionRequest
   */
  skipBeneficiaryDataValidation?: boolean;
  /**
   * Should consider beneficiary\'s jurisdiction in determining if a transfer is of type TRAVEL_RULE
   * @type {boolean}
   * @memberof VaspTransactionRequest
   */
  travelRuleBehavior?: boolean;
  /**
   *
   * @type {VaspTransactionRequestOriginatorProof}
   * @memberof VaspTransactionRequest
   */
  originatorProof?: VaspTransactionRequestOriginatorProof;
  /**
   *
   * @type {VaspTransactionRequestOriginatorProof}
   * @memberof VaspTransactionRequest
   */
  beneficiaryProof?: VaspTransactionRequestOriginatorProof;
  /**
   * Chainalysis network
   * @type {string}
   * @memberof VaspTransactionRequest
   */
  network?: string;
}
/**
 * The beneficiary is defined in Section 1.1 as the natural or legal person or legal arrangement who is identified by the originator as the receiver of the requested VA transfer.
 * @export
 * @interface VaspTransactionRequestBeneficiary
 */
export interface VaspTransactionRequestBeneficiary {
  /**
   * the natural or legal person or legal arrangement who is identified by the originator as the receiver of the requested VA transfer.
   * @type {Array<IvmsPerson>}
   * @memberof VaspTransactionRequestBeneficiary
   */
  beneficiaryPersons: Array<IvmsPerson>;
  /**
   * Identifier of an account that is used to process the transfer. The value for this element is case-sensitive.
   * @type {Array<string>}
   * @memberof VaspTransactionRequestBeneficiary
   */
  accountNumber?: Array<string>;
}
/**
 * The originator is defined in Section 1.1 as the account holder who allows the VA transfer from that account or, where there is no account, the natural or legal person that places the order with the originating VASP to perform the VA transfer.
 * @export
 * @interface VaspTransactionRequestOriginator
 */
export interface VaspTransactionRequestOriginator {
  /**
   * the account holder who allows the VA transfer from that account or, where there is no account, the natural or legal person that places the order with the originating VASP to perform the VA transfer.
   * @type {Array<IvmsPerson>}
   * @memberof VaspTransactionRequestOriginator
   */
  originatorPersons: Array<IvmsPerson>;
  /**
   * Identifier of an account that is used to process the transfer. The value for this element is case-sensitive.
   * @type {Array<string>}
   * @memberof VaspTransactionRequestOriginator
   */
  accountNumber?: Array<string>;
}
/**
 * Ownership Proof data
 * @export
 * @interface VaspTransactionRequestOriginatorProof
 */
export interface VaspTransactionRequestOriginatorProof {
  /**
   * Type
   * @type {string}
   * @memberof VaspTransactionRequestOriginatorProof
   */
  type?: string;
  /**
   * Proof
   * @type {string}
   * @memberof VaspTransactionRequestOriginatorProof
   */
  proof?: string;
}
/**
 * Information about the transaction on the blockchain
 * @export
 * @interface VaspTransactionRequestTransactionBlockchainInfo
 */
export interface VaspTransactionRequestTransactionBlockchainInfo {
  /**
   * Transaction txHash
   * @type {string}
   * @memberof VaspTransactionRequestTransactionBlockchainInfo
   */
  txHash?: string;
  /**
   * Originator Address
   * @type {string}
   * @memberof VaspTransactionRequestTransactionBlockchainInfo
   */
  origin?: string;
  /**
   * Destination Address
   * @type {string}
   * @memberof VaspTransactionRequestTransactionBlockchainInfo
   */
  destination: string;
}
/**
 *
 * @export
 * @interface Wallet
 */
export interface Wallet {
  /**
   *
   * @type {number}
   * @memberof Wallet
   */
  walletId: number;
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  correlationId: string;
  /**
   *
   * @type {number}
   * @memberof Wallet
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof Wallet
   */
  organizationId: number;
  /**
   *
   * @type {number}
   * @memberof Wallet
   */
  providerId?: number;
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  assetSymbol: string;
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  assetTicker: string;
  /**
   *
   * @type {number}
   * @memberof Wallet
   */
  assetId?: number;
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  network: string;
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  temperature: string;
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  depositAddress?: string;
  /**
   *
   * @type {number}
   * @memberof Wallet
   */
  quantity: number;
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  status: string;
  /**
   *
   * @type {number}
   * @memberof Wallet
   */
  internalLedgerId?: number;
  /**
   *
   * @type {number}
   * @memberof Wallet
   */
  availableBalance?: number;
  /**
   *
   * @type {number}
   * @memberof Wallet
   */
  pendingBalance?: number;
  /**
   *
   * @type {number}
   * @memberof Wallet
   */
  lockedBalance?: number;
  /**
   *
   * @type {number}
   * @memberof Wallet
   */
  creditBalance?: number;
  /**
   *
   * @type {number}
   * @memberof Wallet
   */
  feeWalletId?: number | null;
  /**
   *
   * @type {string}
   * @memberof Wallet
   */
  feeAssetSymbol?: string | null;
  /**
   *
   * @type {number}
   * @memberof Wallet
   */
  depositWalletId?: number;
  /**
   * Off Exchange External Account Name
   * @type {string}
   * @memberof Wallet
   */
  offExchangeExternalAccountName?: string;
}
/**
 *
 * @export
 * @interface WalletAggregate
 */
export interface WalletAggregate {
  /**
   *
   * @type {number}
   * @memberof WalletAggregate
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof WalletAggregate
   */
  assetSymbol?: string;
  /**
   *
   * @type {string}
   * @memberof WalletAggregate
   */
  assetTicker?: string;
  /**
   *
   * @type {string}
   * @memberof WalletAggregate
   */
  temperature?: string;
}
/**
 *
 * @export
 * @interface WalletHistory
 */
export interface WalletHistory {
  /**
   *
   * @type {number}
   * @memberof WalletHistory
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof WalletHistory
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof WalletHistory
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof WalletHistory
   */
  organizationId: number;
  /**
   *
   * @type {number}
   * @memberof WalletHistory
   */
  walletId: number;
  /**
   *
   * @type {string}
   * @memberof WalletHistory
   */
  payload?: string;
  /**
   *
   * @type {string}
   * @memberof WalletHistory
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof WalletHistory
   */
  initiatorUserId: number;
  /**
   *
   * @type {string}
   * @memberof WalletHistory
   */
  initiatorUserName: string;
  /**
   *
   * @type {number}
   * @memberof WalletHistory
   */
  requesterUserId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletHistory
   */
  requesterUserName?: string;
  /**
   *
   * @type {boolean}
   * @memberof WalletHistory
   */
  isClientViewable: boolean;
  /**
   *
   * @type {string}
   * @memberof WalletHistory
   */
  correlationId?: string;
  /**
   *
   * @type {string}
   * @memberof WalletHistory
   */
  executionId?: string;
}
/**
 *
 * @export
 * @interface WalletHistoryRequest
 */
export interface WalletHistoryRequest {
  /**
   *
   * @type {number}
   * @memberof WalletHistoryRequest
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof WalletHistoryRequest
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof WalletHistoryRequest
   */
  organizationId: number;
  /**
   *
   * @type {number}
   * @memberof WalletHistoryRequest
   */
  walletId: number;
  /**
   *
   * @type {string}
   * @memberof WalletHistoryRequest
   */
  payload?: string;
  /**
   *
   * @type {string}
   * @memberof WalletHistoryRequest
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof WalletHistoryRequest
   */
  initiatorUserId: number;
  /**
   *
   * @type {string}
   * @memberof WalletHistoryRequest
   */
  initiatorUserName: string;
  /**
   *
   * @type {number}
   * @memberof WalletHistoryRequest
   */
  requesterUserId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletHistoryRequest
   */
  requesterUserName?: string;
  /**
   *
   * @type {boolean}
   * @memberof WalletHistoryRequest
   */
  isClientViewable?: boolean;
  /**
   *
   * @type {string}
   * @memberof WalletHistoryRequest
   */
  correlationId?: string;
  /**
   *
   * @type {string}
   * @memberof WalletHistoryRequest
   */
  executionId?: string;
}
/**
 *
 * @export
 * @interface WalletTransaction
 */
export interface WalletTransaction {
  /**
   *
   * @type {number}
   * @memberof WalletTransaction
   */
  walletTransactionId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransaction
   */
  correlationId?: string;
  /**
   * Account Id
   * @type {number}
   * @memberof WalletTransaction
   */
  accountId?: number | null;
  /**
   * Organization Id
   * @type {number}
   * @memberof WalletTransaction
   */
  organizationId?: number | null;
  /**
   *
   * @type {string}
   * @memberof WalletTransaction
   */
  sourceType?: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransaction
   */
  sourceId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransaction
   */
  sourceName?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransaction
   */
  sourceAddress?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransaction
   */
  destinationType?: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransaction
   */
  destinationId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransaction
   */
  destinationName?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransaction
   */
  destinationAddress?: string;
  /**
   * Type
   * @type {string}
   * @memberof WalletTransaction
   */
  type?: WalletTransactionTypeEnum;
  /**
   *
   * @type {number}
   * @memberof WalletTransaction
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransaction
   */
  amountUSD?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransaction
   */
  assetSymbol?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransaction
   */
  assetTicker?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransaction
   */
  network?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransaction
   */
  blockchainTransactionId?: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransaction
   */
  fee?: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransaction
   */
  feeAmountUSD?: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransaction
   */
  blockHeight?: number;
  /**
   *
   * @type {WalletTransactionStatus}
   * @memberof WalletTransaction
   */
  status?: WalletTransactionStatus;
  /**
   *
   * @type {number}
   * @memberof WalletTransaction
   */
  initiatorUserId?: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransaction
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransaction
   */
  quarantineTransactionId?: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransaction
   */
  feeWalletId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransaction
   */
  feeWalletName?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransaction
   */
  feeNetwork?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransaction
   */
  feeAssetSymbol?: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransaction
   */
  parentTransactionId?: number;
}

export const WalletTransactionTypeEnum = {
  Withdraw: "WITHDRAW",
  Deposit: "DEPOSIT",
  Fee: "FEE",
  Feerefund: "FEEREFUND",
  Transfer: "TRANSFER",
  AddCollateralWithdraw: "ADD_COLLATERAL_WITHDRAW",
  Settlement: "SETTLEMENT",
} as const;

export type WalletTransactionTypeEnum = (typeof WalletTransactionTypeEnum)[keyof typeof WalletTransactionTypeEnum];

/**
 *
 * @export
 * @interface WalletTransactionHistory
 */
export interface WalletTransactionHistory {
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistory
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistory
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistory
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistory
   */
  organizationId: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistory
   */
  walletId: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistory
   */
  walletTransactionId: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistory
   */
  sourceType: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistory
   */
  sourceId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistory
   */
  destinationType: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistory
   */
  destinationId: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistory
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistory
   */
  amountUSD?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistory
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistory
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistory
   */
  initiatorUserId: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistory
   */
  initiatorUserName: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistory
   */
  requesterUserId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistory
   */
  requesterUserName?: string;
  /**
   *
   * @type {boolean}
   * @memberof WalletTransactionHistory
   */
  isClientViewable: boolean;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistory
   */
  correlationId?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistory
   */
  executionId?: string;
}
/**
 *
 * @export
 * @interface WalletTransactionHistoryRequest
 */
export interface WalletTransactionHistoryRequest {
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistoryRequest
   */
  timestamp: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistoryRequest
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistoryRequest
   */
  organizationId: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistoryRequest
   */
  walletId: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistoryRequest
   */
  walletTransactionId: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistoryRequest
   */
  sourceType: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistoryRequest
   */
  sourceId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistoryRequest
   */
  destinationType: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistoryRequest
   */
  destinationId: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistoryRequest
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistoryRequest
   */
  amountUSD?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistoryRequest
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistoryRequest
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistoryRequest
   */
  initiatorUserId: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistoryRequest
   */
  initiatorUserName: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionHistoryRequest
   */
  requesterUserId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistoryRequest
   */
  requesterUserName?: string;
  /**
   *
   * @type {boolean}
   * @memberof WalletTransactionHistoryRequest
   */
  isClientViewable?: boolean;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistoryRequest
   */
  correlationId?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionHistoryRequest
   */
  executionId?: string;
}
/**
 *
 * @export
 * @interface WalletTransactionReview
 */
export interface WalletTransactionReview {
  /**
   *
   * @type {number}
   * @memberof WalletTransactionReview
   */
  walletTransactionId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionReview
   */
  assetSymbol?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionReview
   */
  assetTicker?: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionReview
   */
  sourceWalletId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionReview
   */
  sourceAddress?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionReview
   */
  destinationAddress?: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionReview
   */
  allowlistId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionReview
   */
  allowlistName?: string;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionReview
   */
  destinationType?: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionReview
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionReview
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionReview
   */
  accountId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionReview
   */
  accountName?: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionReview
   */
  organizationId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionReview
   */
  organizationName?: string;
  /**
   *
   * @type {number}
   * @memberof WalletTransactionReview
   */
  initiatorUserId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletTransactionReview
   */
  initiatorName?: string;
}
/**
 * Status
 * @export
 * @enum {string}
 */

export const WalletTransactionStatus = {
  Initiated: "INITIATED",
  Pending: "PENDING",
  Completed: "COMPLETED",
  Cancelled: "CANCELLED",
  Quarantineflagged: "QUARANTINEFLAGGED",
  Quarantinedfunds: "QUARANTINEDFUNDS",
  Failed: "FAILED",
  Signing: "SIGNING",
  Confirming: "CONFIRMING",
} as const;

export type WalletTransactionStatus = (typeof WalletTransactionStatus)[keyof typeof WalletTransactionStatus];

/**
 * Wallet Type
 * @export
 * @enum {string}
 */

export const WalletType = {
  Custody: "CUSTODY",
  Trading: "TRADING",
  Deposit: "DEPOSIT",
  Trust: "TRUST",
  Fee: "FEE",
  OffExchange: "OFF_EXCHANGE",
} as const;

export type WalletType = (typeof WalletType)[keyof typeof WalletType];

/**
 *
 * @export
 * @interface WalletUpdate
 */
export interface WalletUpdate {
  /**
   *
   * @type {number}
   * @memberof WalletUpdate
   */
  walletId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletUpdate
   */
  correlationId?: string;
  /**
   *
   * @type {number}
   * @memberof WalletUpdate
   */
  accountId?: number;
  /**
   *
   * @type {number}
   * @memberof WalletUpdate
   */
  organizationId?: number;
  /**
   *
   * @type {number}
   * @memberof WalletUpdate
   */
  providerId?: number;
  /**
   *
   * @type {string}
   * @memberof WalletUpdate
   */
  assetSymbol?: string;
  /**
   *
   * @type {string}
   * @memberof WalletUpdate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof WalletUpdate
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof WalletUpdate
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof WalletUpdate
   */
  temperature?: string;
  /**
   *
   * @type {string}
   * @memberof WalletUpdate
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof WalletUpdate
   */
  depositAddress?: string;
  /**
   *
   * @type {number}
   * @memberof WalletUpdate
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof WalletUpdate
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof WalletUpdate
   */
  internalLedgerId?: number;
  /**
   *
   * @type {number}
   * @memberof WalletUpdate
   */
  availableBalance?: number;
  /**
   *
   * @type {number}
   * @memberof WalletUpdate
   */
  pendingBalance?: number;
  /**
   *
   * @type {number}
   * @memberof WalletUpdate
   */
  creditBalance?: number;
  /**
   *
   * @type {number}
   * @memberof WalletUpdate
   */
  lockedBalance?: number;
  /**
   *
   * @type {number}
   * @memberof WalletUpdate
   */
  feeWalletId?: number | null;
  /**
   *
   * @type {string}
   * @memberof WalletUpdate
   */
  feeAssetSymbol?: string | null;
  /**
   *
   * @type {number}
   * @memberof WalletUpdate
   */
  depositWalletId?: number;
}
/**
 * Object model representing withdrawal authorization
 * @export
 * @interface WithdrawalAuthorization
 */
export interface WithdrawalAuthorization {
  /**
   * Indicator for Withdrawal Video Authorization
   * @type {boolean}
   * @memberof WithdrawalAuthorization
   */
  videoAuthorization: boolean;
  /**
   * Amount Limit
   * @type {number}
   * @memberof WithdrawalAuthorization
   */
  amountLimit?: number | null;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Delete an existing account
     * @summary Delete Account
     * @param {number} accountId Account Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAccount: async (accountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists("deleteAccount", "accountId", accountId);
      const localVarPath = `/account/{accountId}`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get an existing account
     * @summary Get Account
     * @param {number} accountId Account Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccount: async (accountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists("getAccount", "accountId", accountId);
      const localVarPath = `/account/{accountId}`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve a list of accounts matching specified search criteria.
     * @summary Get a list of Accounts
     * @param {number} [accountId] Account Id
     * @param {Array<'PENDING_ACTIVATION' | 'PENDING_CHANGES' | 'ACTIVE' | 'DELETED' | 'LOCKED'>} [status] Status
     * @param {number} [offset] Sets the number records that are skipped from the beginning of the search result.
     * @param {number} [limit] The maximum number of records to be returned at once.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccounts: async (
      accountId?: number,
      status?: Array<"PENDING_ACTIVATION" | "PENDING_CHANGES" | "ACTIVE" | "DELETED" | "LOCKED">,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/account`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (accountId !== undefined) {
        localVarQueryParameter["accountId"] = accountId;
      }

      if (status) {
        localVarQueryParameter["status"] = status;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update an existing account
     * @summary Update Account
     * @param {number} accountId Account Id
     * @param {AccountRequest} accountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount: async (
      accountId: number,
      accountRequest: AccountRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists("updateAccount", "accountId", accountId);
      // verify required parameter 'accountRequest' is not null or undefined
      assertParamExists("updateAccount", "accountRequest", accountRequest);
      const localVarPath = `/account/{accountId}`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(accountRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration);
  return {
    /**
     * Delete an existing account
     * @summary Delete Account
     * @param {number} accountId Account Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAccount(
      accountId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccount(accountId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get an existing account
     * @summary Get Account
     * @param {number} accountId Account Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccount(
      accountId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(accountId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Retrieve a list of accounts matching specified search criteria.
     * @summary Get a list of Accounts
     * @param {number} [accountId] Account Id
     * @param {Array<'PENDING_ACTIVATION' | 'PENDING_CHANGES' | 'ACTIVE' | 'DELETED' | 'LOCKED'>} [status] Status
     * @param {number} [offset] Sets the number records that are skipped from the beginning of the search result.
     * @param {number} [limit] The maximum number of records to be returned at once.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccounts(
      accountId?: number,
      status?: Array<"PENDING_ACTIVATION" | "PENDING_CHANGES" | "ACTIVE" | "DELETED" | "LOCKED">,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Account>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccounts(accountId, status, offset, limit, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update an existing account
     * @summary Update Account
     * @param {number} accountId Account Id
     * @param {AccountRequest} accountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAccount(
      accountId: number,
      accountRequest: AccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(accountId, accountRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AccountApiFp(configuration);
  return {
    /**
     * Delete an existing account
     * @summary Delete Account
     * @param {number} accountId Account Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAccount(accountId: number, options?: any): AxiosPromise<boolean> {
      return localVarFp.deleteAccount(accountId, options).then((request) => request(axios, basePath));
    },
    /**
     * Get an existing account
     * @summary Get Account
     * @param {number} accountId Account Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccount(accountId: number, options?: any): AxiosPromise<Account> {
      return localVarFp.getAccount(accountId, options).then((request) => request(axios, basePath));
    },
    /**
     * Retrieve a list of accounts matching specified search criteria.
     * @summary Get a list of Accounts
     * @param {number} [accountId] Account Id
     * @param {Array<'PENDING_ACTIVATION' | 'PENDING_CHANGES' | 'ACTIVE' | 'DELETED' | 'LOCKED'>} [status] Status
     * @param {number} [offset] Sets the number records that are skipped from the beginning of the search result.
     * @param {number} [limit] The maximum number of records to be returned at once.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccounts(
      accountId?: number,
      status?: Array<"PENDING_ACTIVATION" | "PENDING_CHANGES" | "ACTIVE" | "DELETED" | "LOCKED">,
      offset?: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<Array<Account>> {
      return localVarFp
        .getAccounts(accountId, status, offset, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update an existing account
     * @summary Update Account
     * @param {number} accountId Account Id
     * @param {AccountRequest} accountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount(accountId: number, accountRequest: AccountRequest, options?: any): AxiosPromise<Account> {
      return localVarFp.updateAccount(accountId, accountRequest, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
  /**
   * Delete an existing account
   * @summary Delete Account
   * @param {number} accountId Account Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public deleteAccount(accountId: number, options?: AxiosRequestConfig) {
    return AccountApiFp(this.configuration)
      .deleteAccount(accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get an existing account
   * @summary Get Account
   * @param {number} accountId Account Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public getAccount(accountId: number, options?: AxiosRequestConfig) {
    return AccountApiFp(this.configuration)
      .getAccount(accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve a list of accounts matching specified search criteria.
   * @summary Get a list of Accounts
   * @param {number} [accountId] Account Id
   * @param {Array<'PENDING_ACTIVATION' | 'PENDING_CHANGES' | 'ACTIVE' | 'DELETED' | 'LOCKED'>} [status] Status
   * @param {number} [offset] Sets the number records that are skipped from the beginning of the search result.
   * @param {number} [limit] The maximum number of records to be returned at once.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public getAccounts(
    accountId?: number,
    status?: Array<"PENDING_ACTIVATION" | "PENDING_CHANGES" | "ACTIVE" | "DELETED" | "LOCKED">,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return AccountApiFp(this.configuration)
      .getAccounts(accountId, status, offset, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update an existing account
   * @summary Update Account
   * @param {number} accountId Account Id
   * @param {AccountRequest} accountRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public updateAccount(accountId: number, accountRequest: AccountRequest, options?: AxiosRequestConfig) {
    return AccountApiFp(this.configuration)
      .updateAccount(accountId, accountRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AllowlistApi - axios parameter creator
 * @export
 */
export const AllowlistApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create allowlist
     * @summary Create Allowlist
     * @param {CreateAllowlistRequest} createAllowlistRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAllowlist: async (
      createAllowlistRequest: CreateAllowlistRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createAllowlistRequest' is not null or undefined
      assertParamExists("createAllowlist", "createAllowlistRequest", createAllowlistRequest);
      const localVarPath = `/allowlistAddress`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAllowlistRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve Allow List Address by Id.
     * @summary Get Allow List Address by id
     * @param {number} allowlistAddressId Allowlist address Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllowlistAddressById: async (
      allowlistAddressId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'allowlistAddressId' is not null or undefined
      assertParamExists("getAllowlistAddressById", "allowlistAddressId", allowlistAddressId);
      const localVarPath = `/allowlistAddress/{allowlistAddressId}`.replace(
        `{${"allowlistAddressId"}}`,
        encodeURIComponent(String(allowlistAddressId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve Allow List Addresses matching specified search criteria.
     * @summary Get Allow List Addresses
     * @param {number} [organizationId] Organization Id
     * @param {string} [address] address
     * @param {'PENDING' | 'DENIED' | 'APPROVED'} [status] Allowlist status
     * @param {string} [correlationId] correlationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllowlistAddresses: async (
      organizationId?: number,
      address?: string,
      status?: "PENDING" | "DENIED" | "APPROVED",
      correlationId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/allowlistAddress`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (organizationId !== undefined) {
        localVarQueryParameter["organizationId"] = organizationId;
      }

      if (address !== undefined) {
        localVarQueryParameter["address"] = address;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (correlationId !== undefined) {
        localVarQueryParameter["correlationId"] = correlationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AllowlistApi - functional programming interface
 * @export
 */
export const AllowlistApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AllowlistApiAxiosParamCreator(configuration);
  return {
    /**
     * Create allowlist
     * @summary Create Allowlist
     * @param {CreateAllowlistRequest} createAllowlistRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAllowlist(
      createAllowlistRequest: CreateAllowlistRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createAllowlist(createAllowlistRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Retrieve Allow List Address by Id.
     * @summary Get Allow List Address by id
     * @param {number} allowlistAddressId Allowlist address Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllowlistAddressById(
      allowlistAddressId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllowlistAddress>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllowlistAddressById(allowlistAddressId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Retrieve Allow List Addresses matching specified search criteria.
     * @summary Get Allow List Addresses
     * @param {number} [organizationId] Organization Id
     * @param {string} [address] address
     * @param {'PENDING' | 'DENIED' | 'APPROVED'} [status] Allowlist status
     * @param {string} [correlationId] correlationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllowlistAddresses(
      organizationId?: number,
      address?: string,
      status?: "PENDING" | "DENIED" | "APPROVED",
      correlationId?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AllowlistAddress>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllowlistAddresses(
        organizationId,
        address,
        status,
        correlationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AllowlistApi - factory interface
 * @export
 */
export const AllowlistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AllowlistApiFp(configuration);
  return {
    /**
     * Create allowlist
     * @summary Create Allowlist
     * @param {CreateAllowlistRequest} createAllowlistRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAllowlist(createAllowlistRequest: CreateAllowlistRequest, options?: any): AxiosPromise<void> {
      return localVarFp.createAllowlist(createAllowlistRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Retrieve Allow List Address by Id.
     * @summary Get Allow List Address by id
     * @param {number} allowlistAddressId Allowlist address Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllowlistAddressById(allowlistAddressId: number, options?: any): AxiosPromise<AllowlistAddress> {
      return localVarFp
        .getAllowlistAddressById(allowlistAddressId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieve Allow List Addresses matching specified search criteria.
     * @summary Get Allow List Addresses
     * @param {number} [organizationId] Organization Id
     * @param {string} [address] address
     * @param {'PENDING' | 'DENIED' | 'APPROVED'} [status] Allowlist status
     * @param {string} [correlationId] correlationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllowlistAddresses(
      organizationId?: number,
      address?: string,
      status?: "PENDING" | "DENIED" | "APPROVED",
      correlationId?: string,
      options?: any,
    ): AxiosPromise<Array<AllowlistAddress>> {
      return localVarFp
        .getAllowlistAddresses(organizationId, address, status, correlationId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AllowlistApi - object-oriented interface
 * @export
 * @class AllowlistApi
 * @extends {BaseAPI}
 */
export class AllowlistApi extends BaseAPI {
  /**
   * Create allowlist
   * @summary Create Allowlist
   * @param {CreateAllowlistRequest} createAllowlistRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AllowlistApi
   */
  public createAllowlist(createAllowlistRequest: CreateAllowlistRequest, options?: AxiosRequestConfig) {
    return AllowlistApiFp(this.configuration)
      .createAllowlist(createAllowlistRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve Allow List Address by Id.
   * @summary Get Allow List Address by id
   * @param {number} allowlistAddressId Allowlist address Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AllowlistApi
   */
  public getAllowlistAddressById(allowlistAddressId: number, options?: AxiosRequestConfig) {
    return AllowlistApiFp(this.configuration)
      .getAllowlistAddressById(allowlistAddressId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve Allow List Addresses matching specified search criteria.
   * @summary Get Allow List Addresses
   * @param {number} [organizationId] Organization Id
   * @param {string} [address] address
   * @param {'PENDING' | 'DENIED' | 'APPROVED'} [status] Allowlist status
   * @param {string} [correlationId] correlationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AllowlistApi
   */
  public getAllowlistAddresses(
    organizationId?: number,
    address?: string,
    status?: "PENDING" | "DENIED" | "APPROVED",
    correlationId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AllowlistApiFp(this.configuration)
      .getAllowlistAddresses(organizationId, address, status, correlationId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get all active assets based on the filter
     * @summary Get Assets
     * @param {number} [id] Asset Id
     * @param {string} [assetName] Asset Name
     * @param {string} [assetTicker] Asset Ticker
     * @param {string} [assetSymbol] Asset Symbol
     * @param {'BASE' | 'TOKEN'} [assetType] Asset Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssets: async (
      id?: number,
      assetName?: string,
      assetTicker?: string,
      assetSymbol?: string,
      assetType?: "BASE" | "TOKEN",
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/assets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      if (assetName !== undefined) {
        localVarQueryParameter["assetName"] = assetName;
      }

      if (assetTicker !== undefined) {
        localVarQueryParameter["assetTicker"] = assetTicker;
      }

      if (assetSymbol !== undefined) {
        localVarQueryParameter["assetSymbol"] = assetSymbol;
      }

      if (assetType !== undefined) {
        localVarQueryParameter["assetType"] = assetType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all active networks based on the filter
     * @summary Get Networks
     * @param {number} [id] Network Id
     * @param {string} [network] Network Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNetworks: async (id?: number, network?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/networks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      if (network !== undefined) {
        localVarQueryParameter["network"] = network;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get all active assets based on the filter
     * @summary Get Assets
     * @param {number} [id] Asset Id
     * @param {string} [assetName] Asset Name
     * @param {string} [assetTicker] Asset Ticker
     * @param {string} [assetSymbol] Asset Symbol
     * @param {'BASE' | 'TOKEN'} [assetType] Asset Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssets(
      id?: number,
      assetName?: string,
      assetTicker?: string,
      assetSymbol?: string,
      assetType?: "BASE" | "TOKEN",
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Asset>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssets(
        id,
        assetName,
        assetTicker,
        assetSymbol,
        assetType,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get all active networks based on the filter
     * @summary Get Networks
     * @param {number} [id] Network Id
     * @param {string} [network] Network Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNetworks(
      id?: number,
      network?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Network>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNetworks(id, network, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AssetsApiFp(configuration);
  return {
    /**
     * Get all active assets based on the filter
     * @summary Get Assets
     * @param {number} [id] Asset Id
     * @param {string} [assetName] Asset Name
     * @param {string} [assetTicker] Asset Ticker
     * @param {string} [assetSymbol] Asset Symbol
     * @param {'BASE' | 'TOKEN'} [assetType] Asset Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssets(
      id?: number,
      assetName?: string,
      assetTicker?: string,
      assetSymbol?: string,
      assetType?: "BASE" | "TOKEN",
      options?: any,
    ): AxiosPromise<Array<Asset>> {
      return localVarFp
        .getAssets(id, assetName, assetTicker, assetSymbol, assetType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all active networks based on the filter
     * @summary Get Networks
     * @param {number} [id] Network Id
     * @param {string} [network] Network Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNetworks(id?: number, network?: string, options?: any): AxiosPromise<Array<Network>> {
      return localVarFp.getNetworks(id, network, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
  /**
   * Get all active assets based on the filter
   * @summary Get Assets
   * @param {number} [id] Asset Id
   * @param {string} [assetName] Asset Name
   * @param {string} [assetTicker] Asset Ticker
   * @param {string} [assetSymbol] Asset Symbol
   * @param {'BASE' | 'TOKEN'} [assetType] Asset Type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsApi
   */
  public getAssets(
    id?: number,
    assetName?: string,
    assetTicker?: string,
    assetSymbol?: string,
    assetType?: "BASE" | "TOKEN",
    options?: AxiosRequestConfig,
  ) {
    return AssetsApiFp(this.configuration)
      .getAssets(id, assetName, assetTicker, assetSymbol, assetType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all active networks based on the filter
   * @summary Get Networks
   * @param {number} [id] Network Id
   * @param {string} [network] Network Name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsApi
   */
  public getNetworks(id?: number, network?: string, options?: AxiosRequestConfig) {
    return AssetsApiFp(this.configuration)
      .getNetworks(id, network, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BlockchainApi - axios parameter creator
 * @export
 */
export const BlockchainApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Build Sql Update Tx statements
     * @summary Build Sql Update Tx statements
     * @param {Array<BuildTransactionUpdatesRequest>} buildTransactionUpdatesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildTransactionUpdates: async (
      buildTransactionUpdatesRequest: Array<BuildTransactionUpdatesRequest>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'buildTransactionUpdatesRequest' is not null or undefined
      assertParamExists("buildTransactionUpdates", "buildTransactionUpdatesRequest", buildTransactionUpdatesRequest);
      const localVarPath = `/transaction/build-transaction-updates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        buildTransactionUpdatesRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get VASP Customer Token
     * @summary Get VASP Customer Token
     * @param {VaspCustomerDid} vaspCustomerDid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVASPCustomerToken: async (
      vaspCustomerDid: VaspCustomerDid,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'vaspCustomerDid' is not null or undefined
      assertParamExists("getVASPCustomerToken", "vaspCustomerDid", vaspCustomerDid);
      const localVarPath = `/vasp/auth-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(vaspCustomerDid, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BlockchainApi - functional programming interface
 * @export
 */
export const BlockchainApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BlockchainApiAxiosParamCreator(configuration);
  return {
    /**
     * Build Sql Update Tx statements
     * @summary Build Sql Update Tx statements
     * @param {Array<BuildTransactionUpdatesRequest>} buildTransactionUpdatesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async buildTransactionUpdates(
      buildTransactionUpdatesRequest: Array<BuildTransactionUpdatesRequest>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.buildTransactionUpdates(
        buildTransactionUpdatesRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get VASP Customer Token
     * @summary Get VASP Customer Token
     * @param {VaspCustomerDid} vaspCustomerDid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVASPCustomerToken(
      vaspCustomerDid: VaspCustomerDid,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VaspCustomerToken>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVASPCustomerToken(vaspCustomerDid, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BlockchainApi - factory interface
 * @export
 */
export const BlockchainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = BlockchainApiFp(configuration);
  return {
    /**
     * Build Sql Update Tx statements
     * @summary Build Sql Update Tx statements
     * @param {Array<BuildTransactionUpdatesRequest>} buildTransactionUpdatesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildTransactionUpdates(
      buildTransactionUpdatesRequest: Array<BuildTransactionUpdatesRequest>,
      options?: any,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .buildTransactionUpdates(buildTransactionUpdatesRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get VASP Customer Token
     * @summary Get VASP Customer Token
     * @param {VaspCustomerDid} vaspCustomerDid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVASPCustomerToken(vaspCustomerDid: VaspCustomerDid, options?: any): AxiosPromise<VaspCustomerToken> {
      return localVarFp.getVASPCustomerToken(vaspCustomerDid, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * BlockchainApi - object-oriented interface
 * @export
 * @class BlockchainApi
 * @extends {BaseAPI}
 */
export class BlockchainApi extends BaseAPI {
  /**
   * Build Sql Update Tx statements
   * @summary Build Sql Update Tx statements
   * @param {Array<BuildTransactionUpdatesRequest>} buildTransactionUpdatesRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BlockchainApi
   */
  public buildTransactionUpdates(
    buildTransactionUpdatesRequest: Array<BuildTransactionUpdatesRequest>,
    options?: AxiosRequestConfig,
  ) {
    return BlockchainApiFp(this.configuration)
      .buildTransactionUpdates(buildTransactionUpdatesRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get VASP Customer Token
   * @summary Get VASP Customer Token
   * @param {VaspCustomerDid} vaspCustomerDid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BlockchainApi
   */
  public getVASPCustomerToken(vaspCustomerDid: VaspCustomerDid, options?: AxiosRequestConfig) {
    return BlockchainApiFp(this.configuration)
      .getVASPCustomerToken(vaspCustomerDid, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FusionAuthApi - axios parameter creator
 * @export
 */
export const FusionAuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Fusion Auth User Log Out
     * @summary Fusion Auth Logout
     * @param {number} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fusionAuthUserLogout: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("fusionAuthUserLogout", "userId", userId);
      const localVarPath = `/fusionAuth/user/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FusionAuthApi - functional programming interface
 * @export
 */
export const FusionAuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FusionAuthApiAxiosParamCreator(configuration);
  return {
    /**
     * Fusion Auth User Log Out
     * @summary Fusion Auth Logout
     * @param {number} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fusionAuthUserLogout(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fusionAuthUserLogout(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FusionAuthApi - factory interface
 * @export
 */
export const FusionAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FusionAuthApiFp(configuration);
  return {
    /**
     * Fusion Auth User Log Out
     * @summary Fusion Auth Logout
     * @param {number} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fusionAuthUserLogout(userId: number, options?: any): AxiosPromise<void> {
      return localVarFp.fusionAuthUserLogout(userId, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * FusionAuthApi - object-oriented interface
 * @export
 * @class FusionAuthApi
 * @extends {BaseAPI}
 */
export class FusionAuthApi extends BaseAPI {
  /**
   * Fusion Auth User Log Out
   * @summary Fusion Auth Logout
   * @param {number} userId User Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FusionAuthApi
   */
  public fusionAuthUserLogout(userId: number, options?: AxiosRequestConfig) {
    return FusionAuthApiFp(this.configuration)
      .fusionAuthUserLogout(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HistorySummaryApi - axios parameter creator
 * @export
 */
export const HistorySummaryApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get Account History Summary matching specified search criteria.
     * @summary Get Account History Summary
     * @param {number} accountId Account Id
     * @param {number} offset How many items to skip
     * @param {number} limit How many items to return at one time
     * @param {number} [organizationId] Organization Id
     * @param {number} [userId] User Id
     * @param {string} [userName] User Name (sql %like% operation is performed)
     * @param {string} [type] History Type
     * @param {string} [description] History Description (sql %like% operation is performed)
     * @param {number} [fromDate] From Date
     * @param {number} [toDate] To Date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountHistorySummaries: async (
      accountId: number,
      offset: number,
      limit: number,
      organizationId?: number,
      userId?: number,
      userName?: string,
      type?: string,
      description?: string,
      fromDate?: number,
      toDate?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists("getAccountHistorySummaries", "accountId", accountId);
      // verify required parameter 'offset' is not null or undefined
      assertParamExists("getAccountHistorySummaries", "offset", offset);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists("getAccountHistorySummaries", "limit", limit);
      const localVarPath = `/account-history-summary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (accountId !== undefined) {
        localVarQueryParameter["accountId"] = accountId;
      }

      if (organizationId !== undefined) {
        localVarQueryParameter["organizationId"] = organizationId;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      if (userName !== undefined) {
        localVarQueryParameter["userName"] = userName;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (description !== undefined) {
        localVarQueryParameter["description"] = description;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get Organization History Summary matching specified search criteria.
     * @summary Get Organization History Summary
     * @param {number} accountId Account Id
     * @param {number} organizationId Organization Id
     * @param {number} offset How many items to skip
     * @param {number} limit How many items to return at one time
     * @param {number} [userId] User Id
     * @param {string} [userName] User Name (sql %like% operation is performed)
     * @param {string} [type] History Type
     * @param {string} [description] History Description (sql %like% operation is performed)
     * @param {number} [fromDate] From Date
     * @param {number} [toDate] To Date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationHistorySummaries: async (
      accountId: number,
      organizationId: number,
      offset: number,
      limit: number,
      userId?: number,
      userName?: string,
      type?: string,
      description?: string,
      fromDate?: number,
      toDate?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists("getOrganizationHistorySummaries", "accountId", accountId);
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getOrganizationHistorySummaries", "organizationId", organizationId);
      // verify required parameter 'offset' is not null or undefined
      assertParamExists("getOrganizationHistorySummaries", "offset", offset);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists("getOrganizationHistorySummaries", "limit", limit);
      const localVarPath = `/organization-history-summary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (accountId !== undefined) {
        localVarQueryParameter["accountId"] = accountId;
      }

      if (organizationId !== undefined) {
        localVarQueryParameter["organizationId"] = organizationId;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      if (userName !== undefined) {
        localVarQueryParameter["userName"] = userName;
      }

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (description !== undefined) {
        localVarQueryParameter["description"] = description;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HistorySummaryApi - functional programming interface
 * @export
 */
export const HistorySummaryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HistorySummaryApiAxiosParamCreator(configuration);
  return {
    /**
     * Get Account History Summary matching specified search criteria.
     * @summary Get Account History Summary
     * @param {number} accountId Account Id
     * @param {number} offset How many items to skip
     * @param {number} limit How many items to return at one time
     * @param {number} [organizationId] Organization Id
     * @param {number} [userId] User Id
     * @param {string} [userName] User Name (sql %like% operation is performed)
     * @param {string} [type] History Type
     * @param {string} [description] History Description (sql %like% operation is performed)
     * @param {number} [fromDate] From Date
     * @param {number} [toDate] To Date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccountHistorySummaries(
      accountId: number,
      offset: number,
      limit: number,
      organizationId?: number,
      userId?: number,
      userName?: string,
      type?: string,
      description?: string,
      fromDate?: number,
      toDate?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistorySummary>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountHistorySummaries(
        accountId,
        offset,
        limit,
        organizationId,
        userId,
        userName,
        type,
        description,
        fromDate,
        toDate,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get Organization History Summary matching specified search criteria.
     * @summary Get Organization History Summary
     * @param {number} accountId Account Id
     * @param {number} organizationId Organization Id
     * @param {number} offset How many items to skip
     * @param {number} limit How many items to return at one time
     * @param {number} [userId] User Id
     * @param {string} [userName] User Name (sql %like% operation is performed)
     * @param {string} [type] History Type
     * @param {string} [description] History Description (sql %like% operation is performed)
     * @param {number} [fromDate] From Date
     * @param {number} [toDate] To Date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationHistorySummaries(
      accountId: number,
      organizationId: number,
      offset: number,
      limit: number,
      userId?: number,
      userName?: string,
      type?: string,
      description?: string,
      fromDate?: number,
      toDate?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistorySummary>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationHistorySummaries(
        accountId,
        organizationId,
        offset,
        limit,
        userId,
        userName,
        type,
        description,
        fromDate,
        toDate,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * HistorySummaryApi - factory interface
 * @export
 */
export const HistorySummaryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HistorySummaryApiFp(configuration);
  return {
    /**
     * Get Account History Summary matching specified search criteria.
     * @summary Get Account History Summary
     * @param {number} accountId Account Id
     * @param {number} offset How many items to skip
     * @param {number} limit How many items to return at one time
     * @param {number} [organizationId] Organization Id
     * @param {number} [userId] User Id
     * @param {string} [userName] User Name (sql %like% operation is performed)
     * @param {string} [type] History Type
     * @param {string} [description] History Description (sql %like% operation is performed)
     * @param {number} [fromDate] From Date
     * @param {number} [toDate] To Date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountHistorySummaries(
      accountId: number,
      offset: number,
      limit: number,
      organizationId?: number,
      userId?: number,
      userName?: string,
      type?: string,
      description?: string,
      fromDate?: number,
      toDate?: number,
      options?: any,
    ): AxiosPromise<Array<HistorySummary>> {
      return localVarFp
        .getAccountHistorySummaries(
          accountId,
          offset,
          limit,
          organizationId,
          userId,
          userName,
          type,
          description,
          fromDate,
          toDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get Organization History Summary matching specified search criteria.
     * @summary Get Organization History Summary
     * @param {number} accountId Account Id
     * @param {number} organizationId Organization Id
     * @param {number} offset How many items to skip
     * @param {number} limit How many items to return at one time
     * @param {number} [userId] User Id
     * @param {string} [userName] User Name (sql %like% operation is performed)
     * @param {string} [type] History Type
     * @param {string} [description] History Description (sql %like% operation is performed)
     * @param {number} [fromDate] From Date
     * @param {number} [toDate] To Date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationHistorySummaries(
      accountId: number,
      organizationId: number,
      offset: number,
      limit: number,
      userId?: number,
      userName?: string,
      type?: string,
      description?: string,
      fromDate?: number,
      toDate?: number,
      options?: any,
    ): AxiosPromise<Array<HistorySummary>> {
      return localVarFp
        .getOrganizationHistorySummaries(
          accountId,
          organizationId,
          offset,
          limit,
          userId,
          userName,
          type,
          description,
          fromDate,
          toDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HistorySummaryApi - object-oriented interface
 * @export
 * @class HistorySummaryApi
 * @extends {BaseAPI}
 */
export class HistorySummaryApi extends BaseAPI {
  /**
   * Get Account History Summary matching specified search criteria.
   * @summary Get Account History Summary
   * @param {number} accountId Account Id
   * @param {number} offset How many items to skip
   * @param {number} limit How many items to return at one time
   * @param {number} [organizationId] Organization Id
   * @param {number} [userId] User Id
   * @param {string} [userName] User Name (sql %like% operation is performed)
   * @param {string} [type] History Type
   * @param {string} [description] History Description (sql %like% operation is performed)
   * @param {number} [fromDate] From Date
   * @param {number} [toDate] To Date
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HistorySummaryApi
   */
  public getAccountHistorySummaries(
    accountId: number,
    offset: number,
    limit: number,
    organizationId?: number,
    userId?: number,
    userName?: string,
    type?: string,
    description?: string,
    fromDate?: number,
    toDate?: number,
    options?: AxiosRequestConfig,
  ) {
    return HistorySummaryApiFp(this.configuration)
      .getAccountHistorySummaries(
        accountId,
        offset,
        limit,
        organizationId,
        userId,
        userName,
        type,
        description,
        fromDate,
        toDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get Organization History Summary matching specified search criteria.
   * @summary Get Organization History Summary
   * @param {number} accountId Account Id
   * @param {number} organizationId Organization Id
   * @param {number} offset How many items to skip
   * @param {number} limit How many items to return at one time
   * @param {number} [userId] User Id
   * @param {string} [userName] User Name (sql %like% operation is performed)
   * @param {string} [type] History Type
   * @param {string} [description] History Description (sql %like% operation is performed)
   * @param {number} [fromDate] From Date
   * @param {number} [toDate] To Date
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HistorySummaryApi
   */
  public getOrganizationHistorySummaries(
    accountId: number,
    organizationId: number,
    offset: number,
    limit: number,
    userId?: number,
    userName?: string,
    type?: string,
    description?: string,
    fromDate?: number,
    toDate?: number,
    options?: AxiosRequestConfig,
  ) {
    return HistorySummaryApiFp(this.configuration)
      .getOrganizationHistorySummaries(
        accountId,
        organizationId,
        offset,
        limit,
        userId,
        userName,
        type,
        description,
        fromDate,
        toDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MessagingApi - axios parameter creator
 * @export
 */
export const MessagingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Sends a message
     * @summary Send a message
     * @param {SendMessageData} sendMessageData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMessage: async (sendMessageData: SendMessageData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'sendMessageData' is not null or undefined
      assertParamExists("sendMessage", "sendMessageData", sendMessageData);
      const localVarPath = `/messaging`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(sendMessageData, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MessagingApi - functional programming interface
 * @export
 */
export const MessagingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MessagingApiAxiosParamCreator(configuration);
  return {
    /**
     * Sends a message
     * @summary Send a message
     * @param {SendMessageData} sendMessageData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendMessage(
      sendMessageData: SendMessageData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean }>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendMessage(sendMessageData, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * MessagingApi - factory interface
 * @export
 */
export const MessagingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = MessagingApiFp(configuration);
  return {
    /**
     * Sends a message
     * @summary Send a message
     * @param {SendMessageData} sendMessageData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMessage(sendMessageData: SendMessageData, options?: any): AxiosPromise<{ [key: string]: boolean }> {
      return localVarFp.sendMessage(sendMessageData, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * MessagingApi - object-oriented interface
 * @export
 * @class MessagingApi
 * @extends {BaseAPI}
 */
export class MessagingApi extends BaseAPI {
  /**
   * Sends a message
   * @summary Send a message
   * @param {SendMessageData} sendMessageData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagingApi
   */
  public sendMessage(sendMessageData: SendMessageData, options?: AxiosRequestConfig) {
    return MessagingApiFp(this.configuration)
      .sendMessage(sendMessageData, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Delete an existing organization
     * @summary Delete Organization
     * @param {number} organizationId Organization Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrganization: async (organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("deleteOrganization", "organizationId", organizationId);
      const localVarPath = `/organization/{organizationId}`.replace(
        `{${"organizationId"}}`,
        encodeURIComponent(String(organizationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Finds an existing Organization by Id.
     * @summary Finds Organization by Id
     * @param {number} organizationId Organization Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganization: async (organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getOrganization", "organizationId", organizationId);
      const localVarPath = `/organization/{organizationId}`.replace(
        `{${"organizationId"}}`,
        encodeURIComponent(String(organizationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve a list of Organizations matching specified search criteria.
     * @summary Retrieve organizations based on various parameters.
     * @param {number} [accountId] Account Id
     * @param {Array<number>} [organizationId] Organization Id
     * @param {Array<'PENDING_ACTIVATION' | 'PENDING_CHANGES' | 'ACTIVE' | 'DELETED' | 'LOCKED'>} [status] Status
     * @param {number} [offset] Sets the number records that are skipped from the beginning of the search result.
     * @param {number} [limit] The maximum number of records to be returned at once.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizations: async (
      accountId?: number,
      organizationId?: Array<number>,
      status?: Array<"PENDING_ACTIVATION" | "PENDING_CHANGES" | "ACTIVE" | "DELETED" | "LOCKED">,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/organizations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (accountId !== undefined) {
        localVarQueryParameter["accountId"] = accountId;
      }

      if (organizationId) {
        localVarQueryParameter["organizationId"] = organizationId;
      }

      if (status) {
        localVarQueryParameter["status"] = status;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update an existing organization
     * @summary Update Organization
     * @param {number} organizationId Organization Id
     * @param {OrganizationRequest} organizationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganization: async (
      organizationId: number,
      organizationRequest: OrganizationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateOrganization", "organizationId", organizationId);
      // verify required parameter 'organizationRequest' is not null or undefined
      assertParamExists("updateOrganization", "organizationRequest", organizationRequest);
      const localVarPath = `/organization/{organizationId}`.replace(
        `{${"organizationId"}}`,
        encodeURIComponent(String(organizationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(organizationRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration);
  return {
    /**
     * Delete an existing organization
     * @summary Delete Organization
     * @param {number} organizationId Organization Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOrganization(
      organizationId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganization(organizationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Finds an existing Organization by Id.
     * @summary Finds Organization by Id
     * @param {number} organizationId Organization Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganization(
      organizationId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganization(organizationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Retrieve a list of Organizations matching specified search criteria.
     * @summary Retrieve organizations based on various parameters.
     * @param {number} [accountId] Account Id
     * @param {Array<number>} [organizationId] Organization Id
     * @param {Array<'PENDING_ACTIVATION' | 'PENDING_CHANGES' | 'ACTIVE' | 'DELETED' | 'LOCKED'>} [status] Status
     * @param {number} [offset] Sets the number records that are skipped from the beginning of the search result.
     * @param {number} [limit] The maximum number of records to be returned at once.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizations(
      accountId?: number,
      organizationId?: Array<number>,
      status?: Array<"PENDING_ACTIVATION" | "PENDING_CHANGES" | "ACTIVE" | "DELETED" | "LOCKED">,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Organization>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizations(
        accountId,
        organizationId,
        status,
        offset,
        limit,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update an existing organization
     * @summary Update Organization
     * @param {number} organizationId Organization Id
     * @param {OrganizationRequest} organizationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateOrganization(
      organizationId: number,
      organizationRequest: OrganizationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganization(
        organizationId,
        organizationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrganizationApiFp(configuration);
  return {
    /**
     * Delete an existing organization
     * @summary Delete Organization
     * @param {number} organizationId Organization Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrganization(organizationId: number, options?: any): AxiosPromise<boolean> {
      return localVarFp.deleteOrganization(organizationId, options).then((request) => request(axios, basePath));
    },
    /**
     * Finds an existing Organization by Id.
     * @summary Finds Organization by Id
     * @param {number} organizationId Organization Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganization(organizationId: number, options?: any): AxiosPromise<Organization> {
      return localVarFp.getOrganization(organizationId, options).then((request) => request(axios, basePath));
    },
    /**
     * Retrieve a list of Organizations matching specified search criteria.
     * @summary Retrieve organizations based on various parameters.
     * @param {number} [accountId] Account Id
     * @param {Array<number>} [organizationId] Organization Id
     * @param {Array<'PENDING_ACTIVATION' | 'PENDING_CHANGES' | 'ACTIVE' | 'DELETED' | 'LOCKED'>} [status] Status
     * @param {number} [offset] Sets the number records that are skipped from the beginning of the search result.
     * @param {number} [limit] The maximum number of records to be returned at once.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizations(
      accountId?: number,
      organizationId?: Array<number>,
      status?: Array<"PENDING_ACTIVATION" | "PENDING_CHANGES" | "ACTIVE" | "DELETED" | "LOCKED">,
      offset?: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<Array<Organization>> {
      return localVarFp
        .getOrganizations(accountId, organizationId, status, offset, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update an existing organization
     * @summary Update Organization
     * @param {number} organizationId Organization Id
     * @param {OrganizationRequest} organizationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganization(
      organizationId: number,
      organizationRequest: OrganizationRequest,
      options?: any,
    ): AxiosPromise<Organization> {
      return localVarFp
        .updateOrganization(organizationId, organizationRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
  /**
   * Delete an existing organization
   * @summary Delete Organization
   * @param {number} organizationId Organization Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public deleteOrganization(organizationId: number, options?: AxiosRequestConfig) {
    return OrganizationApiFp(this.configuration)
      .deleteOrganization(organizationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Finds an existing Organization by Id.
   * @summary Finds Organization by Id
   * @param {number} organizationId Organization Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public getOrganization(organizationId: number, options?: AxiosRequestConfig) {
    return OrganizationApiFp(this.configuration)
      .getOrganization(organizationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve a list of Organizations matching specified search criteria.
   * @summary Retrieve organizations based on various parameters.
   * @param {number} [accountId] Account Id
   * @param {Array<number>} [organizationId] Organization Id
   * @param {Array<'PENDING_ACTIVATION' | 'PENDING_CHANGES' | 'ACTIVE' | 'DELETED' | 'LOCKED'>} [status] Status
   * @param {number} [offset] Sets the number records that are skipped from the beginning of the search result.
   * @param {number} [limit] The maximum number of records to be returned at once.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public getOrganizations(
    accountId?: number,
    organizationId?: Array<number>,
    status?: Array<"PENDING_ACTIVATION" | "PENDING_CHANGES" | "ACTIVE" | "DELETED" | "LOCKED">,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiFp(this.configuration)
      .getOrganizations(accountId, organizationId, status, offset, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update an existing organization
   * @summary Update Organization
   * @param {number} organizationId Organization Id
   * @param {OrganizationRequest} organizationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public updateOrganization(
    organizationId: number,
    organizationRequest: OrganizationRequest,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationApiFp(this.configuration)
      .updateOrganization(organizationId, organizationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PolicyApi - axios parameter creator
 * @export
 */
export const PolicyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Approve/Deny Policy
     * @summary Approve/Deny Policy
     * @param {PolicyAction} policyAction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyAction: async (policyAction: PolicyAction, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'policyAction' is not null or undefined
      assertParamExists("applyAction", "policyAction", policyAction);
      const localVarPath = `/action`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(policyAction, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a list of all Policy Actions matching specified search criteria.
     * @summary Get a list of all Policy Actions
     * @param {number} organizationId organizationId
     * @param {number} [userId] userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPolicySequences: async (
      organizationId: number,
      userId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getAllPolicySequences", "organizationId", organizationId);
      const localVarPath = `/action/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (organizationId !== undefined) {
        localVarQueryParameter["organizationId"] = organizationId;
      }

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a list of Policy Actions matching specified search criteria.
     * @summary Get a list of Policy Actions
     * @param {number} userId userId
     * @param {number} organizationId organizationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInitiatedPolicyActions: async (
      userId: number,
      organizationId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getInitiatedPolicyActions", "userId", userId);
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getInitiatedPolicyActions", "organizationId", organizationId);
      const localVarPath = `/action`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId !== undefined) {
        localVarQueryParameter["userId"] = userId;
      }

      if (organizationId !== undefined) {
        localVarQueryParameter["organizationId"] = organizationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PolicyApi - functional programming interface
 * @export
 */
export const PolicyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PolicyApiAxiosParamCreator(configuration);
  return {
    /**
     * Approve/Deny Policy
     * @summary Approve/Deny Policy
     * @param {PolicyAction} policyAction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyAction(
      policyAction: PolicyAction,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.applyAction(policyAction, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get a list of all Policy Actions matching specified search criteria.
     * @summary Get a list of all Policy Actions
     * @param {number} organizationId organizationId
     * @param {number} [userId] userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPolicySequences(
      organizationId: number,
      userId?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PolicyActionDetails>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPolicySequences(organizationId, userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get a list of Policy Actions matching specified search criteria.
     * @summary Get a list of Policy Actions
     * @param {number} userId userId
     * @param {number} organizationId organizationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInitiatedPolicyActions(
      userId: number,
      organizationId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PolicyActionDetails>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInitiatedPolicyActions(
        userId,
        organizationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PolicyApi - factory interface
 * @export
 */
export const PolicyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PolicyApiFp(configuration);
  return {
    /**
     * Approve/Deny Policy
     * @summary Approve/Deny Policy
     * @param {PolicyAction} policyAction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyAction(policyAction: PolicyAction, options?: any): AxiosPromise<boolean> {
      return localVarFp.applyAction(policyAction, options).then((request) => request(axios, basePath));
    },
    /**
     * Get a list of all Policy Actions matching specified search criteria.
     * @summary Get a list of all Policy Actions
     * @param {number} organizationId organizationId
     * @param {number} [userId] userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPolicySequences(
      organizationId: number,
      userId?: number,
      options?: any,
    ): AxiosPromise<Array<PolicyActionDetails>> {
      return localVarFp
        .getAllPolicySequences(organizationId, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a list of Policy Actions matching specified search criteria.
     * @summary Get a list of Policy Actions
     * @param {number} userId userId
     * @param {number} organizationId organizationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInitiatedPolicyActions(
      userId: number,
      organizationId: number,
      options?: any,
    ): AxiosPromise<Array<PolicyActionDetails>> {
      return localVarFp
        .getInitiatedPolicyActions(userId, organizationId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PolicyApi - object-oriented interface
 * @export
 * @class PolicyApi
 * @extends {BaseAPI}
 */
export class PolicyApi extends BaseAPI {
  /**
   * Approve/Deny Policy
   * @summary Approve/Deny Policy
   * @param {PolicyAction} policyAction
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PolicyApi
   */
  public applyAction(policyAction: PolicyAction, options?: AxiosRequestConfig) {
    return PolicyApiFp(this.configuration)
      .applyAction(policyAction, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a list of all Policy Actions matching specified search criteria.
   * @summary Get a list of all Policy Actions
   * @param {number} organizationId organizationId
   * @param {number} [userId] userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PolicyApi
   */
  public getAllPolicySequences(organizationId: number, userId?: number, options?: AxiosRequestConfig) {
    return PolicyApiFp(this.configuration)
      .getAllPolicySequences(organizationId, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a list of Policy Actions matching specified search criteria.
   * @summary Get a list of Policy Actions
   * @param {number} userId userId
   * @param {number} organizationId organizationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PolicyApi
   */
  public getInitiatedPolicyActions(userId: number, organizationId: number, options?: AxiosRequestConfig) {
    return PolicyApiFp(this.configuration)
      .getInitiatedPolicyActions(userId, organizationId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PriceApi - axios parameter creator
 * @export
 */
export const PriceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get current Price
     * @summary Get Price
     * @param {string} assetSymbol Asset Symbol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrice: async (assetSymbol: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'assetSymbol' is not null or undefined
      assertParamExists("getPrice", "assetSymbol", assetSymbol);
      const localVarPath = `/price/{assetSymbol}`.replace(
        `{${"assetSymbol"}}`,
        encodeURIComponent(String(assetSymbol)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all Prices within 24h
     * @summary Get all Prices within 24h
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/price`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PriceApi - functional programming interface
 * @export
 */
export const PriceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PriceApiAxiosParamCreator(configuration);
  return {
    /**
     * Get current Price
     * @summary Get Price
     * @param {string} assetSymbol Asset Symbol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPrice(
      assetSymbol: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceInfo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPrice(assetSymbol, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get all Prices within 24h
     * @summary Get all Prices within 24h
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPrices(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PriceInfo>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPrices(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PriceApi - factory interface
 * @export
 */
export const PriceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PriceApiFp(configuration);
  return {
    /**
     * Get current Price
     * @summary Get Price
     * @param {string} assetSymbol Asset Symbol
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrice(assetSymbol: string, options?: any): AxiosPromise<PriceInfo> {
      return localVarFp.getPrice(assetSymbol, options).then((request) => request(axios, basePath));
    },
    /**
     * Get all Prices within 24h
     * @summary Get all Prices within 24h
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrices(options?: any): AxiosPromise<Array<PriceInfo>> {
      return localVarFp.getPrices(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * PriceApi - object-oriented interface
 * @export
 * @class PriceApi
 * @extends {BaseAPI}
 */
export class PriceApi extends BaseAPI {
  /**
   * Get current Price
   * @summary Get Price
   * @param {string} assetSymbol Asset Symbol
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PriceApi
   */
  public getPrice(assetSymbol: string, options?: AxiosRequestConfig) {
    return PriceApiFp(this.configuration)
      .getPrice(assetSymbol, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all Prices within 24h
   * @summary Get all Prices within 24h
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PriceApi
   */
  public getPrices(options?: AxiosRequestConfig) {
    return PriceApiFp(this.configuration)
      .getPrices(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReconApi - axios parameter creator
 * @export
 */
export const ReconApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Retrieve list of an recon wallets
     * @summary Get a recon wallets
     * @param {number} [accountId] Account Id
     * @param {number} [organizationId] Organization Id
     * @param {string} [provider] Provider
     * @param {string} [assetSymbol] assetSymbol
     * @param {string} [network] Network
     * @param {string} [temperature] Status
     * @param {string} [walletStatus] Status
     * @param {string} [walletType] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReconWallets: async (
      accountId?: number,
      organizationId?: number,
      provider?: string,
      assetSymbol?: string,
      network?: string,
      temperature?: string,
      walletStatus?: string,
      walletType?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/recon-wallet`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (accountId !== undefined) {
        localVarQueryParameter["accountId"] = accountId;
      }

      if (organizationId !== undefined) {
        localVarQueryParameter["organizationId"] = organizationId;
      }

      if (provider !== undefined) {
        localVarQueryParameter["provider"] = provider;
      }

      if (assetSymbol !== undefined) {
        localVarQueryParameter["assetSymbol"] = assetSymbol;
      }

      if (network !== undefined) {
        localVarQueryParameter["network"] = network;
      }

      if (temperature !== undefined) {
        localVarQueryParameter["temperature"] = temperature;
      }

      if (walletStatus !== undefined) {
        localVarQueryParameter["walletStatus"] = walletStatus;
      }

      if (walletType !== undefined) {
        localVarQueryParameter["walletType"] = walletType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReconApi - functional programming interface
 * @export
 */
export const ReconApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReconApiAxiosParamCreator(configuration);
  return {
    /**
     * Retrieve list of an recon wallets
     * @summary Get a recon wallets
     * @param {number} [accountId] Account Id
     * @param {number} [organizationId] Organization Id
     * @param {string} [provider] Provider
     * @param {string} [assetSymbol] assetSymbol
     * @param {string} [network] Network
     * @param {string} [temperature] Status
     * @param {string} [walletStatus] Status
     * @param {string} [walletType] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReconWallets(
      accountId?: number,
      organizationId?: number,
      provider?: string,
      assetSymbol?: string,
      network?: string,
      temperature?: string,
      walletStatus?: string,
      walletType?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReconWallet>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReconWallets(
        accountId,
        organizationId,
        provider,
        assetSymbol,
        network,
        temperature,
        walletStatus,
        walletType,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReconApi - factory interface
 * @export
 */
export const ReconApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ReconApiFp(configuration);
  return {
    /**
     * Retrieve list of an recon wallets
     * @summary Get a recon wallets
     * @param {number} [accountId] Account Id
     * @param {number} [organizationId] Organization Id
     * @param {string} [provider] Provider
     * @param {string} [assetSymbol] assetSymbol
     * @param {string} [network] Network
     * @param {string} [temperature] Status
     * @param {string} [walletStatus] Status
     * @param {string} [walletType] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReconWallets(
      accountId?: number,
      organizationId?: number,
      provider?: string,
      assetSymbol?: string,
      network?: string,
      temperature?: string,
      walletStatus?: string,
      walletType?: string,
      options?: any,
    ): AxiosPromise<Array<ReconWallet>> {
      return localVarFp
        .getReconWallets(
          accountId,
          organizationId,
          provider,
          assetSymbol,
          network,
          temperature,
          walletStatus,
          walletType,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReconApi - object-oriented interface
 * @export
 * @class ReconApi
 * @extends {BaseAPI}
 */
export class ReconApi extends BaseAPI {
  /**
   * Retrieve list of an recon wallets
   * @summary Get a recon wallets
   * @param {number} [accountId] Account Id
   * @param {number} [organizationId] Organization Id
   * @param {string} [provider] Provider
   * @param {string} [assetSymbol] assetSymbol
   * @param {string} [network] Network
   * @param {string} [temperature] Status
   * @param {string} [walletStatus] Status
   * @param {string} [walletType] type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReconApi
   */
  public getReconWallets(
    accountId?: number,
    organizationId?: number,
    provider?: string,
    assetSymbol?: string,
    network?: string,
    temperature?: string,
    walletStatus?: string,
    walletType?: string,
    options?: AxiosRequestConfig,
  ) {
    return ReconApiFp(this.configuration)
      .getReconWallets(
        accountId,
        organizationId,
        provider,
        assetSymbol,
        network,
        temperature,
        walletStatus,
        walletType,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get last month Finance Report data
     * @summary Get last month Finance Report data
     * @param {number} [accountId] Account Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastMonthFinanceReport: async (accountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/report/financeReport`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (accountId !== undefined) {
        localVarQueryParameter["accountId"] = accountId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get last month Finance Report data for all accounts
     * @summary Get last month Finance Report data for all accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastMonthFinanceReportAllAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/report/financeReport/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration);
  return {
    /**
     * Get last month Finance Report data
     * @summary Get last month Finance Report data
     * @param {number} [accountId] Account Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLastMonthFinanceReport(
      accountId?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLastMonthFinanceReport(accountId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get last month Finance Report data for all accounts
     * @summary Get last month Finance Report data for all accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLastMonthFinanceReportAllAccounts(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<string>>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLastMonthFinanceReportAllAccounts(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ReportApiFp(configuration);
  return {
    /**
     * Get last month Finance Report data
     * @summary Get last month Finance Report data
     * @param {number} [accountId] Account Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastMonthFinanceReport(accountId?: number, options?: any): AxiosPromise<Array<string>> {
      return localVarFp.getLastMonthFinanceReport(accountId, options).then((request) => request(axios, basePath));
    },
    /**
     * Get last month Finance Report data for all accounts
     * @summary Get last month Finance Report data for all accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastMonthFinanceReportAllAccounts(options?: any): AxiosPromise<Array<Array<string>>> {
      return localVarFp.getLastMonthFinanceReportAllAccounts(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
  /**
   * Get last month Finance Report data
   * @summary Get last month Finance Report data
   * @param {number} [accountId] Account Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public getLastMonthFinanceReport(accountId?: number, options?: AxiosRequestConfig) {
    return ReportApiFp(this.configuration)
      .getLastMonthFinanceReport(accountId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get last month Finance Report data for all accounts
   * @summary Get last month Finance Report data for all accounts
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public getLastMonthFinanceReportAllAccounts(options?: AxiosRequestConfig) {
    return ReportApiFp(this.configuration)
      .getLastMonthFinanceReportAllAccounts(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create users in bulk
     * @summary Create Users
     * @param {Array<UserRequest>} userRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUsers: async (userRequest: Array<UserRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userRequest' is not null or undefined
      assertParamExists("createUsers", "userRequest", userRequest);
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete an existing user
     * @summary Delete User
     * @param {number} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("deleteUser", "userId", userId);
      const localVarPath = `/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve a User by User Id.
     * @summary Get a User by User Id
     * @param {number} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getUser", "userId", userId);
      const localVarPath = `/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve a User\'s active permissions across an Organization.
     * @summary Get User active permissions.
     * @param {number} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSessions: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getUserSessions", "userId", userId);
      const localVarPath = `/user/{userId}/session`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve a list of Users matching specified search criteria.
     * @summary Get a list of Users
     * @param {number} [accountId] Account Id
     * @param {Array<number>} [organizationId] Organization Id
     * @param {Array<number>} [userId] User Id
     * @param {Array<string>} [email] Email Id
     * @param {Array<'PENDING_ACTIVATION' | 'PENDING_CHANGES' | 'ACTIVE' | 'DELETED' | 'LOCKED'>} [status] Status
     * @param {Array<'TEAM_MANAGER'>} [accountPermission] Account Permission
     * @param {Array<'VIEW' | 'INITIATOR' | 'APPROVER' | 'VIDEO_AUTHORIZATION'>} [organizationPermission] Organization Permission
     * @param {Array<'CLIENT' | 'DA' | 'VIEW'>} [role] Role
     * @param {number} [offset] Sets the number records that are skipped from the beginning of the search result.
     * @param {number} [limit] The maximum number of records to be returned at once.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers: async (
      accountId?: number,
      organizationId?: Array<number>,
      userId?: Array<number>,
      email?: Array<string>,
      status?: Array<"PENDING_ACTIVATION" | "PENDING_CHANGES" | "ACTIVE" | "DELETED" | "LOCKED">,
      accountPermission?: Array<"TEAM_MANAGER">,
      organizationPermission?: Array<"VIEW" | "INITIATOR" | "APPROVER" | "VIDEO_AUTHORIZATION">,
      role?: Array<"CLIENT" | "DA" | "VIEW">,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (accountId !== undefined) {
        localVarQueryParameter["accountId"] = accountId;
      }

      if (organizationId) {
        localVarQueryParameter["organizationId"] = organizationId;
      }

      if (userId) {
        localVarQueryParameter["userId"] = userId;
      }

      if (email) {
        localVarQueryParameter["email"] = email;
      }

      if (status) {
        localVarQueryParameter["status"] = status;
      }

      if (accountPermission) {
        localVarQueryParameter["accountPermission"] = accountPermission;
      }

      if (organizationPermission) {
        localVarQueryParameter["organizationPermission"] = organizationPermission;
      }

      if (role) {
        localVarQueryParameter["role"] = role;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update an existing user
     * @summary Update User
     * @param {number} userId User Id
     * @param {UserRequest} userRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser: async (
      userId: number,
      userRequest: UserRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("updateUser", "userId", userId);
      // verify required parameter 'userRequest' is not null or undefined
      assertParamExists("updateUser", "userRequest", userRequest);
      const localVarPath = `/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     * Create users in bulk
     * @summary Create Users
     * @param {Array<UserRequest>} userRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUsers(
      userRequest: Array<UserRequest>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUsers(userRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete an existing user
     * @summary Delete User
     * @param {number} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUser(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Retrieve a User by User Id.
     * @summary Get a User by User Id
     * @param {number} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUser(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Retrieve a User\'s active permissions across an Organization.
     * @summary Get User active permissions.
     * @param {number} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserSessions(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSession>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSessions(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Retrieve a list of Users matching specified search criteria.
     * @summary Get a list of Users
     * @param {number} [accountId] Account Id
     * @param {Array<number>} [organizationId] Organization Id
     * @param {Array<number>} [userId] User Id
     * @param {Array<string>} [email] Email Id
     * @param {Array<'PENDING_ACTIVATION' | 'PENDING_CHANGES' | 'ACTIVE' | 'DELETED' | 'LOCKED'>} [status] Status
     * @param {Array<'TEAM_MANAGER'>} [accountPermission] Account Permission
     * @param {Array<'VIEW' | 'INITIATOR' | 'APPROVER' | 'VIDEO_AUTHORIZATION'>} [organizationPermission] Organization Permission
     * @param {Array<'CLIENT' | 'DA' | 'VIEW'>} [role] Role
     * @param {number} [offset] Sets the number records that are skipped from the beginning of the search result.
     * @param {number} [limit] The maximum number of records to be returned at once.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsers(
      accountId?: number,
      organizationId?: Array<number>,
      userId?: Array<number>,
      email?: Array<string>,
      status?: Array<"PENDING_ACTIVATION" | "PENDING_CHANGES" | "ACTIVE" | "DELETED" | "LOCKED">,
      accountPermission?: Array<"TEAM_MANAGER">,
      organizationPermission?: Array<"VIEW" | "INITIATOR" | "APPROVER" | "VIDEO_AUTHORIZATION">,
      role?: Array<"CLIENT" | "DA" | "VIEW">,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(
        accountId,
        organizationId,
        userId,
        email,
        status,
        accountPermission,
        organizationPermission,
        role,
        offset,
        limit,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update an existing user
     * @summary Update User
     * @param {number} userId User Id
     * @param {UserRequest} userRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      userId: number,
      userRequest: UserRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, userRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     * Create users in bulk
     * @summary Create Users
     * @param {Array<UserRequest>} userRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUsers(userRequest: Array<UserRequest>, options?: any): AxiosPromise<Array<User>> {
      return localVarFp.createUsers(userRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Delete an existing user
     * @summary Delete User
     * @param {number} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(userId: number, options?: any): AxiosPromise<boolean> {
      return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
    },
    /**
     * Retrieve a User by User Id.
     * @summary Get a User by User Id
     * @param {number} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser(userId: number, options?: any): AxiosPromise<User> {
      return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
    },
    /**
     * Retrieve a User\'s active permissions across an Organization.
     * @summary Get User active permissions.
     * @param {number} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSessions(userId: number, options?: any): AxiosPromise<UserSession> {
      return localVarFp.getUserSessions(userId, options).then((request) => request(axios, basePath));
    },
    /**
     * Retrieve a list of Users matching specified search criteria.
     * @summary Get a list of Users
     * @param {number} [accountId] Account Id
     * @param {Array<number>} [organizationId] Organization Id
     * @param {Array<number>} [userId] User Id
     * @param {Array<string>} [email] Email Id
     * @param {Array<'PENDING_ACTIVATION' | 'PENDING_CHANGES' | 'ACTIVE' | 'DELETED' | 'LOCKED'>} [status] Status
     * @param {Array<'TEAM_MANAGER'>} [accountPermission] Account Permission
     * @param {Array<'VIEW' | 'INITIATOR' | 'APPROVER' | 'VIDEO_AUTHORIZATION'>} [organizationPermission] Organization Permission
     * @param {Array<'CLIENT' | 'DA' | 'VIEW'>} [role] Role
     * @param {number} [offset] Sets the number records that are skipped from the beginning of the search result.
     * @param {number} [limit] The maximum number of records to be returned at once.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(
      accountId?: number,
      organizationId?: Array<number>,
      userId?: Array<number>,
      email?: Array<string>,
      status?: Array<"PENDING_ACTIVATION" | "PENDING_CHANGES" | "ACTIVE" | "DELETED" | "LOCKED">,
      accountPermission?: Array<"TEAM_MANAGER">,
      organizationPermission?: Array<"VIEW" | "INITIATOR" | "APPROVER" | "VIDEO_AUTHORIZATION">,
      role?: Array<"CLIENT" | "DA" | "VIEW">,
      offset?: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<Array<User>> {
      return localVarFp
        .getUsers(
          accountId,
          organizationId,
          userId,
          email,
          status,
          accountPermission,
          organizationPermission,
          role,
          offset,
          limit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update an existing user
     * @summary Update User
     * @param {number} userId User Id
     * @param {UserRequest} userRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(userId: number, userRequest: UserRequest, options?: any): AxiosPromise<User> {
      return localVarFp.updateUser(userId, userRequest, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   * Create users in bulk
   * @summary Create Users
   * @param {Array<UserRequest>} userRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public createUsers(userRequest: Array<UserRequest>, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .createUsers(userRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete an existing user
   * @summary Delete User
   * @param {number} userId User Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public deleteUser(userId: number, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .deleteUser(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve a User by User Id.
   * @summary Get a User by User Id
   * @param {number} userId User Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUser(userId: number, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .getUser(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve a User\'s active permissions across an Organization.
   * @summary Get User active permissions.
   * @param {number} userId User Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUserSessions(userId: number, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .getUserSessions(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve a list of Users matching specified search criteria.
   * @summary Get a list of Users
   * @param {number} [accountId] Account Id
   * @param {Array<number>} [organizationId] Organization Id
   * @param {Array<number>} [userId] User Id
   * @param {Array<string>} [email] Email Id
   * @param {Array<'PENDING_ACTIVATION' | 'PENDING_CHANGES' | 'ACTIVE' | 'DELETED' | 'LOCKED'>} [status] Status
   * @param {Array<'TEAM_MANAGER'>} [accountPermission] Account Permission
   * @param {Array<'VIEW' | 'INITIATOR' | 'APPROVER' | 'VIDEO_AUTHORIZATION'>} [organizationPermission] Organization Permission
   * @param {Array<'CLIENT' | 'DA' | 'VIEW'>} [role] Role
   * @param {number} [offset] Sets the number records that are skipped from the beginning of the search result.
   * @param {number} [limit] The maximum number of records to be returned at once.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public getUsers(
    accountId?: number,
    organizationId?: Array<number>,
    userId?: Array<number>,
    email?: Array<string>,
    status?: Array<"PENDING_ACTIVATION" | "PENDING_CHANGES" | "ACTIVE" | "DELETED" | "LOCKED">,
    accountPermission?: Array<"TEAM_MANAGER">,
    organizationPermission?: Array<"VIEW" | "INITIATOR" | "APPROVER" | "VIDEO_AUTHORIZATION">,
    role?: Array<"CLIENT" | "DA" | "VIEW">,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return UserApiFp(this.configuration)
      .getUsers(
        accountId,
        organizationId,
        userId,
        email,
        status,
        accountPermission,
        organizationPermission,
        role,
        offset,
        limit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update an existing user
   * @summary Update User
   * @param {number} userId User Id
   * @param {UserRequest} userRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public updateUser(userId: number, userRequest: UserRequest, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .updateUser(userId, userRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WalletApi - axios parameter creator
 * @export
 */
export const WalletApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * ## Creates a new wallet, you can create up to 5 wallets per temperature per asset. The following are the fields and their description.   **accountId**: The account the wallet belongs to.   **organizationId**: The organization the wallet belongs to.   **assetSymbol**: The Asset symbol, currently supported assets are [BTC, ETH].   **network**: The blockchain where we want to create the wallet in, currently Supported networks are [Bitcoin, Ethereum].   **name**: The name you would like to give to the wallet.   **description**: (optional) This optional field is for adding a description to your wallet.   **temperature**: Cold (by default its value is Cold).
     * @summary Create a new wallet
     * @param {CreateWalletRequest} createWalletRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWallet: async (
      createWalletRequest: CreateWalletRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createWalletRequest' is not null or undefined
      assertParamExists("createWallet", "createWalletRequest", createWalletRequest);
      const localVarPath = `/wallet`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createWalletRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve a Wallet matching the Wallet Id.
     * @summary Finds a Wallet based on Wallet Id
     * @param {number} walletId Wallet Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWalletById: async (walletId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'walletId' is not null or undefined
      assertParamExists("getWalletById", "walletId", walletId);
      const localVarPath = `/wallet/{walletId}`.replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve a list of wallets matching specified search criteria.
     * @summary Retrieve wallets based on any of the search criteria below
     * @param {number} [accountId] The Account the Wallet belongs to.
     * @param {number} [organizationId] The Organization the Wallet belongs to
     * @param {string} [provider] The external Provider(e.g Fireblocks) where the Wallet resides
     * @param {string} [assetSymbol] The asset of the asset.
     * @param {'Cold' | 'Warm'} [temperature] The temperature of the Wallet
     * @param {'Pending' | 'Active' | 'Disabled'} [walletStatus] The current status of the Wallet
     * @param {string} [walletType] The type of the Wallet
     * @param {string} [isQuarantine] If the Wallet is currently quarantined or not
     * @param {string} [correlationId] The external correlationId that we get from our provider(e.g Fireblocks)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallets: async (
      accountId?: number,
      organizationId?: number,
      provider?: string,
      assetSymbol?: string,
      temperature?: "Cold" | "Warm",
      walletStatus?: "Pending" | "Active" | "Disabled",
      walletType?: string,
      isQuarantine?: string,
      correlationId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/wallet`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (accountId !== undefined) {
        localVarQueryParameter["accountId"] = accountId;
      }

      if (organizationId !== undefined) {
        localVarQueryParameter["organizationId"] = organizationId;
      }

      if (provider !== undefined) {
        localVarQueryParameter["provider"] = provider;
      }

      if (assetSymbol !== undefined) {
        localVarQueryParameter["assetSymbol"] = assetSymbol;
      }

      if (temperature !== undefined) {
        localVarQueryParameter["temperature"] = temperature;
      }

      if (walletStatus !== undefined) {
        localVarQueryParameter["walletStatus"] = walletStatus;
      }

      if (walletType !== undefined) {
        localVarQueryParameter["walletType"] = walletType;
      }

      if (isQuarantine !== undefined) {
        localVarQueryParameter["isQuarantine"] = isQuarantine;
      }

      if (correlationId !== undefined) {
        localVarQueryParameter["correlationId"] = correlationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update a wallet by id
     * @summary Update a wallet by id
     * @param {number} walletId Wallet Id
     * @param {UpdateWallet} updateWallet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWalletById: async (
      walletId: number,
      updateWallet: UpdateWallet,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'walletId' is not null or undefined
      assertParamExists("updateWalletById", "walletId", walletId);
      // verify required parameter 'updateWallet' is not null or undefined
      assertParamExists("updateWalletById", "updateWallet", updateWallet);
      const localVarPath = `/wallet/{walletId}`.replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateWallet, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WalletApi - functional programming interface
 * @export
 */
export const WalletApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WalletApiAxiosParamCreator(configuration);
  return {
    /**
     * ## Creates a new wallet, you can create up to 5 wallets per temperature per asset. The following are the fields and their description.   **accountId**: The account the wallet belongs to.   **organizationId**: The organization the wallet belongs to.   **assetSymbol**: The Asset symbol, currently supported assets are [BTC, ETH].   **network**: The blockchain where we want to create the wallet in, currently Supported networks are [Bitcoin, Ethereum].   **name**: The name you would like to give to the wallet.   **description**: (optional) This optional field is for adding a description to your wallet.   **temperature**: Cold (by default its value is Cold).
     * @summary Create a new wallet
     * @param {CreateWalletRequest} createWalletRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createWallet(
      createWalletRequest: CreateWalletRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createWallet(createWalletRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Retrieve a Wallet matching the Wallet Id.
     * @summary Finds a Wallet based on Wallet Id
     * @param {number} walletId Wallet Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWalletById(
      walletId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wallet>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletById(walletId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Retrieve a list of wallets matching specified search criteria.
     * @summary Retrieve wallets based on any of the search criteria below
     * @param {number} [accountId] The Account the Wallet belongs to.
     * @param {number} [organizationId] The Organization the Wallet belongs to
     * @param {string} [provider] The external Provider(e.g Fireblocks) where the Wallet resides
     * @param {string} [assetSymbol] The asset of the asset.
     * @param {'Cold' | 'Warm'} [temperature] The temperature of the Wallet
     * @param {'Pending' | 'Active' | 'Disabled'} [walletStatus] The current status of the Wallet
     * @param {string} [walletType] The type of the Wallet
     * @param {string} [isQuarantine] If the Wallet is currently quarantined or not
     * @param {string} [correlationId] The external correlationId that we get from our provider(e.g Fireblocks)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWallets(
      accountId?: number,
      organizationId?: number,
      provider?: string,
      assetSymbol?: string,
      temperature?: "Cold" | "Warm",
      walletStatus?: "Pending" | "Active" | "Disabled",
      walletType?: string,
      isQuarantine?: string,
      correlationId?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Wallet>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWallets(
        accountId,
        organizationId,
        provider,
        assetSymbol,
        temperature,
        walletStatus,
        walletType,
        isQuarantine,
        correlationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update a wallet by id
     * @summary Update a wallet by id
     * @param {number} walletId Wallet Id
     * @param {UpdateWallet} updateWallet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateWalletById(
      walletId: number,
      updateWallet: UpdateWallet,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wallet>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateWalletById(walletId, updateWallet, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * WalletApi - factory interface
 * @export
 */
export const WalletApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = WalletApiFp(configuration);
  return {
    /**
     * ## Creates a new wallet, you can create up to 5 wallets per temperature per asset. The following are the fields and their description.   **accountId**: The account the wallet belongs to.   **organizationId**: The organization the wallet belongs to.   **assetSymbol**: The Asset symbol, currently supported assets are [BTC, ETH].   **network**: The blockchain where we want to create the wallet in, currently Supported networks are [Bitcoin, Ethereum].   **name**: The name you would like to give to the wallet.   **description**: (optional) This optional field is for adding a description to your wallet.   **temperature**: Cold (by default its value is Cold).
     * @summary Create a new wallet
     * @param {CreateWalletRequest} createWalletRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWallet(createWalletRequest: CreateWalletRequest, options?: any): AxiosPromise<void> {
      return localVarFp.createWallet(createWalletRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Retrieve a Wallet matching the Wallet Id.
     * @summary Finds a Wallet based on Wallet Id
     * @param {number} walletId Wallet Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWalletById(walletId: number, options?: any): AxiosPromise<Wallet> {
      return localVarFp.getWalletById(walletId, options).then((request) => request(axios, basePath));
    },
    /**
     * Retrieve a list of wallets matching specified search criteria.
     * @summary Retrieve wallets based on any of the search criteria below
     * @param {number} [accountId] The Account the Wallet belongs to.
     * @param {number} [organizationId] The Organization the Wallet belongs to
     * @param {string} [provider] The external Provider(e.g Fireblocks) where the Wallet resides
     * @param {string} [assetSymbol] The asset of the asset.
     * @param {'Cold' | 'Warm'} [temperature] The temperature of the Wallet
     * @param {'Pending' | 'Active' | 'Disabled'} [walletStatus] The current status of the Wallet
     * @param {string} [walletType] The type of the Wallet
     * @param {string} [isQuarantine] If the Wallet is currently quarantined or not
     * @param {string} [correlationId] The external correlationId that we get from our provider(e.g Fireblocks)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWallets(
      accountId?: number,
      organizationId?: number,
      provider?: string,
      assetSymbol?: string,
      temperature?: "Cold" | "Warm",
      walletStatus?: "Pending" | "Active" | "Disabled",
      walletType?: string,
      isQuarantine?: string,
      correlationId?: string,
      options?: any,
    ): AxiosPromise<Array<Wallet>> {
      return localVarFp
        .getWallets(
          accountId,
          organizationId,
          provider,
          assetSymbol,
          temperature,
          walletStatus,
          walletType,
          isQuarantine,
          correlationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update a wallet by id
     * @summary Update a wallet by id
     * @param {number} walletId Wallet Id
     * @param {UpdateWallet} updateWallet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWalletById(walletId: number, updateWallet: UpdateWallet, options?: any): AxiosPromise<Wallet> {
      return localVarFp.updateWalletById(walletId, updateWallet, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * WalletApi - object-oriented interface
 * @export
 * @class WalletApi
 * @extends {BaseAPI}
 */
export class WalletApi extends BaseAPI {
  /**
   * ## Creates a new wallet, you can create up to 5 wallets per temperature per asset. The following are the fields and their description.   **accountId**: The account the wallet belongs to.   **organizationId**: The organization the wallet belongs to.   **assetSymbol**: The Asset symbol, currently supported assets are [BTC, ETH].   **network**: The blockchain where we want to create the wallet in, currently Supported networks are [Bitcoin, Ethereum].   **name**: The name you would like to give to the wallet.   **description**: (optional) This optional field is for adding a description to your wallet.   **temperature**: Cold (by default its value is Cold).
   * @summary Create a new wallet
   * @param {CreateWalletRequest} createWalletRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletApi
   */
  public createWallet(createWalletRequest: CreateWalletRequest, options?: AxiosRequestConfig) {
    return WalletApiFp(this.configuration)
      .createWallet(createWalletRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve a Wallet matching the Wallet Id.
   * @summary Finds a Wallet based on Wallet Id
   * @param {number} walletId Wallet Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletApi
   */
  public getWalletById(walletId: number, options?: AxiosRequestConfig) {
    return WalletApiFp(this.configuration)
      .getWalletById(walletId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve a list of wallets matching specified search criteria.
   * @summary Retrieve wallets based on any of the search criteria below
   * @param {number} [accountId] The Account the Wallet belongs to.
   * @param {number} [organizationId] The Organization the Wallet belongs to
   * @param {string} [provider] The external Provider(e.g Fireblocks) where the Wallet resides
   * @param {string} [assetSymbol] The asset of the asset.
   * @param {'Cold' | 'Warm'} [temperature] The temperature of the Wallet
   * @param {'Pending' | 'Active' | 'Disabled'} [walletStatus] The current status of the Wallet
   * @param {string} [walletType] The type of the Wallet
   * @param {string} [isQuarantine] If the Wallet is currently quarantined or not
   * @param {string} [correlationId] The external correlationId that we get from our provider(e.g Fireblocks)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletApi
   */
  public getWallets(
    accountId?: number,
    organizationId?: number,
    provider?: string,
    assetSymbol?: string,
    temperature?: "Cold" | "Warm",
    walletStatus?: "Pending" | "Active" | "Disabled",
    walletType?: string,
    isQuarantine?: string,
    correlationId?: string,
    options?: AxiosRequestConfig,
  ) {
    return WalletApiFp(this.configuration)
      .getWallets(
        accountId,
        organizationId,
        provider,
        assetSymbol,
        temperature,
        walletStatus,
        walletType,
        isQuarantine,
        correlationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update a wallet by id
   * @summary Update a wallet by id
   * @param {number} walletId Wallet Id
   * @param {UpdateWallet} updateWallet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletApi
   */
  public updateWalletById(walletId: number, updateWallet: UpdateWallet, options?: AxiosRequestConfig) {
    return WalletApiFp(this.configuration)
      .updateWalletById(walletId, updateWallet, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WalletTransactionApi - axios parameter creator
 * @export
 */
export const WalletTransactionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create transaction
     * @summary Create Transaction
     * @param {CreateTransactionRequest} createTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransaction: async (
      createTransactionRequest: CreateTransactionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createTransactionRequest' is not null or undefined
      assertParamExists("createTransaction", "createTransactionRequest", createTransactionRequest);
      const localVarPath = `/wallet/transaction`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTransactionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get estimate fee
     * @summary Get estimate fee
     * @param {number} quantity quantity
     * @param {number} sourceWalletId sourceWalletId
     * @param {string} destinationAddress destinationAddress
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEstimateFee: async (
      quantity: number,
      sourceWalletId: number,
      destinationAddress: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'quantity' is not null or undefined
      assertParamExists("getEstimateFee", "quantity", quantity);
      // verify required parameter 'sourceWalletId' is not null or undefined
      assertParamExists("getEstimateFee", "sourceWalletId", sourceWalletId);
      // verify required parameter 'destinationAddress' is not null or undefined
      assertParamExists("getEstimateFee", "destinationAddress", destinationAddress);
      const localVarPath = `/wallet/transaction/estimate-fee`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (quantity !== undefined) {
        localVarQueryParameter["quantity"] = quantity;
      }

      if (sourceWalletId !== undefined) {
        localVarQueryParameter["sourceWalletId"] = sourceWalletId;
      }

      if (destinationAddress !== undefined) {
        localVarQueryParameter["destinationAddress"] = destinationAddress;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve a Wallet Transaction matching specified search criteria.
     * @summary Finds wallet transactions based on specified search criteria
     * @param {number} walletTransactionId Wallet Transaction Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWalletTransactionById: async (
      walletTransactionId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'walletTransactionId' is not null or undefined
      assertParamExists("getWalletTransactionById", "walletTransactionId", walletTransactionId);
      const localVarPath = `/wallet/transaction/{walletTransactionId}`.replace(
        `{${"walletTransactionId"}}`,
        encodeURIComponent(String(walletTransactionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a wallet transaction by id with review details.
     * @summary Get a wallet transaction by id with review details
     * @param {number} walletTransactionId Wallet Transaction Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWalletTransactionByIdReview: async (
      walletTransactionId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'walletTransactionId' is not null or undefined
      assertParamExists("getWalletTransactionByIdReview", "walletTransactionId", walletTransactionId);
      const localVarPath = `/wallet/transaction/{walletTransactionId}/review`.replace(
        `{${"walletTransactionId"}}`,
        encodeURIComponent(String(walletTransactionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve a list of Wallet Transactions matching specified search criteria.
     * @summary Retrieve wallet transactions based on any of the search criteria below
     * @param {number} [walletId] The Wallet Id which is part of the Transaction
     * @param {number} [organizationId] The Organization Id the wallet belongs to
     * @param {number} [blockHeight] The number of blocks that have been confirmed in the specific blockchain
     * @param {'INITIATED' | 'PENDING' | 'COMPLETED' | 'CANCELLED' | 'QUARANTINEFLAGGED' | 'QUARANTINEDFUNDS' | 'FAILED' | 'SIGNING' | 'CONFIRMING'} [status] Wallet Transaction Status
     * @param {string} [correlationId] The external transaction Id that we get from our provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWalletTransactions: async (
      walletId?: number,
      organizationId?: number,
      blockHeight?: number,
      status?:
        | "INITIATED"
        | "PENDING"
        | "COMPLETED"
        | "CANCELLED"
        | "QUARANTINEFLAGGED"
        | "QUARANTINEDFUNDS"
        | "FAILED"
        | "SIGNING"
        | "CONFIRMING",
      correlationId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/wallet/transaction`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (walletId !== undefined) {
        localVarQueryParameter["walletId"] = walletId;
      }

      if (organizationId !== undefined) {
        localVarQueryParameter["organizationId"] = organizationId;
      }

      if (blockHeight !== undefined) {
        localVarQueryParameter["blockHeight"] = blockHeight;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (correlationId !== undefined) {
        localVarQueryParameter["correlationId"] = correlationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WalletTransactionApi - functional programming interface
 * @export
 */
export const WalletTransactionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WalletTransactionApiAxiosParamCreator(configuration);
  return {
    /**
     * Create transaction
     * @summary Create Transaction
     * @param {CreateTransactionRequest} createTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTransaction(
      createTransactionRequest: CreateTransactionRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createTransaction(createTransactionRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get estimate fee
     * @summary Get estimate fee
     * @param {number} quantity quantity
     * @param {number} sourceWalletId sourceWalletId
     * @param {string} destinationAddress destinationAddress
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEstimateFee(
      quantity: number,
      sourceWalletId: number,
      destinationAddress: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateFee>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEstimateFee(
        quantity,
        sourceWalletId,
        destinationAddress,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Retrieve a Wallet Transaction matching specified search criteria.
     * @summary Finds wallet transactions based on specified search criteria
     * @param {number} walletTransactionId Wallet Transaction Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWalletTransactionById(
      walletTransactionId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletTransaction>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletTransactionById(walletTransactionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get a wallet transaction by id with review details.
     * @summary Get a wallet transaction by id with review details
     * @param {number} walletTransactionId Wallet Transaction Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWalletTransactionByIdReview(
      walletTransactionId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletTransactionReview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletTransactionByIdReview(
        walletTransactionId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Retrieve a list of Wallet Transactions matching specified search criteria.
     * @summary Retrieve wallet transactions based on any of the search criteria below
     * @param {number} [walletId] The Wallet Id which is part of the Transaction
     * @param {number} [organizationId] The Organization Id the wallet belongs to
     * @param {number} [blockHeight] The number of blocks that have been confirmed in the specific blockchain
     * @param {'INITIATED' | 'PENDING' | 'COMPLETED' | 'CANCELLED' | 'QUARANTINEFLAGGED' | 'QUARANTINEDFUNDS' | 'FAILED' | 'SIGNING' | 'CONFIRMING'} [status] Wallet Transaction Status
     * @param {string} [correlationId] The external transaction Id that we get from our provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWalletTransactions(
      walletId?: number,
      organizationId?: number,
      blockHeight?: number,
      status?:
        | "INITIATED"
        | "PENDING"
        | "COMPLETED"
        | "CANCELLED"
        | "QUARANTINEFLAGGED"
        | "QUARANTINEDFUNDS"
        | "FAILED"
        | "SIGNING"
        | "CONFIRMING",
      correlationId?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WalletTransaction>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletTransactions(
        walletId,
        organizationId,
        blockHeight,
        status,
        correlationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * WalletTransactionApi - factory interface
 * @export
 */
export const WalletTransactionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WalletTransactionApiFp(configuration);
  return {
    /**
     * Create transaction
     * @summary Create Transaction
     * @param {CreateTransactionRequest} createTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransaction(createTransactionRequest: CreateTransactionRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .createTransaction(createTransactionRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get estimate fee
     * @summary Get estimate fee
     * @param {number} quantity quantity
     * @param {number} sourceWalletId sourceWalletId
     * @param {string} destinationAddress destinationAddress
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEstimateFee(
      quantity: number,
      sourceWalletId: number,
      destinationAddress: string,
      options?: any,
    ): AxiosPromise<EstimateFee> {
      return localVarFp
        .getEstimateFee(quantity, sourceWalletId, destinationAddress, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieve a Wallet Transaction matching specified search criteria.
     * @summary Finds wallet transactions based on specified search criteria
     * @param {number} walletTransactionId Wallet Transaction Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWalletTransactionById(walletTransactionId: number, options?: any): AxiosPromise<WalletTransaction> {
      return localVarFp
        .getWalletTransactionById(walletTransactionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a wallet transaction by id with review details.
     * @summary Get a wallet transaction by id with review details
     * @param {number} walletTransactionId Wallet Transaction Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWalletTransactionByIdReview(walletTransactionId: number, options?: any): AxiosPromise<WalletTransactionReview> {
      return localVarFp
        .getWalletTransactionByIdReview(walletTransactionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieve a list of Wallet Transactions matching specified search criteria.
     * @summary Retrieve wallet transactions based on any of the search criteria below
     * @param {number} [walletId] The Wallet Id which is part of the Transaction
     * @param {number} [organizationId] The Organization Id the wallet belongs to
     * @param {number} [blockHeight] The number of blocks that have been confirmed in the specific blockchain
     * @param {'INITIATED' | 'PENDING' | 'COMPLETED' | 'CANCELLED' | 'QUARANTINEFLAGGED' | 'QUARANTINEDFUNDS' | 'FAILED' | 'SIGNING' | 'CONFIRMING'} [status] Wallet Transaction Status
     * @param {string} [correlationId] The external transaction Id that we get from our provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWalletTransactions(
      walletId?: number,
      organizationId?: number,
      blockHeight?: number,
      status?:
        | "INITIATED"
        | "PENDING"
        | "COMPLETED"
        | "CANCELLED"
        | "QUARANTINEFLAGGED"
        | "QUARANTINEDFUNDS"
        | "FAILED"
        | "SIGNING"
        | "CONFIRMING",
      correlationId?: string,
      options?: any,
    ): AxiosPromise<Array<WalletTransaction>> {
      return localVarFp
        .getWalletTransactions(walletId, organizationId, blockHeight, status, correlationId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * WalletTransactionApi - object-oriented interface
 * @export
 * @class WalletTransactionApi
 * @extends {BaseAPI}
 */
export class WalletTransactionApi extends BaseAPI {
  /**
   * Create transaction
   * @summary Create Transaction
   * @param {CreateTransactionRequest} createTransactionRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletTransactionApi
   */
  public createTransaction(createTransactionRequest: CreateTransactionRequest, options?: AxiosRequestConfig) {
    return WalletTransactionApiFp(this.configuration)
      .createTransaction(createTransactionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get estimate fee
   * @summary Get estimate fee
   * @param {number} quantity quantity
   * @param {number} sourceWalletId sourceWalletId
   * @param {string} destinationAddress destinationAddress
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletTransactionApi
   */
  public getEstimateFee(
    quantity: number,
    sourceWalletId: number,
    destinationAddress: string,
    options?: AxiosRequestConfig,
  ) {
    return WalletTransactionApiFp(this.configuration)
      .getEstimateFee(quantity, sourceWalletId, destinationAddress, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve a Wallet Transaction matching specified search criteria.
   * @summary Finds wallet transactions based on specified search criteria
   * @param {number} walletTransactionId Wallet Transaction Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletTransactionApi
   */
  public getWalletTransactionById(walletTransactionId: number, options?: AxiosRequestConfig) {
    return WalletTransactionApiFp(this.configuration)
      .getWalletTransactionById(walletTransactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a wallet transaction by id with review details.
   * @summary Get a wallet transaction by id with review details
   * @param {number} walletTransactionId Wallet Transaction Id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletTransactionApi
   */
  public getWalletTransactionByIdReview(walletTransactionId: number, options?: AxiosRequestConfig) {
    return WalletTransactionApiFp(this.configuration)
      .getWalletTransactionByIdReview(walletTransactionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve a list of Wallet Transactions matching specified search criteria.
   * @summary Retrieve wallet transactions based on any of the search criteria below
   * @param {number} [walletId] The Wallet Id which is part of the Transaction
   * @param {number} [organizationId] The Organization Id the wallet belongs to
   * @param {number} [blockHeight] The number of blocks that have been confirmed in the specific blockchain
   * @param {'INITIATED' | 'PENDING' | 'COMPLETED' | 'CANCELLED' | 'QUARANTINEFLAGGED' | 'QUARANTINEDFUNDS' | 'FAILED' | 'SIGNING' | 'CONFIRMING'} [status] Wallet Transaction Status
   * @param {string} [correlationId] The external transaction Id that we get from our provider
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletTransactionApi
   */
  public getWalletTransactions(
    walletId?: number,
    organizationId?: number,
    blockHeight?: number,
    status?:
      | "INITIATED"
      | "PENDING"
      | "COMPLETED"
      | "CANCELLED"
      | "QUARANTINEFLAGGED"
      | "QUARANTINEDFUNDS"
      | "FAILED"
      | "SIGNING"
      | "CONFIRMING",
    correlationId?: string,
    options?: AxiosRequestConfig,
  ) {
    return WalletTransactionApiFp(this.configuration)
      .getWalletTransactions(walletId, organizationId, blockHeight, status, correlationId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
