import { Button, Dialog, DialogTitle } from "@bakkt/bakkt-ui-components";
import ReviewWithdrawDetails from "./ReviewWithdrawDetails";
import { Alert, DialogActions, DialogContent, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, LoaderFunctionArgs, defer, useLoaderData, useLocation } from "react-router-dom";
import { RootContextType, useRootContext } from "../../RootLayout";
import { getGasBalanceForFeeWallet, isERC20Coin, shouldUseMockData } from "../../utils/dataUtils";
import { fetchMockDataPromiseWithDelay, walletTransactions } from "../../services/mockData";
import { AllowListService, WalletTransactionService } from "../../services/serviceLoader";
import { WithdrawMinimal } from "./WalletWithdraw";
import {
  AllowlistAddress,
  PolicyActionActionEnum,
  WalletTransaction,
  Wallet,
  AllowlistAddressStatusEnum,
} from "../../services/openAPI/client";
import { isApprover, isPolicyDisabled } from "../../utils/permissionsUtil";
import { CryptoTickerEnum } from "../../utils/CryptoIconsMap";

const ReviewWithdraw = () => {
  const { selectedOrg, userInfo, orgDataCache, priceFeed, assets } = useRootContext() as RootContextType;
  const navigate = useNavigate();
  const { state } = useLocation();
  const isApproved = state.approved;
  const policyItem = isApproved ? state.policyItem : state.data;
  const [open, setOpen] = useState(true);

  const { walletTransaction, allowlistList, orgWalletTransactions } = useLoaderData() as {
    walletTransaction: WalletTransaction;
    walletTransactions: WalletTransaction[];
    allowlistList: AllowlistAddress[];
    orgWalletTransactions: WalletTransaction[];
  };
  const selectedAllowlistItem = allowlistList.find(
    (allowListItem) => allowListItem.id === walletTransaction.destinationId,
  );

  const withdrawFormData: WithdrawMinimal = {
    assetTicker: walletTransaction.assetTicker,
    fromWalletId: walletTransaction.sourceId,
    fromWalletAddress: walletTransaction.sourceName,
    toWalletAddress: walletTransaction.destinationName,
    allowListId: walletTransaction.destinationId,
    allowListName: selectedAllowlistItem?.name,
    destinationType: walletTransaction.destinationType,
    quantity: walletTransaction.quantity,
    createdOn: policyItem.timestamp,
    clientName: policyItem.client,
    orgName: policyItem.organization,
    requesterName: policyItem.requester,
  };

  const thirdActionSx = { selfAlign: "start", marginRight: "auto" };

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleDecline = () => {
    navigate(
      `/${selectedOrg.id}/${PolicyActionActionEnum.Deny.toLowerCase()}-transaction/${policyItem.policyActionId}`,
      {
        state: policyItem,
      },
    );
  };

  const handleCancel = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleConfirm = () => {
    navigate(
      `/${selectedOrg.id}/${PolicyActionActionEnum.Approve.toLowerCase()}-transaction/${policyItem.policyActionId}`,
      { state: policyItem },
    );
  };

  const isDisabled = isPolicyDisabled(
    policyItem.approvalCount,
    policyItem?.consensusNumber,
    policyItem.policyInstanceStatus,
    policyItem.policySequenceStatus,
    isApproved,
  );

  //For ERC-20 Assets calculate GAS balance
  const isERC20 = isERC20Coin(walletTransaction.assetTicker || "");
  const fromWallet: Wallet | null =
    orgDataCache.wallets.find((wallet) => wallet.walletId === withdrawFormData.fromWalletId) || null;
  const isSourceOmni = orgDataCache.omnibusWallets.find((wallet) => wallet.walletId === withdrawFormData.fromWalletId)
    ? true
    : false;
  const feeWallet: Wallet | null = orgDataCache.wallets.find((w) => fromWallet?.feeWalletId === w.walletId) || null;
  const feeWalletTransactions = orgWalletTransactions.filter(
    (tx) => tx.sourceId === feeWallet?.walletId || tx.destinationId === feeWallet?.walletId,
  );

  //If there is no feeWallet set gasBalance to 0.
  const gasBalance = getGasBalanceForFeeWallet(feeWallet, priceFeed, assets) || 0;

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={false}>
        <DialogTitle title={`Review Withdraw`} severity="warning">
          Review the request details below.
        </DialogTitle>
        <DialogContent>
          {isERC20 && !isSourceOmni && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              <Typography variant={"body2"}>
                {selectedOrg.name}, GAS Balance: {gasBalance.toFixed(8)} {CryptoTickerEnum.ETH}
              </Typography>
              <Typography variant={"body2"}>
                *Please ensure that the client has a balance of at least 0.05 ETH for GAS before processing this
                withdrawal.
              </Typography>
            </Alert>
          )}
          <ReviewWithdrawDetails withdrawRequest={withdrawFormData} allowListedAddresses={allowlistList} />
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={handleCancel} sx={thirdActionSx}>
            Go Back
          </Button>

          {isApprover(userInfo, Number(selectedOrg.id)) && (
            <>
              <Button variant={"outlined"} onClick={handleDecline} disabled={isDisabled} sx={{ mr: 1 }}>
                Decline
              </Button>

              <Button variant={"contained"} onClick={handleConfirm} disabled={isDisabled} autoFocus>
                Approve
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReviewWithdraw;

export async function loader({ params }: LoaderFunctionArgs) {
  const walletTransactionId = Number(params.walletTransactionId);
  const orgId = Number(params.organizationId);

  const transactionRequestItem = shouldUseMockData
    ? ((await fetchMockDataPromiseWithDelay(walletTransactions[0], 300)) as WalletTransaction)
    : await (WalletTransactionService.getWalletTransactionById(walletTransactionId) as WalletTransaction);

  const requestedWalletTransactions = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(walletTransactions, 500)
    : WalletTransactionService.getWalletTransactions(transactionRequestItem.sourceId, orgId);

  const allWalletTransactionsForOrg = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(walletTransactions, 500)
    : WalletTransactionService.getWalletTransactions(undefined, orgId);

  const allowlistAddresses = AllowListService.getAllowlistAddresses(
    orgId,
    undefined,
    AllowlistAddressStatusEnum.Approved,
  );

  return defer({
    walletTransaction: transactionRequestItem,
    walletTransactions: await requestedWalletTransactions,
    allowlistList: await allowlistAddresses,
    orgWalletTransactions: await allWalletTransactionsForOrg,
  });
}
