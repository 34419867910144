import { AllowListFormData } from "./AddAllowlist";
import { Button, Dialog, DialogTitle, TextField } from "@bakkt/bakkt-ui-components";
import {
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFetcher, useNavigate } from "react-router-dom";
import { useRootContext } from "../../RootLayout";
import { DestinationTypeEnum, OwnerTypeEnum } from "../../utils/CryptoIconsMap";
import { useTheme } from "@mui/material/styles";
import { CreateAllowlistRequest, CreateAllowlistRequestTypeEnum } from "../../services/openAPI/client";
import { mapAllowListFormDataToAllowListAddressRequest } from "../../utils/dataUtils";
import { formatActionErrorResponse, formatActionSuccessResponse } from "../../utils/responseHandlingUtils";
import { AllowListService } from "../../services/serviceLoader";

interface AdditionalInfoProps {
  allowlistFormData: AllowListFormData;
}

export const AdditionalInfo = ({ allowlistFormData }: AdditionalInfoProps) => {
  const { selectedOrg, addAlert, setShouldRefreshPolicyItems, priceFeed } = useRootContext();
  const navigate = useNavigate();
  const theme = useTheme();

  const fetcher = useFetcher();

  const [formData, setFormData] = useState<AllowListFormData>(allowlistFormData);

  const [open, setOpen] = useState(true);

  function updateField(name: string, value: string | boolean) {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  function handleInputChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent) {
    const { name, value } = event.target;
    updateField(name, value);
    if (name === "destinationType") {
      updateField("isSelfHosted", value === DestinationTypeEnum.Centralized ? false : true);
    }
  }

  function handleUpdateCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    if (name === "type") {
      if (checked) {
        updateField("type", CreateAllowlistRequestTypeEnum.Internal);
      } else {
        updateField("type", CreateAllowlistRequestTypeEnum.External);
      }
    } else if (name === "agreeTerms") {
      if (checked) {
        updateField("agreeTerms", true);
      } else {
        updateField("agreeTerms", false);
      }
    }
  }

  const handleCancel = () => {
    setOpen(false);
    navigate(`/allowlist/${selectedOrg.id}`);
  };

  function isFormValid() {
    let isValid = false;
    if (formData.type === CreateAllowlistRequestTypeEnum.Internal) {
      isValid = true;
    } else if (
      formData.type === CreateAllowlistRequestTypeEnum.External &&
      formData.destinationType === DestinationTypeEnum.Centralized &&
      formData.financialInstitutionName
    ) {
      isValid = true;
    } else if (
      formData.type === CreateAllowlistRequestTypeEnum.External &&
      formData.destinationType === DestinationTypeEnum.SelfHosted &&
      ((formData.ownerType === OwnerTypeEnum.Individual &&
        formData.individualFirstName &&
        formData.individualLastName) ||
        (formData.ownerType === OwnerTypeEnum.Company && formData.companyName))
    ) {
      isValid = true;
    }
    return isValid && formData.agreeTerms;
  }

  function handleSubmit() {
    const allowlistAddressRequest = mapAllowListFormDataToAllowListAddressRequest(formData);
    fetcher.submit(JSON.stringify(allowlistAddressRequest), {
      method: "post",
      encType: "application/json",
    });
  }

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response?.success) {
        setShouldRefreshPolicyItems(true);
        addAlert({
          severity: "success",
          messageHeader: "The new address has been saved and is pending review.",
          message: `${selectedOrg.name} requires ${selectedOrg.numberOfConsensus} approvals to add a new address to your Allowlist. Once approved, the request will be shared with Bakkt Support for review.`,
        });
        handleCancel();
        navigate(`/allowlist/${selectedOrg.id}`);
      } else {
        addAlert({
          severity: "error",
          messageHeader: "There was an error saving the new address.",
          message: "Failed to submit address for allow listing. Please try again later or contact support.",
        });
        handleCancel();
      }
    }
  }, [fetcher.data]);

  return (
    <Dialog open={open} maxWidth={"sm"} fullWidth={true} onClose={handleCancel}>
      <DialogTitle title={"Additional Information Required"}>
        Due to regulations, we’re required to collect additional information about the recipient.
      </DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={4} data-testid="addAdditionalInfoGrid">
          <Grid xs={12}>
            <FormControl variant="standard" sx={{ pt: 3 }} required>
              <FormLabel sx={{ color: theme.palette.primary.light }}>Do you own this address?</FormLabel>

              <FormControlLabel
                sx={{ pl: 2 }}
                control={<Checkbox name={"type"} onChange={(event) => handleUpdateCheckbox(event)} />}
                label={"Yes, I own this account"}
                checked={formData.type === CreateAllowlistRequestTypeEnum.Internal ? true : false}
              />
            </FormControl>
          </Grid>
          <Grid xs={12}>
            <FormControl variant="standard" sx={{ pt: 3 }} required>
              <FormLabel sx={{ color: theme.palette.primary.main }}>Select Destination Type</FormLabel>
              <RadioGroup
                sx={{ pl: 2 }}
                onChange={handleInputChange}
                name="destinationType"
                value={formData.destinationType}
              >
                <FormControlLabel
                  value={DestinationTypeEnum.Centralized}
                  control={<Radio color="secondary" />}
                  label={DestinationTypeEnum.Centralized}
                />
                <FormControlLabel
                  value={DestinationTypeEnum.SelfHosted}
                  control={<Radio color="secondary" />}
                  label={DestinationTypeEnum.SelfHosted}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {formData.type === CreateAllowlistRequestTypeEnum.External &&
            formData.destinationType === DestinationTypeEnum.Centralized && (
              <Grid xs={12} sx={{ pl: 2 }}>
                <TextField
                  name="financialInstitutionName"
                  value={formData.financialInstitutionName}
                  onChange={handleInputChange}
                  label="Centralized Provider's Name"
                  id="financialInstitutionName"
                  variant="standard"
                  fullWidth
                  required
                />
              </Grid>
            )}
          {formData.type === CreateAllowlistRequestTypeEnum.External &&
            formData.destinationType === DestinationTypeEnum.SelfHosted && (
              <Grid xs={12}>
                <FormControl variant="standard" sx={{ pt: 3 }} required>
                  <FormLabel sx={{ color: theme.palette.primary.light }}>
                    Does the address belong to an individual or a company?
                  </FormLabel>
                  <RadioGroup sx={{ pl: 2 }} onChange={handleInputChange} name="ownerType" value={formData.ownerType}>
                    <FormControlLabel
                      value={OwnerTypeEnum.Individual}
                      control={<Radio color="secondary" />}
                      label={OwnerTypeEnum.Individual}
                    />
                    <FormControlLabel
                      value={OwnerTypeEnum.Company}
                      control={<Radio color="secondary" />}
                      label={OwnerTypeEnum.Company}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}

          {formData.type === CreateAllowlistRequestTypeEnum.External &&
            formData.ownerType === OwnerTypeEnum.Individual &&
            formData.destinationType === DestinationTypeEnum.SelfHosted && (
              <Grid container xs={12} spacing={2} sx={{ margin: 0 }}>
                <Grid xs={6} sx={{ paddingLeft: 0 }}>
                  <TextField
                    value={formData.individualFirstName}
                    onChange={handleInputChange}
                    name="individualFirstName"
                    id="individual-first-name"
                    label="Recipient's First Name"
                    variant="standard"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid xs={6} sx={{ paddingRight: 0 }}>
                  <TextField
                    value={formData.individualLastName}
                    onChange={handleInputChange}
                    name="individualLastName"
                    id="individual-last-name"
                    label="Recipient's Last Name"
                    variant="standard"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            )}

          {formData.type === CreateAllowlistRequestTypeEnum.External &&
            formData.ownerType === OwnerTypeEnum.Company &&
            formData.destinationType === DestinationTypeEnum.SelfHosted && (
              <Grid xs={12}>
                <TextField
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  label="Recipient's Name"
                  id="companyName"
                  variant="standard"
                  fullWidth
                  required
                />
              </Grid>
            )}

          <Grid xs={12}>
            <FormControl variant="standard" sx={{ pt: 3 }} required>
              <FormControlLabel
                control={<Checkbox name={"agreeTerms"} onChange={(event) => handleUpdateCheckbox(event)} />}
                label={"I agree to the terms below"}
              />
              <FormHelperText>
                {"By making this request you acknowledge that Bakkt will verify the authenticity of the information provided before allowing you to send or receive crypto from the new address. " +
                  "No information will be shared externally."}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant={"contained"} onClick={handleSubmit} autoFocus disabled={!isFormValid()}>
          Request Allowlist
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export async function action({ request }: { request: Request }) {
  try {
    const formAllowListRequest = (await request.json()) as CreateAllowlistRequest;
    const createAllowListResponse = await AllowListService.createAllowlist(formAllowListRequest);
    return formatActionSuccessResponse(createAllowListResponse);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
