import parse from "html-react-parser";
import { createSvgIcon } from "@mui/material";

export interface SVGProps {
  svgString: string;
  title: string;
  sx?: object;
}

export const AssetSVGIcon = ({ svgString, title, sx }: SVGProps) => {
  const Icon = createSvgIcon(parse(svgString), title);
  return <Icon sx={sx} />;
};
