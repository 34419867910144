import { formatCryptoQuantity, formatDollarAmountUsd } from "@bakkt/bakkt-ui-components";
import { getAvailableAndPendingBalances } from "../../../utils/dataUtils";

import { useRootContext } from "../../../RootLayout";
import { Typography, color } from "@bakkt/bakkt-ui-components";

interface walletAvailableProps {
  walletId: string;
}

export const WalletBalanceAvailableOnly = (props: walletAvailableProps) => {
  const walletId = props.walletId;

  const { orgDataCache, priceFeed, assets } = useRootContext();
  const wallets = [...orgDataCache.wallets, ...orgDataCache.omnibusWallets];
  const wallet = wallets.find((wallet) => wallet.walletId === Number(walletId));
  const balances = getAvailableAndPendingBalances(wallet, priceFeed, assets);

  const bodyHeadingSx = {
    color: color.text.secondary,
    fontWeight: "bold",
  };
  const cryptoBalance = balances.availableBalanceCrypto || 0;
  return (
    <>
      <Typography variant="body2" sx={bodyHeadingSx}>
        AMOUNT AVAILABLE
      </Typography>
      <Typography variant="h4">
        {formatCryptoQuantity(cryptoBalance, "long")} {(wallet && wallet.assetTicker) || ""}
      </Typography>
      <Typography variant="subtitle1">{formatDollarAmountUsd(balances.availableBalanceUsd || 0)} USD</Typography>
    </>
  );
};
