import { Alert, CircularProgress } from "@mui/material";
import { Unstable_Grid2 as Grid } from "@mui/material";

interface LoadingIndicatorProps {
  description?: string;
}

export default function LoadingIndicator({ description }: LoadingIndicatorProps) {
  return (
    <Grid container direction={"column"} alignItems={"center"} spacing={10}>
      {description && (
        <Grid xs={12}>
          <Alert severity={"info"}>{description}</Alert>
        </Grid>
      )}
      <CircularProgress />
    </Grid>
  );
}
