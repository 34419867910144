import { Container, Skeleton, SvgIcon } from "@mui/material";
import { innerGridContainer, wrapperContainer } from "./styles.tsx";
import { Alert, Button, Icons, Paper, Typography, color } from "@bakkt/bakkt-ui-components";
import { Unstable_Grid2 as Grid } from "@mui/material";
import React from "react";
import { Await, useRouteLoaderData } from "react-router-dom";
import { RootContextType, useRootContext } from "../../RootLayout";
import { OmnibusWalletsGrid } from "./OmnibusWalletsGrid.tsx";
import { Link as RouterLink } from "react-router-dom";
import { Wallet, WalletType } from "../../services/openAPI/client/api.ts";

export default function OmnibusWallets() {
  const { shouldRefreshOrgOmnibusWallets, selectedOrg } = useRootContext() as RootContextType;
  const { wallets } = useRouteLoaderData("root") as {
    wallets: Wallet[];
  };
  const selectedOrgOmnibusWallets = wallets.filter(
    (wallet: Wallet) =>
      wallet.organizationId === selectedOrg.id &&
      (wallet.type === WalletType.Trading || wallet.type === WalletType.Fee),
  );

  return (
    <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
      <Grid container xs={12} justifyContent={"space-between"} spacing={2} sx={{ mb: 4 }}>
        <Typography variant="h3" data-testid="omnibus-wallets">
          Omnibus Wallets
        </Typography>
        {selectedOrgOmnibusWallets.length > 0 && (
          <Grid container>
            <RouterLink to={`addWallet/`}>
              <Button
                variant="outlined"
                size="small"
                sx={{ pointerEvents: "auto", backgroundColor: color.crispWhite.main }}
                data-testid="newWalletButton"
              >
                <SvgIcon component={Icons.PlusIcon} sx={{ width: "20px", height: "20px", marginRight: "6px" }} />
                NEW WALLET
              </Button>
            </RouterLink>
          </Grid>
        )}
      </Grid>

      <Paper>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Container sx={innerGridContainer} data-testid="dashboardOmnibusWalletsTable">
              {shouldRefreshOrgOmnibusWallets ? (
                <Skeleton variant="rectangular" height={300} />
              ) : (
                <React.Suspense fallback={<Skeleton variant="rectangular" height={110} />}>
                  <Await
                    resolve={selectedOrgOmnibusWallets}
                    errorElement={<Alert severity="error">Error loading wallet data!</Alert>}
                  >
                    <OmnibusWalletsGrid />
                  </Await>
                </React.Suspense>
              )}
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
