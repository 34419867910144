import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  color,
  formatUnixTime,
  Icons,
  Paper,
  Skeleton,
  Typography,
  useInterval,
  UserInfo,
} from "@bakkt/bakkt-ui-components";
import {
  AlertTitle,
  Box,
  Container,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useRouteLoaderData } from "react-router-dom";
import { PolicyActionDetails, PolicyStatusEnum } from "../../services/openAPI/client";
import { isApprover } from "../../utils/permissionsUtil";
import { getRouteByRequestCategory, shouldUseMockData } from "../../utils/dataUtils";
import { fetchMockDataPromiseWithDelay, pendingReview } from "../../services/mockData";
import { PolicyService } from "../../services/serviceLoader";
import PendingApprovalLink from "./PendingApprovalLink";
import ConsensusLink from "./ConsensusLink";
import RequestLink from "./RequestLink";

interface PendingApprovalProps {
  selectedOrgId: string;
  shouldRefreshPolicyItems: boolean;
  setShouldRefreshPolicyItems: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PendingApproval = ({
  selectedOrgId,
  shouldRefreshPolicyItems,
  setShouldRefreshPolicyItems,
}: PendingApprovalProps) => {
  const location = useLocation();
  const { userInfo } = useRouteLoaderData("root") as { userInfo: UserInfo };
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [allPendingPolicyItems, setAllPendingPolicyItems] = useState<PolicyActionDetails[]>([]);
  const [approverPendingPolicyItems, setApproverPendingPolicyItems] = useState<PolicyActionDetails[]>([]);

  const getPendingPolicyItems = async () => {
    try {
      const allPolicyItems: any = shouldUseMockData
        ? await fetchMockDataPromiseWithDelay(pendingReview, 200)
        : await PolicyService.getAllPolicySequences(Number(selectedOrgId));

      const isOlderThan24Hours = (policy: PolicyActionDetails) => {
        const twentyFourHoursAgoTimestamp = Number(new Date().getTime() - 24 * 60 * 60 * 1000);
        return twentyFourHoursAgoTimestamp > Number(policy.timestamp);
      };

      const filteredAllPolicyItems = allPolicyItems
        .filter((policy: PolicyActionDetails) => {
          if (
            policy.policyInstanceStatus === PolicyStatusEnum.Completed ||
            policy.policySequenceStatus === PolicyStatusEnum.Cancelled
          ) {
            if (!isOlderThan24Hours(policy)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        })
        .sort(
          (policyA: PolicyActionDetails, policyB: PolicyActionDetails) =>
            Number(policyB.timestamp) - Number(policyA.timestamp),
        );

      if (shouldRefreshPolicyItems && containsExpectedPolicyItemUpdate(allPendingPolicyItems, filteredAllPolicyItems)) {
        setShouldRefreshPolicyItems(false);
      }

      setAllPendingPolicyItems(filteredAllPolicyItems);

      if (isApprover(userInfo, Number(selectedOrgId))) {
        const approverPolicyItems: any = shouldUseMockData
          ? await fetchMockDataPromiseWithDelay(pendingReview, 200)
          : await PolicyService.getInitiatedPolicyActions(userInfo.userId, Number(selectedOrgId));
        setApproverPendingPolicyItems(approverPolicyItems);
      }
    } catch (error) {
      return setIsError(true);
    }
  };

  const containsExpectedPolicyItemUpdate = (oldItems: PolicyActionDetails[], newItems: PolicyActionDetails[]) => {
    // Check if a new policy item exists for this requester ID, e.g. a new allowlist or transaction was created
    const filterFunction = (item: PolicyActionDetails) => item.requesterId === userInfo.userId;
    const oldItemRequesterIdCount = oldItems.filter(filterFunction).length;
    const newItemRequesterIdCount = newItems.filter(filterFunction).length;
    const isNewPolicyItem = newItemRequesterIdCount > oldItemRequesterIdCount;

    // Check if the total approver count has increased, e.g. an approval for allowlist or transaction was given
    const approverCountReducerFunction = (accumulator: number, currentValue: PolicyActionDetails) =>
      accumulator + (currentValue.approvalCount || 0);
    const oldItemApproverCount = oldItems.reduce(approverCountReducerFunction, 0);
    const newItemApproverCount = newItems.reduce(approverCountReducerFunction, 0);
    const isTotalApprovalCountIncreased = newItemApproverCount > oldItemApproverCount;

    // Check if the total cancelled count has increased, e.g. a denial for allowlist or transaction was given
    const cancelledCountReducerFunction = (accumulator: number, currentValue: PolicyActionDetails) => {
      if (currentValue.policySequenceStatus === PolicyStatusEnum.Cancelled) {
        return accumulator + 1;
      }
      return accumulator;
    };
    const oldItemCancelledCount = oldItems.reduce(cancelledCountReducerFunction, 0);
    const newItemCancelledCount = newItems.reduce(cancelledCountReducerFunction, 0);
    const isTotalCancelledCountIncreased = newItemCancelledCount > oldItemCancelledCount;

    return isNewPolicyItem || isTotalApprovalCountIncreased || isTotalCancelledCountIncreased;
  };

  useEffect(() => {
    getPendingPolicyItems();
  }, [selectedOrgId]);

  // refresh every 3 sec when polling for new item - otherwise refresh every 60 sec
  const refreshIntervalMilliseconds = shouldRefreshPolicyItems ? 3000 : 60000;
  useInterval(async () => {
    getPendingPolicyItems();
  }, refreshIntervalMilliseconds);

  const handleAccordionChange = (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
    event.preventDefault();
    setIsExpanded(isExpanded);
  };

  const tableHeader = {
    fontWeight: 500,
    fontSize: 16,
  };

  const linkSx = {
    textDecoration: "underline",
  };

  return (
    <Paper>
      <Container maxWidth="xl" disableGutters sx={{ py: 3, px: 7 }}>
        <Accordion expanded={isExpanded} onChange={handleAccordionChange} sx={{ border: 0 }}>
          <AccordionSummary
            sx={{ display: "flex", flexDirection: "row", ml: -1, pl: 0 }}
            aria-controls="panel1d-content"
            expandIcon={<SvgIcon component={Icons.ChevronDownIcon} />}
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                variant="h4"
                sx={{
                  color: allPendingPolicyItems.length >= 1 ? color.engagingOrange.dark : color.techBlack[700],
                  fontWeight: 500,
                  letterSpacing: "normal",
                }}
              >
                Pending Review
              </Typography>
              <Typography variant="body1">Pending reviews needed from approvers in your organization.</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
              <Typography variant="body1">{isExpanded ? "Collapse" : "Expand"}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            {shouldRefreshPolicyItems ? (
              <Skeleton variant="rectangular" height={110} />
            ) : isError ? (
              <Alert severity="error">
                <AlertTitle>Error loading pending review data.</AlertTitle>
                Please try again or contact support for assistance.
              </Alert>
            ) : (
              <>
                {allPendingPolicyItems.length >= 1 ? (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow sx={{ "&:hover": { backgroundColor: "inherit" } }}>
                          <TableCell component="th" sx={[tableHeader, { pl: 0 }]} width="250">
                            REQUEST
                          </TableCell>
                          <TableCell component="th" align="left" sx={tableHeader} width="200">
                            TIMESTAMP
                          </TableCell>
                          <TableCell component="th" align="left" sx={tableHeader} width="500">
                            REQUESTER
                          </TableCell>
                          <TableCell component="th" align="right" sx={tableHeader}>
                            COMPLETE APPROVALS
                          </TableCell>
                          <TableCell component="th" align="right" sx={[tableHeader, { pr: 0.2 }]}>
                            STATUS
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allPendingPolicyItems.map((policyItem: PolicyActionDetails, index: number) => {
                          const approverPolicyItem = approverPendingPolicyItems.find(
                            (item) => item.policySequenceId === policyItem.policySequenceId,
                          );
                          return (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row" sx={{ pl: 0.2 }}>
                                {isApprover(userInfo, Number(selectedOrgId)) &&
                                approverPolicyItem &&
                                Number(policyItem.approvalCount) < Number(policyItem.consensusNumber) &&
                                policyItem.policySequenceStatus != PolicyStatusEnum.Cancelled ? (
                                  <RouterLink
                                    to={`${location.pathname}/review-${getRouteByRequestCategory(policyItem)}/${
                                      JSON.parse(String(policyItem.policyInstancePayload)).entityId
                                    }`}
                                    state={{
                                      data: approverPolicyItem,
                                    }}
                                  >
                                    <Box component="span" sx={linkSx}>
                                      {policyItem.policyInstanceRequestType?.replaceAll("_", " ")}{" "}
                                      {policyItem.policyInstanceRequestCategory}
                                    </Box>
                                  </RouterLink>
                                ) : (
                                  <RequestLink
                                    policyItem={policyItem}
                                    linkState={{
                                      policyItem,
                                      approved: true,
                                    }}
                                  />
                                )}
                              </TableCell>
                              <TableCell align="left">{formatUnixTime(Number(policyItem.timestamp), "long")}</TableCell>
                              <TableCell align="left">{policyItem.requester}</TableCell>
                              <TableCell align="right">
                                {policyItem.approvalCount} of {policyItem.consensusNumber}
                              </TableCell>
                              <TableCell align="right" sx={{ pr: 0.2 }}>
                                {isApprover(userInfo, Number(selectedOrgId)) &&
                                approverPolicyItem &&
                                policyItem.policySequenceStatus != PolicyStatusEnum.Cancelled &&
                                Number(policyItem.approvalCount) < Number(policyItem.consensusNumber) ? (
                                  <PendingApprovalLink
                                    policyItem={policyItem}
                                    linkState={{
                                      data: approverPolicyItem,
                                    }}
                                  />
                                ) : (
                                  <ConsensusLink
                                    policyItem={policyItem}
                                    linkState={{
                                      policyItem,
                                      approved: true,
                                    }}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h5" sx={{ fontWeight: 400 }}>
                      No items to review
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </Container>
    </Paper>
  );
};
