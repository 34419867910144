import { Typography, Paper, Link, Divider, Button, Icons, formatDollarAmountUsd } from "@bakkt/bakkt-ui-components";
import { Box, Container } from "@mui/material";
import { Unstable_Grid2 as Grid } from "@mui/material";
import { currentAmountColor, currentAmountDollar, organizationGridSpacing } from "./styles";
import { RootContextType, useRootContext } from "../../RootLayout";
import { isInitiator } from "../../utils/permissionsUtil";
import { Link as RouterLink } from "react-router-dom";

export const OrganizationDetails = () => {
  const { selectedOrg, userInfo } = useRootContext() as RootContextType;
  return (
    <Container maxWidth="xl" disableGutters sx={{ my: 3 }}>
      <Grid container>
        <Typography variant="h3" sx={{ pb: 3 }}>
          Organization
        </Typography>
      </Grid>

      <Paper>
        <Grid container>
          <Grid container spacing={2} xs={12}>
            <Grid container xs={6} sx={organizationGridSpacing} key={selectedOrg.id}>
              <Grid>
                <Container>
                  <Typography variant="body2" sx={currentAmountColor}>
                    VIDEO AUTHORIZATION THRESHOLD
                  </Typography>
                  <Typography variant="h3" sx={currentAmountDollar}>
                    {formatDollarAmountUsd(selectedOrg.withdrawalAuthorization.amountLimit || 0)}
                  </Typography>
                  {isInitiator(userInfo, selectedOrg.id) ? (
                    <RouterLink to={`changeRequest/${"video-auth"}`}>
                      <Box component="span" className="body-links">
                        Request Change
                      </Box>
                    </RouterLink>
                  ) : (
                    <></>
                  )}
                </Container>
              </Grid>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid container xs={5} sx={organizationGridSpacing}>
              <Grid sx={{ pl: 0 }}>
                <Container>
                  <Typography variant="body2" sx={currentAmountColor}>
                    NUMBER OF APPROVERS
                  </Typography>
                  <Typography variant="h3" sx={currentAmountDollar}>
                    {selectedOrg.numberOfConsensus} required
                  </Typography>
                  {isInitiator(userInfo, selectedOrg.id) ? (
                    <RouterLink to={`changeRequest/${"consensus"}`}>
                      <Box component="span" className="body-links">
                        Request Change
                      </Box>
                    </RouterLink>
                  ) : (
                    <></>
                  )}
                </Container>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
