import { Alert, Button, DataGridPremium, Icons, Pagination, Tab, formatPhoneNumber } from "@bakkt/bakkt-ui-components";
import {
  Box,
  Chip,
  Stack,
  SvgIcon,
  Tabs,
  Typography,
  Unstable_Grid2 as Grid,
  TablePaginationProps,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import {
  DataGridPremiumProps,
  GridColDef,
  GridPagination,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";
import React, { useState } from "react";
import { Link as RouterLink, Await, useAsyncValue } from "react-router-dom";
import { Organization, OrganizationPermission, Status, User } from "../../services/openAPI/client";
import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { internalGrid, userPremissionsChip } from "./styles";

const tabs = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

const activeUserStatus = Object.values(Status).filter((statusValue) => statusValue !== Status.Deleted);

const inactiveUserStatus = Object.values(Status).filter((statusValue) => statusValue === Status.Deleted);

export const DetailPanelContent = (params: { row: User; orgs: Organization[] }) => {
  function renderPermissions(permissions: string[] | undefined) {
    return (
      <Stack direction="row" spacing={1}>
        {permissions?.map((permission, index) =>
          permission == OrganizationPermission.View && permissions?.length > 1 ? (
            <></>
          ) : (
            <Chip key={index} label={permission.replace("_", " ").toLowerCase()} sx={userPremissionsChip} />
          ),
        )}
      </Stack>
    );
  }

  function getOrganizationName(id: number) {
    return params.orgs.find((org: Organization) => org.id === id)?.name;
  }

  const columns: GridColDef[] = [
    {
      field: "organizationId",
      headerName: "ORGANIZATION",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) => {
        return getOrganizationName(row.organizationId);
      },
    },
    {
      field: "activePermissions",
      headerName: "PERMISSIONS",
      minWidth: 1000,
      renderCell: ({ row }) => renderPermissions(row.activePermissions),
    },
  ];

  const initialState = {};

  const data = { columns, initialState, rows: params.row.organizations } as DataGridPremiumProps<any>;

  return (
    <Paper sx={{ pl: 6.5 }}>
      <DataGridPremium
        sx={internalGrid}
        {...data}
        initialState={{
          ...data.initialState,
        }}
        getRowId={(row) => row.organizationId}
        hideFooter
      />
    </Paper>
  );
};

export const ManageUsersGrid = () => {
  const { users, orgs } = useAsyncValue() as {
    users: User[];
    orgs: Organization[];
  };

  const [selectedTab, setSelectedTab] = useState("active");

  const [filteredUsers, setFilteredUsers] = useState(
    users.filter((user: User) => activeUserStatus.includes(user.status)),
  );

  const filterActiveInactiveUsers = (currTab: string) => {
    if (currTab === "active") {
      setFilteredUsers(users.filter((user) => activeUserStatus.includes(user.status)));
    } else if (currTab === "inactive") {
      setFilteredUsers(users.filter((user) => inactiveUserStatus.includes(user.status)));
    } else {
      setFilteredUsers(users);
    }
  };

  const handleTabChange = (_: React.SyntheticEvent, value: string) => {
    setSelectedTab(value);
    filterActiveInactiveUsers(value);
  };

  const mailTo = "operations@bakkt.com";

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Grid container xs={12} justifyContent="space-between">
          <GridToolbarQuickFilter />
          <RouterLink to={`changeRequest/${"user-add"}`} className="default-link-hover">
            <Button variant={"contained"}>
              <SvgIcon component={Icons.PlusIcon} inheritViewBox sx={{ width: 20, height: 20, pr: 1 }} />
              New User
            </Button>
          </RouterLink>
        </Grid>
        <Grid xs={12}>
          <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor={"secondary"}>
            {tabs.map((tab) => (
              <Tab key={tab.value} value={tab.value} label={tab.label}></Tab>
            ))}
          </Tabs>
        </Grid>
      </GridToolbarContainer>
    );
  };

  function getColorClass(value: string) {
    if (value.toUpperCase() === Status.PendingActivation || value.toUpperCase() === Status.PendingChanges) {
      return "client-status-orange-cell";
    } else {
      return "";
    }
  }

  const getEditLink = () => {
    return (
      <div>
        <RouterLink to={`changeRequest/${"user"}`} className="default-link-hover">
          Request Change
        </RouterLink>
      </div>
    );
  };

  const paginationSx = {
    "& .MuiPagination-ul": {
      flexWrap: "nowrap",
    },
  };

  function MuiPagination({ page, onPageChange }: Pick<TablePaginationProps, "page" | "onPageChange">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
        sx={paginationSx}
        showFirstButton
        showLastButton
      />
    );
  }

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={MuiPagination} {...props} />;
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 40,
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "LAST NAME",
      minWidth: 50,
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.name.lastName;
      },
    },

    {
      field: "firstName",
      headerName: "FIRST NAME",
      minWidth: 50,
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.name.firstName;
      },
    },
    {
      field: "email",
      headerName: "EMAIL",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "phone",
      headerName: "PHONE",
      minWidth: 30,
      flex: 1,
      valueGetter: (params: any) => {
        return formatPhoneNumber(params.row.phone.countryCode + " " + params.row.phone.number);
      },
    },
    {
      field: "position",
      headerName: "POSITION",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "status",
      headerName: "STATUS",
      minWidth: 200,
      flex: 1,
      cellClassName: (params: any) => getColorClass(params.value),
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      minWidth: 120,
      align: "right",
      headerAlign: "right",
      renderCell: () => getEditLink(),
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
      },
    },
  };

  const data = { columns, initialState, rows: filteredUsers };

  const getDetailPanelContent = React.useCallback(
    ({ row }: GridRowParams) =>
      row.organizations.length > 0 ? (
        <DetailPanelContent row={row} orgs={orgs} />
      ) : (
        <Paper>
          <Typography variant={"body1"} sx={{ textAlign: "center", pt: 2, pb: 2 }}>
            No Organization Permissions Found
          </Typography>
        </Paper>
      ),
    [],
  );

  function customDetailPanelExpandIcon() {
    return <SvgIcon component={Icons.ChevronDownIcon} inheritViewBox sx={{ width: 20, height: 20 }} />;
  }

  function customDetailPanelCollapseIcon() {
    return <SvgIcon component={Icons.ChevronUpIcon} inheritViewBox sx={{ width: 20, height: 20 }} />;
  }

  return (
    <>
      <Paper>
        <React.Suspense fallback={<LoadingIndicator />}>
          <Await resolve={users} errorElement={<Alert severity={"error"}>Error loading users data!</Alert>}>
            <Box sx={{ height: "100%", flex: 1, width: "100%" }}>
              <DataGridPremium
                slots={{
                  toolbar: CustomToolbar,
                  detailPanelExpandIcon: customDetailPanelExpandIcon,
                  detailPanelCollapseIcon: customDetailPanelCollapseIcon,
                  pagination: CustomPagination,
                }}
                {...data}
                initialState={{
                  ...data.initialState,
                }}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={() => "auto"}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                pagination
                disableRowSelectionOnClick
              />
            </Box>
          </Await>
        </React.Suspense>
      </Paper>
    </>
  );
};
