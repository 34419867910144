import { OrganizationUsers } from "./OrganizationUsers";
import { UserService, OrganizationService } from "../../services/serviceLoader";
import { defer, LoaderFunctionArgs, Outlet } from "react-router-dom";
import { fetchMockDataPromiseWithDelay, organizations, users } from "../../services/mockData";
import { shouldUseMockData } from "../../utils/dataUtils.ts";
import { OrganizationDetails } from "./OrganizationDetails.tsx";
import { useRootContext } from "../../RootLayout";
import React from "react";
import Alerts from "../../components/alerts/Alerts";

const Organization = () => {
  return (
    <>
      <Outlet context={useRootContext()} />
      <Alerts />
      <OrganizationDetails />
      <OrganizationUsers />
    </>
  );
};

export default Organization;

export async function loader({ params }: LoaderFunctionArgs) {
  try {
    const orgId = Number(params.organizationId);

    const userPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(users, 3000)
      : UserService.getUsers(undefined, [orgId]);

    return defer({
      usersPromise: userPromise,
    });
  } catch (e) {
    console.log("Error fetching dashboard data: ", e);
    return {};
  }
}
