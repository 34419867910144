import { LoaderFunctionArgs, defer, useLoaderData, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import {
  DialogActions,
  DialogContent,
  FormControl,
  Unstable_Grid2 as Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  SvgIcon,
  ClickAwayListener,
  Tooltip,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  Button,
  TextField,
  InputLabel,
  Select,
  Typography,
  Icons,
} from "@bakkt/bakkt-ui-components";
import { getAllowlistById, shouldUseMockData } from "../../utils/dataUtils";
import { useRootContext } from "../../RootLayout";
import { allow, fetchMockDataPromiseWithDelay } from "../../services/mockData";
import { AllowlistAddress, AllowlistAddressStatusEnum } from "../../services/openAPI/client";
import { AllowListService } from "../../services/serviceLoader";
import { isApprover, isPolicyDisabled } from "../../utils/permissionsUtil";
import { AssetSVGIcon } from "../../components/customSVG/AssetSVGIcon.tsx";

const ReviewAllowlist = () => {
  const { selectedOrg, orgDataCache, userInfo, assets } = useRootContext();
  const { allowlistItem } = useLoaderData() as {
    allowlistItem: AllowlistAddress;
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const isApproved = state.approved;
  const reviewItem = isApproved ? state.policyItem : state.data;
  const [open, setOpen] = useState(true);
  const [showCopyAddressConfirm, setShowCopyAddressConfirm] = useState<boolean>(false);
  const thirdActionSx = { selfAlign: "start", marginRight: "auto" };

  const handleCopy = (address: string) => {
    navigator.clipboard.writeText(address);
    setShowCopyAddressConfirm(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleDecline = () => {
    navigate(`/${selectedOrg.id}/decline-allowlist/${reviewItem.policyActionId}`, { state: reviewItem });
  };

  const handleCancel = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleConfirm = () => {
    navigate(`/${selectedOrg.id}/confirm-allowlist/${reviewItem.policyActionId}`, { state: reviewItem });
  };

  const handleTooltipClose = () => {
    setShowCopyAddressConfirm(false);
  };

  const isAllowlistExpired = allowlistItem.status === AllowlistAddressStatusEnum.Expired;

  const isDisabled =
    isPolicyDisabled(
      reviewItem.approvalCount,
      reviewItem.consensusNumber,
      reviewItem.policyInstanceStatus,
      reviewItem.policySequenceStatus,
      isApproved,
    ) || isAllowlistExpired;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
      <DialogTitle title={"Review and Approve New Address"} severity="warning">
        Review new allowlist address and confirm if correct.
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3} sx={{ pb: 2 }}>
          <Grid xs={4}>
            <TextField
              name="Client"
              value={orgDataCache.account.name}
              label="Client"
              id="name"
              variant="standard"
              fullWidth
              disabled
            />
          </Grid>
          <Grid xs={8}>
            <TextField
              name="address name"
              value={allowlistItem.name}
              label="Address Name"
              id="address name"
              variant="standard"
              fullWidth
              disabled
            />
          </Grid>
          <Grid xs={4}>
            <TextField
              name="organization"
              value={selectedOrg.name}
              label="Organization"
              id="organization"
              variant="standard"
              fullWidth
              disabled
            />
          </Grid>
          <Grid xs={8}>
            <TextField
              name="address"
              value={allowlistItem.address}
              label="Address"
              id="address"
              variant="standard"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <Tooltip
                        onClose={handleTooltipClose}
                        open={showCopyAddressConfirm}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        placement="top"
                        title="Address Copied"
                      >
                        <IconButton
                          onClick={() => handleCopy(String(allowlistItem.address))}
                          disableRipple
                          disableFocusRipple
                        >
                          <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={{ width: 15, height: 15 }} />
                        </IconButton>
                      </Tooltip>
                    </ClickAwayListener>
                  </InputAdornment>
                ),
              }}
              disabled
            />
          </Grid>
          <Grid container xs={12} alignItems={"flex-end"}>
            <Grid xs={4}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel id="select-asset" disabled>
                  Asset
                </InputLabel>
                <Select value={allowlistItem.assetTicker} label="asset" id="asset" disabled>
                  {assets.map((asset, index) => (
                    <MenuItem key={index} value={asset.ticker}>
                      <Grid container alignItems={"center"}>
                        <SvgIcon
                          component={() =>
                            AssetSVGIcon({
                              svgString: asset.iconSVG,
                              title: asset.ticker,
                              sx: { width: 32, height: 32, pr: 1 },
                            })
                          }
                          inheritViewBox
                        />
                        <Typography sx={{ mr: 1 }}>{asset.name}</Typography>
                        <Typography variant={"body2"}>{asset.ticker}</Typography>
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={8}>
              <TextField
                name="centralized provider name"
                value={allowlistItem.financialInstitutionName}
                label="Centralized Provider Name"
                id="centralized provider name"
                variant="standard"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant={"outlined"} onClick={handleCancel} sx={thirdActionSx}>
          Go Back
        </Button>
        {isApprover(userInfo, Number(selectedOrg.id)) && (
          <>
            <Button variant={"outlined"} onClick={handleDecline} disabled={isDisabled} sx={{ mr: 1 }}>
              Decline
            </Button>
            <Button variant={"contained"} onClick={handleConfirm} disabled={isDisabled} autoFocus>
              Confirm
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ReviewAllowlist;

export async function loader({ params }: LoaderFunctionArgs) {
  const allowlistId = Number(params.allowlistId);
  const allowlistItem = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(getAllowlistById(allowlistId, allow) || {}, 300)
    : AllowListService.getAllowlistAddressById(allowlistId);

  return defer({
    allowlistItem: await allowlistItem,
  });
}
