import { ThemeProvider, themes, Typography } from "@bakkt/bakkt-ui-components";
import CssBaseline from "@mui/material/CssBaseline";
import { useRouteError } from "react-router-dom";
import { Container } from "@mui/material";
import LoadingIndicator from "../../components/loading/LoadingIndicator.tsx";
import { AxiosError } from "axios";

export default function ErrorPage() {
  const error = useRouteError() as AxiosError | any;
  console.error(error);

  return (
    <ThemeProvider theme={themes.light}>
      <CssBaseline />
      {error?.response?.status === 401 ? (
        <Container
          maxWidth="xl"
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "600px" }}
        >
          <LoadingIndicator />
        </Container>
      ) : (
        <Container maxWidth="md">
          <Typography variant={"h4"}>Error</Typography>
          <Typography variant={"h6"}>
            Sorry, an unexpected error has occurred. Please contact support or try again later.
          </Typography>
        </Container>
      )}
    </ThemeProvider>
  );
}
