import { Unstable_Grid2 as Grid } from "@mui/material";
import { formatDollarAmountUsd } from "@bakkt/bakkt-ui-components";
import { WalletBalanceInfoCard } from "../../../components/walletBalanceInfoCard/WalletBalanceInfoCard";
import React from "react";
import { Wallet } from "../../../services/openAPI/client";
import { useParams } from "react-router-dom";
import { RootContextType, useRootContext } from "../../../RootLayout";
import { getAvailableAndPendingBalances } from "../../../utils/dataUtils";

export const WalletDetailsBalances = () => {
  const routeParams = useParams();

  const { orgDataCache, priceFeed, assets } = useRootContext() as RootContextType;
  const wallets = orgDataCache.wallets;
  const wallet = wallets.find((wallet: Wallet) => wallet.walletId === Number(routeParams.walletId));

  const balances = getAvailableAndPendingBalances(wallet, priceFeed, assets);

  return (
    <>
      <Grid xs={4}>
        <WalletBalanceInfoCard
          title="Total"
          cryptoBalance={balances.totalBalanceCrypto || 0}
          cryptoTicker={(wallet && wallet.assetTicker) || ""}
          usdBalance={formatDollarAmountUsd(balances.totalBalanceUsd || 0)}
        />
      </Grid>
      <Grid xs={4}>
        <WalletBalanceInfoCard
          title="Available"
          cryptoBalance={balances.availableBalanceCrypto || 0}
          cryptoTicker={(wallet && wallet.assetTicker) || ""}
          usdBalance={formatDollarAmountUsd(balances.availableBalanceUsd || 0)}
        />
      </Grid>
      <Grid xs={4}>
        <WalletBalanceInfoCard
          title="Pending"
          cryptoBalance={balances.pendingBalanceCrypto || 0}
          cryptoTicker={(wallet && wallet.assetTicker) || ""}
          usdBalance={formatDollarAmountUsd(balances.pendingBalanceUsd || 0)}
        />
      </Grid>
    </>
  );
};
