import { RootContextType, useRootContext } from "../../RootLayout";
import {
  Await,
  defer,
  Link as RouterLink,
  LoaderFunctionArgs,
  useFetcher,
  useLoaderData,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  AllowlistAddress,
  AllowlistAddressStatusEnum,
  CreateTransactionRequest,
  CreateTransactionRequestTypeEnum,
  PriceInfo,
  Wallet,
  WalletTransaction,
  WalletType,
} from "../../services/openAPI/client";
import {
  Alert,
  Button,
  color,
  DialogTitle,
  Divider,
  formatCryptoQuantity,
  formatWalletAddress,
  Icons,
  TextField,
  Typography,
} from "@bakkt/bakkt-ui-components";
import {
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  SvgIcon,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Unstable_Grid2 as Grid,
  Container,
  Box,
  Chip,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  checkWarmBalance,
  checkWarmWallet,
  getAssetQtyByPrice,
  getAvailableAndPendingBalances,
  getGasBalanceForFeeWallet,
  getPriceByAssetQty,
  getSVGStringForTicker,
  isERC20Coin,
  isTrustWallet,
  MAX_WITHDRAW_LIMIT,
  shouldUseMockData,
} from "../../utils/dataUtils";
import { CryptoTickerEnum, WalletTemp } from "../../utils/CryptoIconsMap";
import { allow, fetchMockDataPromiseWithDelay } from "../../services/mockData";
import { AllowListService, WalletTransactionService } from "../../services/serviceLoader";
import { WalletBalanceAvailableOnly } from "./balances/WalletBalanceAvailableOnly";
import { WalletBalanceAvailableMinimal } from "./balances/WalletBalanceAvailableMinimal";
import ReviewWithdrawDetails from "./ReviewWithdrawDetails";
import { destinationType, withdrawToType } from "../../utils/customTypes";
import { formatActionErrorResponse, formatActionSuccessResponse } from "../../utils/responseHandlingUtils.ts";
import { MIN_GAS_BALANCE } from "../../utils/constants";
import { isREL009PublicFeature } from "../../config/firebaseConfig.ts";
import { AssetSVGIcon } from "../../components/customSVG/AssetSVGIcon.tsx";

export interface WithdrawMinimal {
  assetTicker?: string | null;
  fromWalletId?: number | null;
  fromWalletAddress?: string | null;
  toWalletId?: number | null;
  toWalletAddress?: string | null;
  allowListId?: number | null;
  allowListName?: string | null;
  destinationType?: string;
  quantity?: string | number | null;
  originatorId?: number;
  createdOn?: number;
  accountId?: number;
  organizationId?: number;
  clientName?: string;
  orgName?: string;
  requesterName?: string;
}

export default function WalletWithdraw() {
  const { policyAllowlistListPromise } = useLoaderData() as {
    policyAllowlistListPromise: Promise<AllowlistAddress[]>;
  };

  const fetcher = useFetcher();
  const { orgDataCache, selectedOrg, userInfo, priceFeed, setShouldRefreshPolicyItems, addAlert, assets } =
    useRootContext() as RootContextType;
  const wallets = [...orgDataCache.wallets, ...orgDataCache.omnibusWallets];
  const accountOmnibusWallets = orgDataCache.accountOmnibusWallets;
  const accountOrgs = orgDataCache.accountOrgs;
  const { state } = useLocation();
  const assetTicker = state?.ticker;

  //For ERC-20 Assets
  const [isERC20, setIsERC20] = useState<boolean>(false);
  const [feeWallet, setFeeWallet] = useState<Wallet | null>(null);
  const [gasBalance, setGasBalance] = useState<number>(0);

  const { walletId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const buttonToggleStyle = {
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: theme.palette.secondary.main,
    },
  };

  const getSelectedWallet = (id: number | null): Wallet | undefined => {
    return wallets.find((wallet: Wallet) => wallet.walletId == id);
  };

  const getSelectedAddressById = (addressId: number): AllowlistAddress => {
    return addressList.find((address) => address.id == addressId) as AllowlistAddress;
  };

  const [showCopyAddressConfirm, setShowCopyAddressConfirm] = useState(false);
  const [selectedWalletId, setSelectedWalletId] = useState<number | null>(
    walletId ? (parseInt(walletId as string) as number) : null,
  );
  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(
    getSelectedWallet(selectedWalletId as number) as Wallet,
  );
  const [selectedAsset, setSelectedAsset] = useState<string>(selectedWallet?.assetTicker || assetTicker || "BTC");
  const [selectedAddress, setSelectedAddress] = useState<AllowlistAddress | null>();
  const withdrawFormData: WithdrawMinimal = {
    assetTicker: selectedAsset,
    fromWalletId: selectedWalletId || null,
    fromWalletAddress: selectedWallet?.address || "",
    toWalletId: "" || null,
    toWalletAddress: "",
    allowListId: null,
    allowListName: null,
    destinationType: "",
    quantity: "",
    originatorId: userInfo.userId,
    accountId: userInfo.accountId,
    organizationId: selectedOrg.id,
  };

  const [withdraw, setWithdraw] = useState<WithdrawMinimal>(withdrawFormData);
  const [withdrawStep, setWithdrawStep] = useState<number>(1);
  const [allowListedAddresses, setAllowListedAddresses] = useState<AllowlistAddress[]>([]);
  const [currencyView, setCurrencyView] = useState<string>("CRYPTO");
  const [selectedAddressId, setSelectedAddressId] = useState<number | null>(null);
  const [destinationAddressType, setDestinationAddressType] = useState<string>("");

  function getDefaultWalletType() {
    const selectedSourceWallet = getSelectedWallet(parseInt(walletId || "")) as Wallet;
    return selectedSourceWallet ? selectedSourceWallet.type.valueOf() : undefined;
  }

  const [selectedWalletType, setSelectedWalletType] = useState<string | undefined>(getDefaultWalletType());
  const [selectDestinationType, setSelectDestinationType] = useState("");
  const [selectedDestinationWallet, setSelectedDestinationWallet] = useState<Wallet | null>();

  const isWarmBalance = checkWarmBalance(wallets as Wallet[], priceFeed as PriceInfo[], assets);

  const [enableCreateWithdraw, setEnableCreateWithdraw] = useState<boolean>(false);

  const removeWarmWalletAddress = (addresses: AllowlistAddress[]) => {
    return addresses.filter((address) => {
      const wallet = wallets.find((wallet) => (wallet.address as string) === address.address);

      return !wallet || wallet.temperature == WalletTemp.Cold;
    });
  };

  const filterAddressesByAsset = (asset?: string, addressListCheck?: AllowlistAddress[]) => {
    const filterBy = asset ? asset : selectedAsset;
    let addresses = addressListCheck ? addressListCheck : allowListedAddresses;
    if (destinationAddressType === destinationType.External) {
      addresses = addresses.filter((address) => address.type == destinationType.External);
    } else if (destinationAddressType === destinationType.Internal) {
      //Filter only allowlists whose type is internal and not omnibus(deposit address)
      addresses = addresses.filter((address) => address.type === destinationType.Internal && !address.tradingAddress);
    } else if (destinationAddressType === destinationType.Trading) {
      addresses = addresses.filter((address) => address.type === destinationType.Internal && address.tradingAddress);
    }

    if (!isWarmBalance) {
      addresses = removeWarmWalletAddress(addresses);
    }

    return addresses
      .filter((address) => address.assetTicker === filterBy && address.address !== selectedWallet?.address)
      .sort((a: AllowlistAddress, b: AllowlistAddress) =>
        (a.name ? a.name : "") < (b.name ? b.name : "") ? -1 : 1,
      ) as AllowlistAddress[];
  };

  const [addressList, setAddressList] = useState<AllowlistAddress[]>(filterAddressesByAsset());

  const getAllowlistedById = (addressItem: number) => {
    return allowListedAddresses.find((address) => address.id == addressItem) as AllowlistAddress;
  };

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const [balances, setBalances] = useState({
    availableBalanceCrypto: 0,
    availableBalanceUsd: 0,
  });

  const getWithdrawValue = () => {
    let amount = Number(withdraw.quantity);
    if (currencyView === "CRYPTO") {
      amount = getPriceByAssetQty(selectedAsset, amount, priceFeed, assets);
    }
    return amount;
  };

  const handleReviewWithdraw = () => {
    // before we progress, check if amount needs converted from
    // input USD value to crypto value
    let amount = Number(withdraw.quantity);
    if (currencyView !== "CRYPTO") {
      // get asset QTY from dollar value & assetTicker
      amount = getAssetQtyByPrice(selectedAsset, amount, priceFeed, assets);
      // convert back to string
      updateField("quantity", amount.toString());
    }
    setWithdrawStep(withdrawStep + 1);
  };

  const updateField = (fieldName: string, value: string | number | boolean) => {
    setWithdraw((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const resetAllValues = () => {
    // wipe selected addresses
    setSelectedAddress(null);
    setSelectedAddressId(null);
    // reset selected wallet to param
    setSelectedWalletId(null);
    setSelectedWallet(null);
    setSelectDestinationType("");
    setSelectedDestinationWallet(null);
    // reset the formData
    setWithdraw(withdrawFormData);
  };

  function updateERCChanges(updatedTicker?: string, updatedWalletId?: number | undefined) {
    if (updatedTicker) {
      setIsERC20(isERC20Coin(updatedTicker));
    }
    const fromWallet: Wallet | null = wallets.find((wallet) => wallet.walletId === updatedWalletId) || null;
    const feeWallet = wallets.find((w) => fromWallet?.feeWalletId === w.walletId) || null;

    const gasBalance = getGasBalanceForFeeWallet(feeWallet, priceFeed, assets) || 0;
    setGasBalance(gasBalance);
    setFeeWallet(feeWallet);
  }

  const handleChangeAsset = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    // set the selected asset
    setSelectedAsset(value as string);
    // apply filter to address list
    setAddressList(filterAddressesByAsset(value));
    // reset any lower form values
    resetAllValues();

    //set ERC20 related variables
    updateERCChanges(value, undefined);
  };

  useEffect(() => {
    (async () => {
      await policyAllowlistListPromise.then((response) => {
        setAllowListedAddresses(response);
        setAddressList(response);
      });
    })();
  }, []);

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        setShouldRefreshPolicyItems(true);
        addAlert({
          severity: "success",
          messageHeader: "Withdraw transaction has been initiated.",
        });
      } else {
        setShouldRefreshPolicyItems(false);
        addAlert({
          severity: "error",
          messageHeader: "Error while initiating withdraw transaction.",
        });
      }
      navigate(`/${selectedOrg.id}`);
    }
  }, [fetcher.data]);

  useEffect(() => {
    if (selectedWallet) {
      setBalances(getAvailableAndPendingBalances(selectedWallet, priceFeed, assets));
      setSelectedWalletType(selectedWallet.type as WalletType);
      //set ERC20 related values
      updateERCChanges(selectedWallet?.assetTicker || "", selectedWallet.walletId);
    }
  }, [selectedWallet]);

  const handleChangeWallet = async (event: SelectChangeEvent) => {
    // on wallet select, set up full allowlist & filtered address lists
    await policyAllowlistListPromise.then((response) => {
      setAllowListedAddresses(response);
      setAddressList(response);
    });
    const newSelectedWallet = getSelectedWallet(parseInt(event.target.value)) as Wallet;
    // update formData
    updateField("fromWalletId", event.target.value);
    updateField("fromWalletAddress", newSelectedWallet!.address as string);
    updateField("assetTicker", newSelectedWallet.assetTicker as string);
    // set values
    setSelectedWallet(newSelectedWallet as Wallet);
    setSelectedWalletId(newSelectedWallet.walletId as number);
    setSelectedAsset(newSelectedWallet.assetTicker as string);
    // changing wallet should not reset lower form values

    //set ERC20 related values
    updateERCChanges(undefined, newSelectedWallet.walletId);
  };

  const handleChangeOwner = (event: React.ChangeEvent<HTMLInputElement>) => {
    // set the ownertype
    const destination = event.target.value;

    if (destination === destinationType.Trading) {
      setSelectDestinationType(withdrawToType.Wallet);
    } else {
      setSelectDestinationType(withdrawToType.Allowlist);
    }

    setDestinationAddressType(destination);
    // reset selected address values & formData
    setSelectedAddress(null);
    setSelectedAddressId(null);
    updateField("toWalletAddress", "");
    updateField("toWalletId", "");
    updateField("destinationType", destination);
  };

  const handleChangeAddress = (event: SelectChangeEvent) => {
    // set allowList values & formData
    const allowListItem = getAllowlistedById(Number(event.target.value)) as AllowlistAddress;
    updateWithdrawWalletDestinationFields(Number(event.target.value), allowListItem);
  };

  const updateWithdrawWalletDestinationFields = (id: number, allowlist: AllowlistAddress, type?: string) => {
    //find destination wallet
    const destinationWallet = wallets.find((wallet) =>
      type === destinationType.Trading
        ? wallet.depositAddress === allowlist?.address
        : wallet.address === allowlist?.address,
    );

    setSelectedAddress(getSelectedAddressById(id) as AllowlistAddress);
    setSelectedAddressId(id);
    updateField("toWalletAddress", allowlist?.address);
    updateField("allowListId", allowlist?.id as number);
    updateField("allowListName", allowlist?.name as string);
    updateField("toWalletId", destinationWallet?.walletId as number);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent) => {
    const name = event.target.name;
    let value = event.target.value;

    if (currencyView === "CRYPTO") {
      if (value.startsWith(".")) {
        value = `0${value}`;
      } else if (value.startsWith("0") && !value.startsWith("0.") && value.length > 1) {
        value = `0.${value.substring(1)}`;
      }
    }

    const decimalRegex =
      currencyView === "CRYPTO" && selectedAsset === CryptoTickerEnum.BTC ? /^\d*(\.\d{0,8})?$/ : /^\d*(\.\d{0,18})?$/;

    if (decimalRegex.test(value)) {
      updateField(name, value);
    }
  };

  const handleCurrencyToggle = (event: React.MouseEvent<HTMLElement>, currency: string | null) => {
    const value = event.target;
    setCurrencyView(currency as string);
  };

  const handleTooltipClose = () => {
    setShowCopyAddressConfirm(false);
  };

  const copyAddress = () => {
    navigator.clipboard.writeText(selectedWallet?.address as string);
    setShowCopyAddressConfirm(true);
  };

  const isWithdrawFormValid = () => {
    const isValid = (val: any) => {
      if (val) return true;
    };
    const isPositive = (val: any) => {
      if (Number(val) > 0) return true;
    };
    const isUnderMax = (val: any) => {
      if (Number(val) < MAX_WITHDRAW_LIMIT) return true;
    };
    const isWithinAvailableBalance = (val: any) => {
      if (currencyView === "CRYPTO" && Number(val) <= balances.availableBalanceCrypto) {
        return true;
      } else if (currencyView !== "CRYPTO" && Number(val) <= balances.availableBalanceUsd) {
        return true;
      }
    };

    if (
      isValid(withdraw?.toWalletAddress) &&
      isValid(withdraw?.fromWalletAddress) &&
      isValid(withdraw?.fromWalletId) &&
      isValid(destinationAddressType) &&
      isValid(withdraw?.quantity) &&
      isPositive(withdraw?.quantity) &&
      isWithinAvailableBalance(withdraw?.quantity) &&
      isValid(withdraw?.assetTicker) &&
      isValid(withdraw?.allowListId) &&
      isValid(withdraw?.allowListName) &&
      isUnderMax(getWithdrawValue())
    )
      return true;
  };

  const isEnoughGasBalance = () => {
    if (isTrustWallet(selectedWallet) && isERC20 && gasBalance < MIN_GAS_BALANCE) {
      return false;
    }
    return true;
  };

  const handleCreateWithdraw = () => {
    const createWithdraw: CreateTransactionRequest = {
      type: CreateTransactionRequestTypeEnum.Withdraw,
      sourceWalletId: Number(withdraw.fromWalletId),
      allowlistId: Number(withdraw.allowListId),
      quantity: Number(withdraw.quantity),
      destinationWalletId: Number(withdraw.toWalletId),
    };
    fetcher.submit(JSON.stringify(createWithdraw), {
      method: "post",
      encType: "application/json",
    });
  };

  const handleWalletType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const walletType = event.target.value as WalletType;
    setSelectedWalletType(walletType as WalletType);
    setSelectedWalletId(null);
    setSelectDestinationType("");
    setSelectedDestinationWallet(null);
    setSelectedWallet(null);

    setDestinationAddressType("");
    setSelectedAddress(null);
    setSelectedAddressId(null);
  };

  const bodyHeadingSx = {
    color: color.text.secondary,
    fontWeight: "bold",
  };

  function getDisplayTextSource(wallet: Wallet | null, isSelected?: boolean) {
    const balances = getAvailableAndPendingBalances(wallet, priceFeed, assets);
    const cryptoBalance = balances.availableBalanceCrypto || 0;
    if (!wallet) {
      return <></>;
    }

    const selectedAsset = assets.find((asset) => asset.ticker === wallet.assetTicker);
    return (
      <Grid container direction={"column"}>
        <Grid container alignItems={"center"} sx={{ pb: 0 }}>
          <SvgIcon
            component={() =>
              AssetSVGIcon({
                svgString: getSVGStringForTicker(assets, wallet.assetTicker as string),
                title: wallet.assetTicker,
                sx: { width: 32, height: 32, pr: 1 },
              })
            }
            inheritViewBox
          />
          <Typography variant="h5">{selectedAsset?.name}</Typography>
          <Typography variant={"subtitle1"}> {`(${selectedOrg.name})`}</Typography>
        </Grid>
        <Grid container alignItems={"center"} sx={{ pl: 4 }}>
          <Typography variant={"body1"}>{`ID: ${formatWalletAddress(wallet?.address || "")}`}</Typography>
        </Grid>
        {!isSelected && (
          <Grid container alignItems={"center"} sx={{ pl: 4 }}>
            <Typography variant={"body1"}>{`Balance: ${formatCryptoQuantity(
              cryptoBalance as number,
              wallet?.assetTicker,
            )} ${wallet?.assetTicker}`}</Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  const filterWalletByAsset = (assetTicker: string, orgOmnibusWallets: Wallet[]) => {
    return orgOmnibusWallets.filter((wallet) => wallet.assetTicker === assetTicker);
  };

  function getDisplayTextDestination(address: AllowlistAddress | null) {
    if (!address) {
      return <></>;
    }
    return (
      <Grid container direction={"column"}>
        <Grid container alignItems={"center"} sx={{ pb: 0 }}>
          <Typography variant={"h5"}>
            {`${accountOrgs ? accountOrgs.find((org) => org.id === address?.organizationId)?.name : ""}`}
          </Typography>
        </Grid>
        <Grid container alignItems={"center"} sx={{ pl: 0 }}>
          <Typography variant={"body1"}>{`${formatWalletAddress(address?.tradingAddress || "")}`}</Typography>
        </Grid>
      </Grid>
    );
  }
  const disableOmnibus = orgDataCache.omnibusWallets.length === 0;

  function getDisplayTextDestinationWrapper(
    selectedAddress: AllowlistAddress | null,
    selectedAddressId: number | null,
    destinationAddressType: string,
  ) {
    if (selectedAddressId && destinationAddressType === destinationType.Trading) {
      return getDisplayTextDestination(selectedAddress || null);
    } else {
      return selectedAddress?.address;
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
      {withdrawStep == 1 && (
        <>
          <DialogTitle title="Withdraw" severity="secondary">
            Specify your withdrawal details below. Withdrawals can only be sent to allowlist addresses. New addresses
            may require more than 48 hours for approval before they can be used.
          </DialogTitle>
          <DialogContent>
            {isTrustWallet(selectedWallet) && isERC20 && gasBalance < MIN_GAS_BALANCE && (
              <Alert severity="warning" sx={{ mb: 2 }}>
                <Typography variant={"subtitle2"}>
                  This ERC-20 asset’s fee wallet has insufficient funds of {gasBalance} ETH.
                  <Typography variant={"subtitle2"}>
                    ERC-20 assets require a minimum balance of {MIN_GAS_BALANCE} ETH in {feeWallet?.name} wallet for GAS
                    fees.
                  </Typography>
                  <Typography variant={"subtitle2"}>
                    *Please fund this fee wallet {feeWallet?.address} with at least {MIN_GAS_BALANCE} ETH in order to
                    proceed
                  </Typography>
                </Typography>
              </Alert>
            )}
            <Typography variant="h5" sx={{ mb: 1 }}>
              Source
            </Typography>
            <Container disableGutters maxWidth={"lg"}>
              <Grid container direction={"row"} columnGap={4}>
                <Grid xs={6}>
                  {isREL009PublicFeature && (
                    <FormControl variant="standard" required>
                      <RadioGroup name="wallet-type" onChange={handleWalletType} value={selectedWalletType} row>
                        <FormControlLabel value={WalletType.Custody} control={<Radio />} label="Segregated Wallet" />
                        <Stack direction={"row"} alignContent={"flex-end"} alignItems={"flex-end"} sx={{ p: 0, m: 0 }}>
                          <FormControlLabel
                            value={WalletType.Trading}
                            control={<Radio />}
                            label="Omnibus Wallet"
                            sx={{ mr: 0 }}
                            disabled={disableOmnibus}
                          />
                          {disableOmnibus && (
                            <Tooltip
                              title={
                                <Typography variant="body2">
                                  Please activate omnibus in the dashboard in order to create omnibus wallets.
                                </Typography>
                              }
                              placement="top"
                            >
                              <Box>
                                <SvgIcon
                                  component={Icons.InformationIcon}
                                  sx={{ width: 22, height: 22, color: color.text.disabled }}
                                />
                              </Box>
                            </Tooltip>
                          )}
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                  )}
                </Grid>
                <Grid xs={6}>
                  {selectedWalletType !== WalletType.Trading ? (
                    <>
                      <FormControl variant="standard" sx={{ width: "100%", mb: 3 }} required>
                        <InputLabel id="select-asset">Asset</InputLabel>
                        <Select
                          name="assetTicker"
                          value={selectedAsset ? (selectedAsset as any) : ""}
                          label="asset"
                          onChange={handleChangeAsset}
                        >
                          {assets.map((asset, index) => (
                            <MenuItem key={index} value={asset.ticker} disabled={asset.ticker === CryptoTickerEnum.ETC}>
                              {asset.ticker === CryptoTickerEnum.ETC ? (
                                <>
                                  <Grid container alignItems={"center"} sx={{ p: 1, pb: 0 }}>
                                    <SvgIcon
                                      component={() =>
                                        AssetSVGIcon({
                                          svgString: asset.iconSVG,
                                          title: asset.ticker,
                                          sx: { width: 32, height: 32, pr: 1 },
                                        })
                                      }
                                      inheritViewBox
                                    />
                                    <Typography sx={{ fontWeight: 600, mr: 1 }}>{asset.name}</Typography>
                                    <Typography variant={"body2"} sx={{ mr: 1 }}>
                                      {asset.ticker}
                                    </Typography>
                                    <Chip key={asset.ticker} label={"Contact Support"} />
                                  </Grid>
                                </>
                              ) : (
                                <>
                                  <Grid container alignItems={"center"} sx={{ p: 1, pb: 0 }}>
                                    <SvgIcon
                                      component={() =>
                                        AssetSVGIcon({
                                          svgString: asset.iconSVG,
                                          title: asset.ticker,
                                          sx: { width: 32, height: 32, pr: 1 },
                                        })
                                      }
                                      inheritViewBox
                                    />
                                    <Typography sx={{ fontWeight: 600, mr: 1 }}>{asset.name}</Typography>
                                    <Typography variant={"body2"}>{asset.ticker}</Typography>
                                  </Grid>
                                </>
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl variant="standard" sx={{ width: "100%" }} required>
                        <InputLabel id="select-wallet">Wallet</InputLabel>
                        <Select
                          name="fromWalletId"
                          value={selectedWalletId ? (selectedWalletId as any) : ""}
                          renderValue={() => `${selectedWallet?.name}`}
                          label="Wallet"
                          onChange={handleChangeWallet}
                          MenuProps={{
                            sx: { maxHeight: 300 },
                          }}
                        >
                          {wallets
                            .filter(
                              (wallet) =>
                                wallet.assetTicker === selectedAsset &&
                                wallet.type === WalletType.Custody &&
                                checkWarmWallet(isWarmBalance, wallet.temperature as string),
                            )
                            .map((wallet) => (
                              <MenuItem key={wallet.walletId} value={wallet.walletId}>
                                <Box>
                                  <Typography variant="h5">{wallet.name}</Typography>
                                  <Typography variant={"body1"}>{`ID: ${wallet.walletId}`}</Typography>
                                </Box>
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <FormControl variant="standard" sx={{ width: "100%" }} required>
                        <InputLabel id="select-wallet">Source omnibus wallet</InputLabel>
                        <Select
                          name="fromWalletId"
                          value={selectedWallet?.walletId ? (selectedWallet?.walletId as any) : ""}
                          renderValue={() => getDisplayTextSource(selectedWallet || null, true)}
                          onChange={handleChangeWallet}
                          label="Wallet"
                          MenuProps={{
                            sx: { maxHeight: 300 },
                          }}
                        >
                          {wallets
                            .filter((wallet) => wallet.type === WalletType.Trading)
                            .map((wallet) => (
                              <MenuItem key={wallet.walletId} value={wallet.walletId}>
                                {getDisplayTextSource(wallet)}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                </Grid>

                <Divider orientation="vertical" flexItem />

                <Grid container direction={"column"}>
                  {selectedWallet ? (
                    <>
                      {selectedWalletType === WalletType.Custody && (
                        <Box sx={{ mb: 3 }}>
                          <Typography variant="body2" sx={bodyHeadingSx}>
                            {selectedWallet.name} ADDRESS
                          </Typography>
                          {formatWalletAddress(selectedWallet.address as string)}
                          <ClickAwayListener onClickAway={handleTooltipClose}>
                            <Tooltip
                              onClose={handleTooltipClose}
                              open={showCopyAddressConfirm}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              placement="top"
                              title="Address Copied"
                            >
                              <IconButton sx={{ m: 0, p: 0 }} onClick={copyAddress} disableRipple disableFocusRipple>
                                <SvgIcon
                                  component={Icons.CopyIcon}
                                  inheritViewBox
                                  sx={{ width: "15px", height: "15px", ml: 1, mr: 1.2 }}
                                />
                              </IconButton>
                            </Tooltip>
                          </ClickAwayListener>
                          <IconButton sx={{ m: 0, p: 0 }} disableRipple={true}>
                            <SvgIcon component={Icons.GoToIcon} inheritViewBox sx={{ width: "16px", height: "16px" }} />
                          </IconButton>
                        </Box>
                      )}
                      {selectedWalletId && (
                        <WalletBalanceAvailableOnly walletId={selectedWalletId.toString() as string} />
                      )}
                    </>
                  ) : (
                    <>
                      <Typography variant="body2" sx={bodyHeadingSx}>
                        AMOUNT AVAILABLE
                      </Typography>
                      <Typography variant="h5">Wallet Not Selected</Typography>
                      <Typography variant="subtitle1">$0.00</Typography>
                    </>
                  )}
                </Grid>
              </Grid>

              <Divider sx={{ my: 5 }} />
              <Grid container direction={"column"} justifyContent={"space-between"}>
                {selectedWallet && (
                  <>
                    <Typography variant="h5">Destination</Typography>

                    <Grid sx={{ mb: 3 }}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="owner-label"
                          name="owner"
                          onChange={handleChangeOwner}
                          value={destinationAddressType}
                        >
                          <FormControlLabel
                            value={destinationType.Internal}
                            control={<Radio />}
                            label={isREL009PublicFeature ? "Segregated Internal" : selectedOrg.name}
                          />
                          <FormControlLabel
                            value={destinationType.External}
                            control={<Radio />}
                            label={
                              isREL009PublicFeature
                                ? "Segregrated External Wallet"
                                : "External Entity or Financial Institution"
                            }
                          />
                          {isREL009PublicFeature && selectedWalletType === WalletType.Custody && (
                            <Stack
                              direction={"row"}
                              alignContent={"flex-end"}
                              alignItems={"flex-end"}
                              sx={{ p: 0, m: 0 }}
                            >
                              <FormControlLabel
                                value={destinationType.Trading}
                                control={<Radio />}
                                label="Omnibus Wallet"
                                sx={{ mr: 0 }}
                                disabled={disableOmnibus}
                              />
                              {disableOmnibus && (
                                <Tooltip
                                  title={
                                    <Typography variant="body2">
                                      Please activate omnibus in the dashboard in order to create omnibus wallets.
                                    </Typography>
                                  }
                                  placement="top"
                                >
                                  <Box>
                                    <SvgIcon
                                      component={Icons.InformationIcon}
                                      sx={{ width: 22, height: 22, color: color.text.disabled }}
                                    />
                                  </Box>
                                </Tooltip>
                              )}
                            </Stack>
                          )}
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid container xs={12} alignItems={"flex-end"} justifyContent={"space-between"} sx={{ mb: 3 }}>
                      <Grid xs={destinationAddressType === destinationType.External ? 10 : 12}>
                        <React.Suspense>
                          <Await resolve={policyAllowlistListPromise} errorElement={<></>}>
                            <>
                              <FormControl variant="standard" sx={{ width: "100%" }} required>
                                <InputLabel id="select-address">Address</InputLabel>
                                <Select
                                  name="toWalletAddress"
                                  id="address"
                                  value={selectedAddressId ? (selectedAddressId as any) : ""}
                                  label="Address"
                                  renderValue={() =>
                                    getDisplayTextDestinationWrapper(
                                      selectedAddress || null,
                                      selectedAddressId,
                                      destinationAddressType,
                                    )
                                  }
                                  onChange={handleChangeAddress}
                                  disabled={!destinationAddressType}
                                  MenuProps={{
                                    sx: { maxHeight: 300 },
                                  }}
                                >
                                  {filterAddressesByAsset(selectedAsset, addressList)?.length < 1 ? (
                                    <MenuItem key={0} value={""}>
                                      <Box>
                                        <Typography variant="h5">No Approved Addresses For This Selection</Typography>
                                      </Box>
                                    </MenuItem>
                                  ) : (
                                    filterAddressesByAsset(selectedAsset, addressList).map(
                                      (address: AllowlistAddress) => (
                                        <MenuItem key={address.id} value={address.id}>
                                          <Box>
                                            {!address.tradingAddress && (
                                              <Typography variant="h5">{`${address.name}`}</Typography>
                                            )}
                                            {destinationAddressType === destinationType.Trading
                                              ? getDisplayTextDestination(address)
                                              : `Address:${address?.address}`}
                                          </Box>
                                        </MenuItem>
                                      ),
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </>
                          </Await>
                        </React.Suspense>
                      </Grid>

                      {destinationAddressType === destinationType.External && (
                        <RouterLink to={`/allowlist/${Number(selectedOrg.id)}`}>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ pointerEvents: "auto" }}
                            data-testid="newAllowButton"
                          >
                            <SvgIcon
                              component={Icons.PlusIcon}
                              sx={{ width: "20px", height: "20px", marginRight: "6px" }}
                            />
                            Add Address
                          </Button>
                        </RouterLink>
                      )}
                    </Grid>

                    <Grid container xs={12} alignItems={"flex-end"} justifyContent={"space-between"} sx={{ mb: 3 }}>
                      <Grid xs={10}>
                        <FormControl fullWidth variant="standard" required>
                          <TextField
                            name="quantity"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {currencyView === "CRYPTO" ? selectedAsset : "USD $"}
                                </InputAdornment>
                              ),
                            }}
                            value={withdraw.quantity}
                            onChange={handleInputChange}
                            label="Amount"
                            variant="standard"
                            required
                          />
                        </FormControl>
                      </Grid>

                      <FormControl>
                        <ToggleButtonGroup
                          value={currencyView}
                          exclusive
                          onChange={handleCurrencyToggle}
                          aria-label="currency-view"
                        >
                          <ToggleButton value="CRYPTO" aria-label="left aligned" sx={buttonToggleStyle}>
                            <SvgIcon
                              component={Icons.CryptoIcon}
                              inheritViewBox
                              sx={{ width: "18px", height: "18px" }}
                            />
                          </ToggleButton>
                          <ToggleButton value="USD" aria-label="centered" sx={buttonToggleStyle}>
                            <SvgIcon component={Icons.CashIcon} inheritViewBox sx={{ width: "18px", height: "18px" }} />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </FormControl>
                    </Grid>

                    <Grid>
                      <WalletBalanceAvailableMinimal
                        ticker={selectedAsset}
                        amount={withdraw.quantity as string}
                        view={currencyView}
                      />
                      <Typography variant="body2">Maximum Withdraw: $20,000,000</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Container>
          </DialogContent>
          <DialogActions>
            <Button variant={"contained"} onClick={handleClose} autoFocus>
              Close
            </Button>
            <Button
              variant={"contained"}
              onClick={handleReviewWithdraw}
              disabled={!(isWithdrawFormValid() && isEnoughGasBalance())}
              autoFocus
            >
              Review Withdraw
            </Button>
          </DialogActions>
        </>
      )}
      {withdrawStep == 2 && (
        <>
          <DialogTitle title="Review Withdraw" severity="warning">
            Review your withdrawal details below.
          </DialogTitle>
          <DialogContent>
            <ReviewWithdrawDetails
              withdrawRequest={withdraw}
              allowListedAddresses={allowListedAddresses}
              setEnableWithdraw={setEnableCreateWithdraw}
            />
          </DialogContent>
          <DialogActions>
            <Button variant={"contained"} onClick={handleClose} autoFocus>
              Close
            </Button>
            <Button variant={"contained"} autoFocus onClick={handleCreateWithdraw} disabled={!enableCreateWithdraw}>
              Create Withdraw Request
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export async function loader({ params }: LoaderFunctionArgs) {
  try {
    const orgId = Number(params.organizationId);
    const walletId = Number(params.walletId);

    const policyAllowlistListPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(allow, 500)
      : AllowListService.getAllowlistAddresses(orgId, undefined, AllowlistAddressStatusEnum.Approved);

    return defer({
      policyAllowlistListPromise: policyAllowlistListPromise,
    });
  } catch (e) {
    console.log("Error fetching transaction data: ", e);
    return {};
  }
}

export async function action({ request }: { request: Request }) {
  try {
    const createWalletTransaction = (await request.json()) as CreateTransactionRequest;
    const createTransactionResponse = await WalletTransactionService.createTransaction(createWalletTransaction);
    return formatActionSuccessResponse(createTransactionResponse);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
