import React, { memo, useState } from "react";
import {
  GridColDef,
  GridPagination,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";
import { color, DataGridPremium, formatUnixTime, Icons, Pagination, Tab } from "@bakkt/bakkt-ui-components";
import Paper from "@mui/material/Paper";
import {
  Tabs,
  Unstable_Grid2 as Grid,
  TablePaginationProps,
  Box,
  IconButton,
  SvgIcon,
  Tooltip,
  Snackbar,
} from "@mui/material";
import { HistorySummary } from "../../services/openAPI/client";
import { useAsyncValue } from "react-router-dom";
import { useRootContext } from "../../RootLayout";

enum AuditLogsTabEnum {
  Organization = "Organization",
  Account = "Account",
}

interface AuditLogsGridProps {
  accountHistories: HistorySummary[];
  orgHistories: HistorySummary[];
}

interface HistoryItemWithId extends HistorySummary {
  id: number;
}

export const AuditLogsGrid = memo(function ({ accountHistories, orgHistories }: AuditLogsGridProps) {
  const tabs = accountHistories
    ? [AuditLogsTabEnum.Organization, AuditLogsTabEnum.Account]
    : [AuditLogsTabEnum.Organization];
  const [selectedTab, setSelectedTab] = useState(AuditLogsTabEnum.Organization);
  const [copyId, setCopyId] = useState(false);
  const { selectedOrg } = useRootContext();

  const mapFauxIds = (list: HistorySummary[]): HistoryItemWithId[] => {
    return list.map((item, i) => {
      return {
        ...item,
        id: i,
      };
    });
  };

  const filteredHistories =
    selectedTab === AuditLogsTabEnum.Organization ? mapFauxIds(orgHistories) : mapFauxIds(accountHistories);

  const handleTabChange = (_: React.SyntheticEvent, value: AuditLogsTabEnum) => {
    setSelectedTab(value);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Grid container xs={12} justifyContent="space-between" sx={{ mb: 2 }}>
          <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor={"secondary"}>
            {tabs.map((tab) => (
              <Tab key={tab} value={tab} label={tab}></Tab>
            ))}
          </Tabs>
          <GridToolbarQuickFilter />
        </Grid>
        <Grid container xs={12}>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{
              fileName: `${selectedOrg.name} Audit Log ${formatUnixTime(Date.now())}`,
            }}
          />
        </Grid>
      </GridToolbarContainer>
    );
  };

  const paginationSx = {
    "& .MuiPagination-ul": {
      flexWrap: "nowrap",
    },
  };

  function MuiPagination({ page, onPageChange }: Pick<TablePaginationProps, "page" | "onPageChange">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
        sx={paginationSx}
        showFirstButton
        showLastButton
      />
    );
  }

  const dataGridSx = {
    ".MuiDataGrid-cell": {
      whiteSpace: "normal !important",
      wordWrap: "break-word !important",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: color.provocativePlum[50],
    },
  };

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={MuiPagination} {...props} />;
  }

  const handleCopyID = (ID: string) => {
    setCopyId(true);
    navigator.clipboard.writeText(ID);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "createdAt",
      headerName: "TIME",
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          return <span>{formatUnixTime(params.value, "long")}</span>;
        }
      },
      flex: 1.2,
    },

    {
      field: "type",
      headerName: "TYPE",
      flex: 0.8,
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 2,
      renderCell(params) {
        return params.value?.replaceAll("_", " ");
      },
    },
    {
      field: "userName",
      headerName: "USER",
      flex: 1,
    },
    {
      field: "itemId",
      headerName: "CORRELATION ID",
      flex: 1.2,
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          return (
            <Box>
              <IconButton aria-label="copy" onClick={() => handleCopyID(params.value)}>
                <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={{ width: 15, height: 15 }} />
              </IconButton>
              <Tooltip title={params.value}>
                <span>{params.value}</span>
              </Tooltip>
            </Box>
          );
        }
      },
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
      },
    },
  };

  const data = { columns, initialState, rows: filteredHistories };

  return (
    <>
      <Paper>
        <Grid container>
          <DataGridPremium
            density="comfortable"
            rowHeight={60}
            slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: {
                  debounceMs: 500,
                },
              },
            }}
            {...data}
            initialState={{
              ...data.initialState,
              sorting: {
                ...data.initialState,
                sortModel: [{ field: "createdAt", sort: "desc" }],
              },
              pagination: {
                paginationModel: { pageSize: 50, page: 0 },
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            pagination
            disableRowSelectionOnClick
            sx={dataGridSx}
          />
        </Grid>
        <Snackbar
          open={copyId}
          onClose={() => {
            setCopyId(false);
          }}
          autoHideDuration={2000}
          message={`Copied ID to clipboard`}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        />
      </Paper>
    </>
  );
});
