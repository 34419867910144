import { Alert, AlertTitle, Unstable_Grid2 as Grid } from "@mui/material";
import React from "react";
import { useRootContext } from "../../RootLayout.tsx";

export default function Alerts() {
  const { alerts, setAlerts } = useRootContext();

  const handleDeleteAlert = (index: number) => {
    setAlerts((prevAlerts) => {
      const updatedAlerts = [...prevAlerts];
      updatedAlerts.splice(index, 1);
      return updatedAlerts;
    });
  };

  return (
    <Grid container direction={"column"} alignItems={"center"} spacing={2}>
      {alerts.map((alert, index) => (
        <Grid key={"alert-" + index} xs={12}>
          <Alert severity={alert.severity} onClose={() => handleDeleteAlert(index)}>
            {alert.messageHeader && <AlertTitle>{alert.messageHeader}</AlertTitle>}
            {alert.message}
          </Alert>
        </Grid>
      ))}
    </Grid>
  );
}
