import { Container, Chip, Card, CardContent, Divider, Stack, Box } from "@mui/material";
import { Unstable_Grid2 as Grid } from "@mui/material";
import { Typography, Paper, Alert, Link } from "@bakkt/bakkt-ui-components";
import {
  cardContentActiveUserTop,
  cardContentUserBottom,
  userPremissionsChip,
  userFontWeightNormal,
  pendingColor,
  lockedColor,
  userEditDivider,
  cardContentDeactivatedUserTop,
  noDeactivatedUsers,
  cardWidth,
  cardGridContainer,
} from "./styles";
import React, { useState } from "react";
import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { Link as RouterLink, Await, useLoaderData } from "react-router-dom";
import { OrganizationPermission, OrganizationLevelPermission, Status, User } from "../../services/openAPI/client";
import styles from "./Organization.module.css";
import { useRootContext } from "../../RootLayout";
import { isInitiator, isTeamManager } from "../../utils/permissionsUtil";

export const OrganizationUsers = () => {
  const { selectedOrg, userInfo } = useRootContext();
  const { usersPromise }: any = useLoaderData() as {
    usersPromise: Promise<User[]>;
  };

  const [editUser, setEditUser] = useState<number | undefined>();

  const renderUserCard = (isDisabledUser: boolean, users: User[]) => {
    const filteredUsers = isDisabledUser
      ? users.filter((user: User) => user.status !== Status.Deleted)
      : users.filter((user: User) => user.status === Status.Deleted);

    return filteredUsers;
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container spacing={2} sx={{ mt: 5 }}>
        <Grid>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Users
          </Typography>
        </Grid>
      </Grid>

      <Paper>
        <Grid container sx={{ p: 2 }}>
          <Grid container sx={{ py: 2 }} xs={12}>
            <Grid>
              <Typography variant="h4" sx={userFontWeightNormal}>
                Active Users
              </Typography>
            </Grid>
          </Grid>
          <React.Suspense
            fallback={
              <Stack sx={{ mt: 12, mb: 7, width: "100%" }}>
                <LoadingIndicator />
              </Stack>
            }
          >
            <Await
              resolve={usersPromise}
              errorElement={<Alert severity="error">Error loading organization data!</Alert>}
            >
              {(users: User[]) => (
                <Grid container sx={cardGridContainer} rowGap={2}>
                  {renderUserCard(true, users).map((user: User) => (
                    <Grid xs={3} key={user.id + "-ActiveUsers"}>
                      <Card
                        onMouseEnter={() => setEditUser(user.id)}
                        onMouseLeave={() => setEditUser(undefined)}
                        className={styles["card"]}
                        sx={cardWidth}
                      >
                        <CardContent sx={cardContentActiveUserTop} className={styles["top"]}>
                          <Stack direction="column">
                            <Typography variant="body1">
                              {user.name.firstName} {user.name.lastName}
                            </Typography>
                            <Typography variant="body2">{user.email}</Typography>
                            {user.status === Status.PendingActivation ? (
                              <Typography sx={pendingColor} variant="body2">
                                Pending Activation
                              </Typography>
                            ) : null}
                            {user.status === Status.PendingChanges ? (
                              <Typography sx={pendingColor} variant="body2">
                                Pending Changes
                              </Typography>
                            ) : null}
                            {user.status === Status.Locked ? (
                              <Typography sx={lockedColor} variant="body2">
                                Locked
                              </Typography>
                            ) : null}
                            {editUser === user.id && user.status === Status.Active ? (
                              <Typography variant="body2">Active</Typography>
                            ) : null}
                          </Stack>
                          {isTeamManager(userInfo) ? (
                            editUser === user.id && (
                              <Stack direction="row" alignItems={"flex-top"}>
                                <RouterLink to={`changeRequest/${"user"}`}>
                                  <Box component="span" className="body-links">
                                    Deactivate
                                  </Box>
                                </RouterLink>
                                <Divider orientation="vertical" variant="middle" flexItem sx={userEditDivider} />
                                <RouterLink to={`changeRequest/${"user"}`}>
                                  <Box component={"span"} className="body-links">
                                    Edit
                                  </Box>
                                </RouterLink>
                              </Stack>
                            )
                          ) : (
                            <></>
                          )}
                        </CardContent>
                        <CardContent sx={cardContentUserBottom} className={styles["bottom"]}>
                          {user.organizations!.map(
                            ({ activePermissions, organizationId }: OrganizationLevelPermission, index: number) => (
                              <Stack direction="row" spacing={1} key={index + "-userPermissionStackActiveUsers"}>
                                {activePermissions &&
                                  organizationId === Number(selectedOrg.id) &&
                                  activePermissions.map((permission: OrganizationPermission, index: number) =>
                                    permission == OrganizationPermission.View && activePermissions.length > 1 ? (
                                      <React.Fragment key={index}></React.Fragment>
                                    ) : (
                                      <Chip
                                        key={index + "-userChipPermissionActiveUsers"}
                                        label={permission.toLowerCase().replace("_", " ")}
                                        size="small"
                                        sx={userPremissionsChip}
                                      />
                                    ),
                                  )}
                              </Stack>
                            ),
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Await>
          </React.Suspense>
        </Grid>

        <Grid container sx={{ p: 2 }}>
          <Grid sx={{ py: 2 }} xs={12}>
            <Typography variant="h4" sx={userFontWeightNormal}>
              Deactivated Users
            </Typography>
          </Grid>

          <Container maxWidth="xl" disableGutters>
            <React.Suspense>
              <Await
                resolve={usersPromise}
                errorElement={<Alert severity="error">Error loading organization data!</Alert>}
              >
                {(users: User[]) => (
                  <Grid container sx={cardGridContainer} rowGap={2}>
                    {renderUserCard(false, users).length === 0 ? (
                      <Grid xs={12} sx={noDeactivatedUsers}>
                        <Typography variant="h4" sx={userFontWeightNormal}>
                          No deactivated users found
                        </Typography>
                      </Grid>
                    ) : (
                      renderUserCard(false, users).map((user: User) => (
                        <Grid xs={3} key={user.id + "-DeactivatedUsers"}>
                          <Card
                            onMouseEnter={() => setEditUser(user.id)}
                            onMouseLeave={() => setEditUser(undefined)}
                            className={styles["card"]}
                            sx={cardWidth}
                          >
                            <CardContent sx={cardContentDeactivatedUserTop} className={styles["top"]}>
                              <Stack direction="column">
                                <Typography variant="body1">
                                  {user.name.firstName} {user.name.lastName}{" "}
                                </Typography>
                                <Typography variant="body2">{user.email}</Typography>
                                <Typography variant="body2">Inactive</Typography>
                              </Stack>
                              {editUser === user.id && (
                                <Stack direction="row">
                                  <Link href="#" variant="body2">
                                    Activate
                                  </Link>
                                </Stack>
                              )}
                            </CardContent>
                            <CardContent sx={cardContentUserBottom} className={styles["bottom"]}>
                              {user.organizations!.map(
                                ({ activePermissions, organizationId }: OrganizationLevelPermission, index: number) => (
                                  <Stack direction="row" spacing={1} key={index + "-userPermissionStackActiveUsers"}>
                                    {activePermissions &&
                                      organizationId === Number(selectedOrg.id) &&
                                      activePermissions.map((permission: OrganizationPermission, index: number) =>
                                        permission == OrganizationPermission.View && activePermissions.length > 1 ? (
                                          <React.Fragment key={index}></React.Fragment>
                                        ) : (
                                          <Chip
                                            key={index + "-userChipPermissionActiveUsers"}
                                            label={permission.toLowerCase().replace("_", " ")}
                                            size="small"
                                            sx={userPremissionsChip}
                                          />
                                        ),
                                      )}
                                  </Stack>
                                ),
                              )}
                            </CardContent>
                          </Card>
                        </Grid>
                      ))
                    )}
                  </Grid>
                )}
              </Await>
            </React.Suspense>
          </Container>
        </Grid>
      </Paper>
    </Container>
  );
};
