import { WalletTransaction } from "../../../services/openAPI/client";
import { SvgIcon, Unstable_Grid2 as Grid, Stack } from "@mui/material";
import { formatCryptoQuantity, formatDollarAmountUsd, Icons, Typography } from "@bakkt/bakkt-ui-components";
import { AssetSVGIcon } from "../../../components/customSVG/AssetSVGIcon.tsx";
import { getDecimalLength, getPriceByAssetQty, getSVGStringForTicker } from "../../../utils/dataUtils.ts";
import { useRootContext } from "../../../RootLayout.tsx";

export interface SettlementLineItem {
  accountId?: number;
  organizationId?: number;
  clientName?: string;
  orgName?: string;
  requesterName?: string;
  createdOn?: number;
  settlementTransaction: WalletTransaction;
}

interface SettlementLineItemProps {
  settlementLineItem: SettlementLineItem;
}

export const SettlementLineItem = ({ settlementLineItem }: SettlementLineItemProps) => {
  const { priceFeed, assets } = useRootContext();

  return (
    <>
      <Grid container xs={12} justifyContent={"space-between"} sx={{ mb: 3 }}>
        <Grid xs={10}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <SvgIcon
              component={() =>
                AssetSVGIcon({
                  svgString: getSVGStringForTicker(assets, settlementLineItem.settlementTransaction.assetTicker || ""),
                  title: settlementLineItem.settlementTransaction.assetTicker || "",
                  sx: { width: 20, height: 20 },
                })
              }
              inheritViewBox
            />
            <Typography variant="subtitle1">{settlementLineItem.settlementTransaction.assetTicker || ""}</Typography>
            <SvgIcon component={Icons.ArrowRightIcon} inheritViewBox sx={{ width: 10, height: 15 }} />
            <Typography variant="subtitle1">
              {settlementLineItem.settlementTransaction.destinationName || ""}
            </Typography>
          </Stack>
        </Grid>
        <Grid direction="row" sx={{ textAlign: "right" }}>
          <Typography variant="subtitle1">
            {formatCryptoQuantity(
              Number(settlementLineItem.settlementTransaction.quantity) || 0,
              settlementLineItem.settlementTransaction.assetTicker || "",
              "long",
              getDecimalLength(Number(settlementLineItem.settlementTransaction.quantity)),
            )}{" "}
            {settlementLineItem.settlementTransaction.assetTicker || ""}
          </Typography>
          <Typography variant="subtitle1">
            {formatDollarAmountUsd(
              getPriceByAssetQty(
                settlementLineItem.settlementTransaction.assetTicker as string,
                Number(settlementLineItem.settlementTransaction.quantity),
                priceFeed,
                assets,
              ) || 0,
            )}{" "}
            USD
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
