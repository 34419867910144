import { Await, defer, LoaderFunctionArgs, Outlet, useLoaderData } from "react-router-dom";
import { shouldUseMockData } from "../../utils/dataUtils";
import { fetchMockDataPromiseWithDelay, organizations, users } from "../../services/mockData";
import { OrganizationService, UserService } from "../../services/serviceLoader";
import { subText, wrapperContainer } from "../dashboard/styles";
import { Stack, Unstable_Grid2 as Grid } from "@mui/material";
import { Alert, Paper, Typography } from "@bakkt/bakkt-ui-components";
import { Container } from "@mui/material";
import React from "react";
import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { ManageUsersGrid } from "./ManageUsersGrid";
import { User, Organization } from "../../services/openAPI/client";
import { useRootContext } from "../../RootLayout";

const ManageUsers = () => {
  const { usersPromise, orgsPromise } = useLoaderData() as {
    usersPromise: Promise<User[]>;
    orgsPromise: Promise<Organization[]>;
  };
  return (
    <>
      <Outlet context={useRootContext()} />
      <Container maxWidth="xl" disableGutters sx={wrapperContainer}>
        <Grid container spacing={2}>
          <Grid xs={9}>
            <Typography variant="h3">User Management</Typography>
            <Typography variant="body1" sx={subText}>
              Showing all account users.
            </Typography>
          </Grid>
        </Grid>

        <Paper>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Container data-testid="manageUsersTable">
                <React.Suspense
                  fallback={
                    <Stack sx={{ mt: 12, mb: 7 }}>
                      <LoadingIndicator />
                    </Stack>
                  }
                >
                  <Await
                    resolve={Promise.all([usersPromise, orgsPromise]).then(([users, orgs]) => ({ users, orgs }))}
                    errorElement={<Alert severity="error">Error loading users data!</Alert>}
                  >
                    <ManageUsersGrid />
                  </Await>
                </React.Suspense>
              </Container>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default ManageUsers;

export async function loader({ params }: LoaderFunctionArgs) {
  try {
    const accountId = Number(params.accountId);
    const usersPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(users, 200)
      : UserService.getUsers(accountId);

    const orgsPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(organizations, 200)
      : OrganizationService.getOrganizations(accountId);

    return defer({
      usersPromise: usersPromise,
      orgsPromise: orgsPromise,
    });
  } catch (e) {
    console.log("Error fetching dashboard data: ", e);
    return {};
  }
}
