import { fetchMockDataPromiseWithDelay, userInfo as userInfoMock } from "../services/mockData.ts";
import { FusionAuthService } from "../services/serviceLoader.ts";
import { API_URL, shouldUseMockData, USER_SESSION_KEY } from "./dataUtils.ts";
import { UserInfo } from "@bakkt/bakkt-ui-components";
import axios from "axios";

export const LOGOUT_URL =
  (window as any)["LOGOUT_URL"] == "{{LOGOUT_URL}}" ? import.meta.env.VITE_LOGOUT_URL : (window as any)["LOGOUT_URL"];

export async function handleLogout() {
  const userInfoSession: UserInfo = JSON.parse(sessionStorage.getItem(USER_SESSION_KEY) || "{}");
  const logoutResponse = shouldUseMockData
    ? await fetchMockDataPromiseWithDelay({ 200: { message: "success" } }, 1000)
    : await FusionAuthService.fusionAuthUserLogout(userInfoSession.userId);
  sessionStorage.removeItem(USER_SESSION_KEY);
  window.location.replace(LOGOUT_URL);
}

export async function getUserSession() {
  const userInfoSession = sessionStorage.getItem(USER_SESSION_KEY);
  const USER_INFO_URL = `${API_URL}/custody/userinfo`;

  const getUserInfo = async () => {
    return shouldUseMockData
      ? await fetchMockDataPromiseWithDelay(userInfoMock, 2000)
      : await axios.post(USER_INFO_URL);
  };

  const userInfo: UserInfo = userInfoSession ? JSON.parse(userInfoSession) : getUserInfo();

  return userInfo;
}
