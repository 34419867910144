import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter, RouteObject } from "react-router-dom";
import Dashboard, { loader as dashboardLoader } from "./pages/dashboard/Dashboard";
import RootLayout, { loader as rootLoader } from "./RootLayout";
import AddWallet, { action as addWalletAction } from "./pages/wallet/AddWallet";
import Organization, { loader as organizationLoader } from "./pages/organization/Organization";
import ErrorPage from "./pages/error/ErrorPage.tsx";
import { registerAxiosConfig } from "./config/axiosConfig";
import WalletDeposit from "./pages/wallet/WalletDeposit.tsx";
import WalletWithdraw, {
  loader as walletWithdrawLoader,
  action as walletWithdrawAction,
} from "./pages/wallet/WalletWithdraw.tsx";
import ManageUsers, { loader as manageUsersLoader } from "./pages/manage-users/ManageUsers";
import Allowlist, { loader as allowlistLoader } from "./pages/allowlist/Allowlist.tsx";
import AddAllowlist from "./pages/allowlist/AddAllowlist.tsx";
import { action as addAllowlistAction } from "./pages/allowlist/AdditionalInfo.tsx";
import Audit, { loader as auditLoader } from "./pages/audit/Audit.tsx";
import AllTransactions, { loader as allTransactionsLoader } from "./pages/allTransactions/AllTransactions";
import WalletDetailsDialog, {
  loader as walletDetailsLoader,
  action as walletDetailsAction,
} from "./pages/wallet/WalletDetailsDialog";
import ReviewAllowlist, { loader as reviewAllowlistLoader } from "./pages/allowlist/ReviewAllowlist.tsx";
import ConfirmAllowlist, { action as confirmAllowlistAction } from "./pages/allowlist/ConfirmAllowlist.tsx";
import DeclineAllowlist, { action as declineAllowlist } from "./pages/allowlist/DeclineAllowlist.tsx";
import ReviewWithdraw, { loader as reviewWithdrawLoader } from "./pages/wallet/ReviewWithdraw.tsx";
import ReviewTransfer, { loader as reviewTransferLoader } from "./pages/wallet/ReviewTransfer.tsx";
import ApproveDeclineWithdraw, {
  action as approveDeclineWithdrawAction,
} from "./pages/wallet/ApproveDeclineWithdraw.tsx";
import ChangeRequest, { action as changeRequest } from "./components/changeRequest/ChangeRequest";
import WalletTransfer, { action as omnibusTransferAction } from "./pages/wallet/WalletTransfer";
import { action as activateOmnibusAction } from "./pages/wallet/ActivateOmnibusWallet.tsx";
import ManageCollateral, { action as collateralAction } from "./pages/wallet/manageCollateral/ManageCollateral.tsx";
import ReviewSettlement, { loader as reviewSettlementLoader } from "./pages/wallet/settlement/ReviewSettlement.tsx";
import ReviewCollateral, {
  loader as reviewCollateralLoader,
} from "./pages/wallet/manageCollateral/ReviewCollateral.tsx";
import ApproveDeclineCollateralRequest, {
  action as approveDeclineCollateralRequestwAction,
} from "./pages/wallet/manageCollateral/ApproveDeclineCollateralRequest.tsx";

const modals: RouteObject[] = [
  {
    path: "changeRequest/:change?",
    element: <ChangeRequest />,
    action: changeRequest,
    errorElement: <ChangeRequest />,
  },
  {
    path: "addWallet/:assetSymbol?",
    element: <AddWallet />,
    action: addWalletAction,
    errorElement: <AddWallet />,
  },
  {
    path: "deposit/:walletId?",
    element: <WalletDeposit />,
    errorElement: <WalletDeposit />,
  },
  {
    path: "transfer/:walletId?",
    element: <WalletTransfer />,
    action: omnibusTransferAction,
  },
  {
    path: "withdraw/:walletId?",
    element: <WalletWithdraw />,
    loader: walletWithdrawLoader,
    action: walletWithdrawAction,
  },
  {
    path: "wallet/:walletId",
    element: <WalletDetailsDialog />,
    action: walletDetailsAction,
    loader: walletDetailsLoader,
  },
  {
    path: "collateral/:walletId",
    element: <ManageCollateral />,
    action: collateralAction,
  },
  {
    path: "review-allowlist/:allowlistId",
    element: <ReviewAllowlist />,
    loader: reviewAllowlistLoader,
  },
  {
    path: "confirm-allowlist/:policyActionId",
    element: <ConfirmAllowlist />,
    action: confirmAllowlistAction,
  },
  {
    path: "decline-allowlist/:policyActionId",
    element: <DeclineAllowlist />,
    action: declineAllowlist,
  },
  {
    path: "review-withdraw/:walletTransactionId",
    element: <ReviewWithdraw />,
    loader: reviewWithdrawLoader,
  },
  {
    path: "review-transfer/:walletTransactionId",
    element: <ReviewTransfer />,
    loader: reviewTransferLoader,
  },
  {
    path: "review-settlement/:walletTransactionId",
    element: <ReviewSettlement />,
    loader: reviewSettlementLoader,
  },
  {
    path: ":transactionAction-transaction/:policyActionId",
    element: <ApproveDeclineWithdraw />,
    action: approveDeclineWithdrawAction,
  },
  {
    path: "review-collateral/:walletTransactionId",
    element: <ReviewCollateral />,
    loader: reviewCollateralLoader,
  },
  {
    path: ":transactionAction-collateral-request/:policyActionId",
    element: <ApproveDeclineCollateralRequest />,
    action: approveDeclineCollateralRequestwAction,
  },
];

registerAxiosConfig();
const router = createBrowserRouter([
  {
    path: "/",
    id: "root",
    element: <RootLayout />,
    loader: rootLoader,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/:organizationId",
        id: "dashboard",
        element: <Dashboard />,
        loader: dashboardLoader,
        action: activateOmnibusAction,
        children: [...modals],
      },
      {
        path: "audit/:organizationId/:accountId",
        element: <Audit />,
        loader: auditLoader,
        children: [...modals],
      },
      {
        path: "organization/:organizationId",
        element: <Organization />,
        loader: organizationLoader,
        children: [...modals],
      },
      {
        path: "transactions/:organizationId",
        element: <AllTransactions />,
        children: [...modals],
        loader: allTransactionsLoader,
      },
      {
        path: "allowlist/:organizationId",
        id: "allowlist",
        element: <Allowlist />,
        loader: allowlistLoader,
        children: [
          {
            path: "new",
            id: "allowlist-new-address",
            element: <AddAllowlist />,
            action: addAllowlistAction,
          },
          ...modals,
        ],
      },
      {
        path: "manage-users/:organizationId/:accountId",
        element: <ManageUsers />,
        loader: manageUsersLoader,
        children: [...modals],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<RouterProvider router={router} />);
