// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { ensureInitialized, fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from "../utils/dataUtils";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app);

export let isREL009PublicFeature = false;

export async function initRemoteConfig(account_id: string) {
  const rc = remoteConfig;

  /* set minimum Fetch interval millis to 5 mins.
     Default is 12 hours. Config will fetch every 5 mins instead of 12 hours
  */
  rc.settings.minimumFetchIntervalMillis = 300000;
  let REL009AccountIds;

  // fetches remote config backend and activates them in frontend
  const isActivated = await fetchAndActivate(rc);

  if (isActivated) {
    REL009AccountIds = getValue(rc, "REL_009_AccountList").asString().split(",");
  } else {
    // ensureInitialized gets the last fetched and activated values from the app
    // this the fallback if isActivated returns false.
    REL009AccountIds = await ensureInitialized(rc).then(() => {
      return getValue(rc, "REL_009_AccountList").asString().split(",");
    });
  }

  isREL009PublicFeature = REL009AccountIds.includes(account_id);

  return { isREL009PublicFeature };
}
