import {
  Container,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Input,
  StyledEngineProvider,
  SvgIcon,
  SelectChangeEvent,
  Typography,
  Box,
} from "@mui/material";
import { Icons, Button } from "@bakkt/bakkt-ui-components";
import React from "react";
import { Await, useLoaderData, Link as RouterLink, useLocation } from "react-router-dom";
import LoadingIndicator from "../loading/LoadingIndicator.tsx";
import { Organization } from "../../services/openAPI/client";
import { Unstable_Grid2 as Grid } from "@mui/material";
import { isInitiator, isViewOnly } from "../../utils/permissionsUtil";
import { isREL009PublicFeature } from "../../config/firebaseConfig.ts";

interface BakktToolbarProps {
  selectedOrgId: string;
  setSelectedOrgId: (selectedOrgId: string) => void;
}

export const BakktToolbar = ({ selectedOrgId, setSelectedOrgId }: BakktToolbarProps) => {
  const { organizations, userInfo }: any = useLoaderData();

  const handleOrgChange = (event: SelectChangeEvent) => {
    setSelectedOrgId(event.target.value);
  };

  const contrastText = {
    color: "primary.contrastText",
  };

  const linkTextSx = {
    color: "primary.contrastText",
  };

  const inputLabelText = {
    color: "primary.contrastText",
    "&.Mui-focused": { color: "primary.contrastText" },
  };

  const iconSize = {
    width: 20,
    height: 20,
    pr: 1,
    pt: 1,
    mb: -0.2,
  };

  const borderBottom = {
    borderBottom: `1px solid`,
    borderColor: `primary.contrastText`,
  };

  const location = useLocation();

  return (
    <StyledEngineProvider injectFirst>
      <Container disableGutters maxWidth="xl">
        <Toolbar>
          <Grid container xs={12} justifyContent="space-between" alignItems="center" sx={{ mt: 2.5 }}>
            <Grid xs={4}>
              <FormControl variant="standard" fullWidth sx={borderBottom}>
                <React.Suspense fallback={<LoadingIndicator />}>
                  <Await resolve={organizations} errorElement={<Typography>Error loading organizations!</Typography>}>
                    {(organizations: Organization[]) => (
                      <>
                        <InputLabel sx={inputLabelText}>Organization</InputLabel>
                        <Select
                          label="Organization"
                          value={selectedOrgId}
                          sx={contrastText}
                          onChange={handleOrgChange}
                          input={<Input />}
                        >
                          {organizations
                            .sort((orgA: Organization, orgB: Organization) => {
                              if (orgA.id === organizations[0].id) {
                                return -1;
                              } else if (orgB.id === organizations[0].id) {
                                return 1;
                              } else {
                                return orgA.name > orgB.name ? 1 : -1;
                              }
                            })
                            .map((org: Organization) => (
                              <MenuItem key={org.id} value={org.id.toString()}>
                                {org.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </>
                    )}
                  </Await>
                </React.Suspense>
              </FormControl>
            </Grid>
            <Grid>
              <Stack direction="row" spacing={2} alignItems="center">
                {!isViewOnly(userInfo, Number(selectedOrgId)) && (
                  <RouterLink to={`${location.pathname}/addWallet`}>
                    <Button variant="text" size="small" color="inherit" sx={contrastText}>
                      <Box component="span" sx={linkTextSx} className="default-link-hover">
                        <SvgIcon component={Icons.AddWalletIcon} inheritViewBox sx={iconSize} />
                        <Box component="div" sx={{ display: "inline-block" }}>
                          Add New Wallet
                        </Box>
                      </Box>
                    </Button>
                  </RouterLink>
                )}
                {isREL009PublicFeature && isInitiator(userInfo, Number(selectedOrgId)) && (
                  <RouterLink to={`${location.pathname}/transfer`}>
                    <Button variant="text" size="small" color="inherit" sx={contrastText}>
                      <Box component="span" sx={linkTextSx} className="default-link-hover">
                        <SvgIcon component={Icons.TransferIcon} inheritViewBox sx={iconSize} />
                        Transfer
                      </Box>
                    </Button>
                  </RouterLink>
                )}
                {isInitiator(userInfo, Number(selectedOrgId)) && (
                  <RouterLink to={`${location.pathname}/withdraw`}>
                    <Button variant="text" size="small" color="inherit" sx={contrastText}>
                      <Box component="span" sx={linkTextSx} className="default-link-hover">
                        <SvgIcon component={Icons.WithdrawIcon} inheritViewBox sx={iconSize} />
                        Withdraw
                      </Box>
                    </Button>
                  </RouterLink>
                )}
                <RouterLink to={`${location.pathname}/deposit`}>
                  <Button variant="text" size="small" color="inherit" sx={contrastText}>
                    <Box component="span" sx={linkTextSx} className="default-link-hover">
                      <SvgIcon component={Icons.DepositIcon} inheritViewBox sx={iconSize} />
                      Deposit
                    </Box>
                  </Button>
                </RouterLink>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </StyledEngineProvider>
  );
};
